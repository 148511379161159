import { useEffect, useState } from "react";
import { BigTitle } from "../../../shared/utils/styled-components-library.utils";
import styled from "styled-components";
import { get, } from "../../../shared/utils/api.utils";
import { Alert } from "@material-ui/lab";
import { AlertWrapper } from "../../modals/delete-user.modal";

const percentage = (a, b) => {
  if (b === 0)
    return 'N/A';
  return `${Math.round((a / b) * 100)}%`;
}

export default function Statistics({ user }) {
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: null, message: '' });
  const [userStatistics, setUserStatistics] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    get(
      user.type === 'admin'
      ? '/stats/my-company'
      : `/stats/userId/${user.userId}`
    ).then((res) => {
      if (res === false)
        return ;
      setUserStatistics(res);
    }).catch((err) => {
      setAlert({ show: true, type: 'error', message: 'Une erreur est survenue, veuillez réessayer.' });
    }).finally(() => {
      setIsLoading(false);
    });
  }, [user]);

  let total = null;
  if (userStatistics !== null) {
    total = userStatistics.reduce((acc, stat) => {
      acc.needCreations += stat.needCreations;
      acc.profileCreations += stat.profileCreations;
      acc.appliedNeeds += stat.appliedNeeds;
      acc.interestingNeeds += stat.interestingNeeds;
      acc.wonNeeds += stat.wonNeeds;
      return acc;
    }, {
      needCreations: 0,
      profileCreations: 0,
      appliedNeeds: 0,
      interestingNeeds: 0,
      wonNeeds: 0,
    });
  } else {
    total = {
      needCreations: 0,
      profileCreations: 0,
      appliedNeeds: 0,
      interestingNeeds: 0,
      wonNeeds: 0,
    };
  }

  let columnNumberFirstArray = 1;
  if (user.profile === 'company')
    columnNumberFirstArray += 1;
  if (user.profile !== 'freelance')
    columnNumberFirstArray += 1;
  if (user.profile !== 'end-client')
    columnNumberFirstArray += 3;


  return (
    <MainWrapper>
      <BigTitle>Statistiques</BigTitle>
      <BigCard>
        <Grid columns={columnNumberFirstArray}>
          <div></div>
          { user.profile === 'company' && <ColumnHeader>Nombre d'intercontrats créés</ColumnHeader> }
          { user.profile !== 'freelance' && <ColumnHeader>Nombre de besoins créés</ColumnHeader> }
          { user.profile !== 'end-client' &&
              <>
                <ColumnHeader>Nombre de candidatures</ColumnHeader>
                <ColumnHeader>Nombre de candidatures intéressantes</ColumnHeader>
                <ColumnHeader>Nombre de besoins gagnés</ColumnHeader>
              </>
          }

          {
            userStatistics !== null &&
              userStatistics.map((stat, i) => (
                <>
                  <RowHeader>{stat.name}</RowHeader>
                  { user.profile === 'company' && <RowCell>{stat.profileCreations}</RowCell> }
                  { user.profile !== 'freelance' && <RowCell>{stat.needCreations}</RowCell> }
                  { user.profile !== 'end-client' &&
                      <>
                        <RowCell>{stat.appliedNeeds}</RowCell>
                        <RowCell>{stat.interestingNeeds}</RowCell>
                        <RowCell>{stat.wonNeeds}</RowCell>
                      </>
                  }
                  <div className="row-border" />
                </>
              ))
          }
          {
            total !== null && user.type === 'admin' &&
              <>
                <RowHeader className="last">Bilan{ user.profile === 'freelance' ? '' : ' société'}</RowHeader>
                { user.profile === 'company' && <RowCell className="last">{total.profileCreations}</RowCell> }
                { user.profile !== 'freelance' && <RowCell className="last">{total.needCreations}</RowCell> }
                { user.profile !== 'end-client' && 
                  <>
                    <RowCell className="last">{total.appliedNeeds}</RowCell>
                    <RowCell className="last">{total.interestingNeeds}</RowCell>
                    <RowCell className="last">{total.wonNeeds}</RowCell>
                  </>
                }
              </>
          }
        </Grid>
        { alert.show && <AlertWrapper>
          <Alert severity={alert.type}>{alert.message}</Alert>
        </AlertWrapper>
        }

        { user.profile !== 'end-client' && 
          <Grid style={{ marginTop: '5%' }} columns={4}>
            <div></div>
            <ColumnHeader>Rapport candidatures intéressantes / Nombre de candidatures</ColumnHeader>
            <ColumnHeader>Rapport candidatures gagnées / Nombre de candidatures</ColumnHeader>
            <ColumnHeader>Rapport candidatures gagnées / intéressantes</ColumnHeader>
            {
              userStatistics !== null &&
                userStatistics.map((stat, i) => (
                  <>
                    <RowHeader>{stat.name}</RowHeader>
                    <RowCell>{percentage(stat.interestingNeeds, stat.appliedNeeds)}</RowCell>
                    <RowCell>{percentage(stat.wonNeeds, stat.appliedNeeds)}</RowCell>
                    <RowCell>{percentage(stat.wonNeeds, stat.interestingNeeds)}</RowCell>
                    <div className="row-border" />
                  </>
                ))
            }
            {
              total !== null && user.type === 'admin' &&
                <>
                  <RowHeader className="last">Bilan{ user.profile === 'freelance' ? '' : ' société'}</RowHeader>
                  <RowCell className="last">{percentage(total.interestingNeeds, total.appliedNeeds)}</RowCell>
                  <RowCell className="last">{percentage(total.wonNeeds, total.appliedNeeds)}</RowCell>
                  <RowCell className="last">{percentage(total.wonNeeds, total.interestingNeeds)}</RowCell>
                </>
            }
          </Grid>
        }
      </BigCard>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BigCard = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.3);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px;
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20% repeat(${props => props.columns ? props.columns -1 : 5}, 1fr);
  column-gap: 3px;
  row-gap: 15px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  & > .row-border {
    grid-column: 1 / -1;
    border-bottom: 1px solid #f2b6bc;
  }
`;

const ColumnHeader = styled.div`
  color: 241f47;
  border: 1px solid #f2b6bc;
  border-radius: 10px;
  color: #241f47;
`;

const RowHeader = styled.div`
  color: 241f47;
  color: #241f47;
  font-weight: bold;
  text-align: left !important;
  justify-content: flex-start !important;

  &.last {
    color: #f2b6bc;
    font-weight: bold;
  }
`;

const RowCell = styled.div`
  &.last {
    color: #f2b6bc;
    font-weight: bold;
  }
`
