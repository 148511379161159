import React from "react";
import styled from "styled-components";
import { Stepper, Step, StepLabel, StepConnector, withStyles } from "@material-ui/core";

const ActiveIcon = () => (
	<img src="/assets/icons/stepper-active.png" alt="active step" />
);
const InactiveIcon = () => <img src="/assets/icons/stepper.png" alt="step" />;
const InactiveIconPink = () => (
	<img src="/assets/icons/stepperPink.png" alt="step" />
);

export default function CustomStepper({
	labels,
	activeStep,
	style,
	handleStep,
	completedSteps,
	type
}) {
	return (
		<Stepper
			alternativeLabel
			activeStep={activeStep}
			style={{...style, backgroundColor: 'transparent'} || {}}
			nonLinear
			// connector={CustomConnector}
		>
			{labels.map((label, index) => (
				<Step key={label}>
					<StyledStepLabel
						completed={completedSteps[index]}
						onClick={completedSteps[index] ? () => handleStep(index) : () => { }}
						StepIconComponent={
							type === "pink" ? StepIconLibraryPink : StepIconLibrary
						}
					>
						<span className="large">{label}</span>
						<span className="small">{index + 1}</span>
					</StyledStepLabel>
				</Step>
			))}
		</Stepper>
	);
}

const StepIconLibrary = props => {
	const { active, completed } = props;
	return (
		<IconWrapper reachable={completed}>
			{active || completed ? <ActiveIcon /> : <InactiveIcon />}
		</IconWrapper>
	);
};

const StepIconLibraryPink = props => {
	const { active, completed } = props;
	return (
		<IconWrapper reachable={completed}>
			{active || completed ? <ActiveIcon /> : <InactiveIconPink />}
		</IconWrapper>
	);
};

const IconWrapper = styled.div`
	cursor: ${props => (props.reachable ? "pointer" : "initial")};
`;

const StyledStepLabel = withStyles({
	root: {
		'& .MuiTypography-root': {
			color: "#f296ac",
			fontFamily: "Prompt Semi Bold"
		},
		'& .MuiStepLabel-active': {
			color: "#42387a",
		},
		'@media(max-width: 500px)': {
			'& .MuiStepLabel-label .large': {
				display: 'none'
			}
		},
		'@media(min-width: 501px)': {
			'& .MuiStepLabel-label .small': {
				display: 'none'
			}
		}
	}
})(StepLabel);

// const CustomConnector = withStyles({
//   alternativeLabel: {
//     top: 22,
//   },
//   active: {
//     '& $line': {
//       backgroundImage:
//         'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
//     },
//   },
//   completed: {
//     '& $line': {
//       backgroundImage:
//         'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
//     },
//   },
//   line: {
//     height: 3,
//     border: 5,
//     backgroundColor: '#eaeaf0',
//     borderRadius: 1,
//   },
// })(StepConnector);