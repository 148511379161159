import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Checkbox,
  withStyles,
} from "@material-ui/core";
import Image from "../images/image.component";

export default function CheckboxList({
  label,
  items,
  groupItems,
  toggleCheckHandler,
  showCheckedNumber,
}) {
  const [open, setOpen] = useState(false);
  const toggleCollapse = () => {
    setOpen(!open);
  };

  let itemsCheckedNumber;
  if (groupItems) {
    itemsCheckedNumber = groupItems?.reduce(
      (acc, curr) => (curr.checked ? acc + 1 : acc),
      0
    );
  } else {
    itemsCheckedNumber = items?.reduce(
      (acc, curr) => (curr.checked ? acc + 1 : acc),
      0
    );
  }

  return (
    <ListWrapper>
      <StyledList>
        <StyledListHeader button onClick={toggleCollapse}>
          <CustomListItemText primary={label} />
          {itemsCheckedNumber > 0 && (
            <CheckedListItem>{itemsCheckedNumber}</CheckedListItem>
          )}
          <AngleWrapper>
            {open ? (
              <Image
                src="/assets/icons/angle-up.svg"
                width="18px"
                alt="Angle vers le haut"
              />
            ) : (
              <Image
                src="/assets/icons/angle-down.svg"
                width="18px"
                alt="Angle vers le bas"
              />
            )}
          </AngleWrapper>
        </StyledListHeader>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div style={{ maxHeight: 250, overflow: "auto" }}>
            <StyledList component="div" disablePadding>
              {items
                ? items.map((item) => (
                    <StyledListItem
                      key={item.value}
                      role={undefined}
                      dense
                      button
                      onClick={() => toggleCheckHandler(item.value)}
                    >
                      <ListItemIcon>
                        <StyledCheckbox
                          checked={item.checked}
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <CustomListItemText primary={item.label} />
                    </StyledListItem>
                  ))
                : null}
              {groupItems
                ? groupItems.map((groupItem, index) => {
                    return groupItem.isTitle ? (
                      // <StyledListItem key={index} role={undefined} dense>
                      //   <CustomListItemText primary={groupItem.value} />
                      // </StyledListItem>
                      <section
                        style={{
                          background: "#F0F0F0",
                          padding: "3px 2px 2px 10px",
                          fontFamily: "Prompt Light",
                        }}
                      >
                        <h3 style={{ margin: 0 }}>{groupItem.value}</h3>
                      </section>
                    ) : (
                      <StyledListItem
                        key={index}
                        role={undefined}
                        dense
                        onClick={() => toggleCheckHandler(groupItem.label)}
                      >
                        <ListItemIcon>
                          <StyledCheckbox
                            checked={groupItem.checked}
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <CustomListItemText primary={groupItem.label} />
                      </StyledListItem>
                    );
                  })
                : null}
            </StyledList>
          </div>
        </Collapse>
      </StyledList>
    </ListWrapper>
  );
}

const ListWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #b5b4db;
`;

const StyledList = styled(List)`
  && {
    &.MuiList-root {
      margin: 0;
      padding: 0;
    }
  }
`;

const StyledListHeader = styled(ListItem)`
  && {
    &.MuiListItem-root {
      padding: 5px 2px;
      margin: 0;
    }

    & .MuiSvgIcon-root {
      color: #f093ab;
    }
  }
`;

const CheckedListItem = styled(ListItem)`
  && {
    &.MuiListItem-root {
      margin-left: auto;
      width: auto;
      color: #f093ab;
      font-weight: 500;
    }
  }
`;

const StyledListItem = styled(ListItem)`
  && {
    &.MuiListItem-root {
      padding: 0px 13px;
    }

    & .MuiButtonBase-root {
      padding: 2px;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    &.MuiIconButton-root:hover {
      background-color: transparent;
    }
  }
`;

const CustomListItemText = withStyles({
  root: {
    "& .MuiTypography-root": {
      color: "#42387a",
      fontFamily: "Prompt Light",
    },
  },
})(ListItemText);

const AngleWrapper = styled.div`
  margin-right: 5px;
`;
