import styled from "styled-components";
import { useState, useEffect } from "react";
import { BigTitle } from "../../../shared/utils/styled-components-library.utils";
import { deleteReq, get } from "../../../shared/utils/api.utils";
import moment from "moment";
import Image from "../../../shared/components/images/image.component";
import Message from "./messages/message.component";
import MessageInput from "./messages/input.component";

const ChannelName = ({ name, company, createdAt, selected, onClick }) => {
  return (
    <ChannelNameWrapper selected={selected} onClick={ onClick }>
      <div style={{width: '100%'}}>
        <div className="name">{name}.</div>
        <div className="company">{ company }</div>
        <div className="date">{ moment(createdAt).format("DD/MM/YYYY") }</div>
      </div>
    </ChannelNameWrapper>
  )
}

export default function Messages({ user }) {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [correspondant, setCorrespondant] = useState(null);
  const [messages, setMessages] = useState([]);
  const [refreshChannel, setRefreshChannel] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(new Date());

  const getChannels = async() => {
    setLoading(true);
    return get("/messages/channels").then((channels) => {
      setChannels(channels);
      if (!correspondant && channels.length > 0) {
        setCorrespondant({
          userId: channels[0].correspondant.userId,
          name: channels[0].correspondant.name,
          company: channels[0].correspondant.company,
        });
      }
      setLoading(false);
    });
  }

  const deleteCorrespondant = () => {
    setLoading(true);
    deleteReq(`/messages/${correspondant.userId}`).then(() => {
      setChannels(channels.filter(channel => channel.correspondant.userId !== correspondant.userId));
      setCorrespondant(null);
      setMessages([]);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshChannel(true);
    }, 3000);
    return () => clearInterval(intervalId);
  }, []); // eslint-disable-line

  useEffect(() => {
    if(!correspondant) return;
    setLoading(true);
    get(`/messages/${correspondant.userId}`).then((messages) => {
      setMessages(messages);
      setLoading(false);
      setLastUpdate(new Date());
    });
  }, [correspondant]); // eslint-disable-line

  useEffect(() => {
    getChannels().then(() => {
      if(!correspondant) return;
      get(`/messages/${correspondant.userId}`).then((messages) => {
        setMessages(messages);
      });
    }).finally(() => {
      setRefreshChannel(false);
    });
  }, [refreshChannel]); // eslint-disable-line

  return (
      <MainWrapper>
        <BigTitle>MESSAGES</BigTitle>
        <BigCard>
          { channels?.length === 0
            ? <div style={{margin: 'auto'}}>Aucun message</div>
            :
            <>
              <ChannelDescriptions>
                {
                  channels && channels.map((channel, i) => (
                      <ChannelName
                        key={i}
                        name={channel.correspondant.name}
                        company={channel.correspondant.company}
                        createdAt={channel.lastMessageDate}
                        selected={ channel.correspondant.userId === correspondant?.userId }
                        onClick={() => setCorrespondant({
                          userId: channel.correspondant.userId,
                          name: channel.correspondant.name,
                          company: channel.correspondant.company,
                        })}
                      />
                    )
                  )
                }
              </ChannelDescriptions>
              <DiscussionWrapper>
                <ChannelTitleWrapper>
                  <ChannelTitle>{ correspondant?.name }. { correspondant?.company }</ChannelTitle>
                  <Image src="/assets/icons/bin.png" onClick={ deleteCorrespondant } width="25px"/>
                </ChannelTitleWrapper>
                <MessagesWrapper>
                {
                  messages?.map((message, i) => {
                    const isSenderSelf = message?.senderUserId === user.userId;
                    const name = isSenderSelf ? (user.firstname + ' ' + user.lastname) : correspondant?.name;
                    return (
                      <Message
                        key={i}
                        isSenderSelf={ isSenderSelf }
                        name={name}
                        message={message}
                      />
                    );
                  })
                }
                </MessagesWrapper>
                <MessageInput correspondant={correspondant} setRefreshChannel={setRefreshChannel} />
              </DiscussionWrapper>
            </>
          }
        </BigCard>
      </MainWrapper>
  );
}

export const MainWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const BigCard = styled.div`
  height: 100%;
  box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.3);
	border-radius: 40px;
  display: flex;
  flex-direction: row;
  padding: 40px 40px;
  max-height: 80vh;
`;

const DiscussionWrapper = styled.div`
  color: #241f47;
  width: 80%;
  height: 100%;
  box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.3);
  border-radius: 40px;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ChannelDescriptions = styled.div`
  width: 20%;
  height: 100%;
  padding: 40px 0px;
  overflow-y: auto;
`

const ChannelNameWrapper = styled.div`
  background-color: ${props => props.selected ? '#f5f5f5' : '#fff'};
  color: ${props => props.selected ? '#fcaabb' : '#241f47'};
  border-radius: 40px;
  margin-bottom: 20px;
  cursor: ${props => props.selected ? 'default' : 'pointer'};
  display: flex;
  align-items: center;
  padding-right: 40px;
  font-family: "Prompt";
  border-radius: 0px;
  margin: 0px;
  border-bottom: 1px solid #241f47;

  & .name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .company {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .date {
    font-style: italic;
  }
`

const ChannelTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

const ChannelTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const MessagesWrapper = styled.div`
  padding-top: 20px;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
`
