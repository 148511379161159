import React from 'react';
// enumeration = { key1: number, key2: number, ... }
// choice = key1 | key2 | ...
// childrenMap: Map<key, component>
const div = React.createElement('div');

export class AbstractChoice {
  #tabsMap;
  currentTab;
  #onTabChange;

  constructor({ tabsMap, onTabChange }) {
    this.#tabsMap = tabsMap;
    this.currentTab = undefined;
    this.#onTabChange = onTabChange;
  }

  setOnTabChange(onTabChange) {
    this.#onTabChange = onTabChange;
  }

  changeTab = function({ newTab }) {
    if (this.#onTabChange !== undefined) {
      this.#onTabChange({ newTab, newAttachments: this.getAttachments({ tab: newTab }) });
    }
    this.currentTab = newTab;
  }

  changeTabWithCondition = function(validationFunction) {
    for (const [key, value] of this.#tabsMap) {
      if (validationFunction({ key, value})) {
        this.changeTab({ newTab: key });
        return;
      }
    }
  }

  findFirstValidTab(conditions) {
    if (this.currentTab !== undefined) {
      this.changeTab({ newTab: this.currentTab })
    }
    for (const [key, value] of this.#tabsMap.entries()) {
      if (!value.validationFunction || value.validationFunction(conditions)) {
        this.changeTab({ newTab: key });
        return ;
      }
    }
  }

  currentComponent = function({ conditions, props }) {
    for (const [, value] of Object.entries(conditions)) {
      if (value === undefined || value === null) return div;
    }
    if (!this.validationFunction({ tab: this.currentTab, conditions })) {
      return div;
    }
    let RenderedComponent = this.#tabsMap.get(this.currentTab)?.component(conditions);
    const additionalProps = this.#tabsMap.get(this.currentTab)?.additionalProps;
    // key is used to place the same component with a different state
    let key;
    if ((key = this.#tabsMap.get(this.currentTab)?.key) !== undefined) {
      return RenderedComponent ? <RenderedComponent {...props} {...additionalProps} key={key} /> : div;
    }
    return RenderedComponent ? <RenderedComponent {...props} {...additionalProps} /> : div;
  }

  validationFunction({ tab, conditions }) {
    if (this.#tabsMap.get(tab)?.validationFunction === undefined)
      return true;
    return this.#tabsMap.get(tab)?.validationFunction(conditions);
  }

  getAttachments({ tab }) {
    return this.#tabsMap.get(tab)?.attachments;
  }

  getAllTabKeys(conditions) {
    const keys = [];
    for (const [key, value] of this.#tabsMap.entries()) {
      if (!value.validationFunction || value.validationFunction(conditions)) {
        keys.push(key);
      }
    }
    return keys;
  }
}


