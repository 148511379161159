import React, { useState, useMemo, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import moment from "moment";
import { Tooltip, withStyles } from "@material-ui/core";

import { AuthContext } from "../../../contexts/auth.context";

import Pagination from "../../../shared/components/lists/pagination.component";
import TableHeaderCell from "./table-header-cell.component";
import {
  experienceYearsOptions
  //availabilityOptions
} from "../../../constants/select-options.constants";
import { HorizontalSeparator } from "../../../shared/utils/styled-components-library.utils";
import { getLocationLabelsById } from "../../../shared/utils/profiles.utils";
import Image from "../../../shared/components/images/image.component";
import { useHistory } from "react-router-dom";
import { getLogo } from "../../../shared/utils/items.utils";

const experienceYearsMap = experienceYearsOptions.reduce(
  (obj, item) => ({ ...obj, [item.value]: item.label }),
  {}
);
// const availabilityMap = availabilityOptions.reduce((obj, item) => ({ ...obj, [item.value]: item.label }), {});

const tooltipContent = { freelance: "Freelance", company: "Interco" };

export default function ListingTable({
  nameSpace,
  profiles,
  currentSort,
  sortBy,
  workLocations,
  openSignInModal
}) {
  const [auth] = useContext(AuthContext);
  const history = useHistory();
  // pagination
  const items = useMemo(() => [10, 25, 50, 100], []);
  const [page, setPage] = useState(null);
  const [rowPerPage, setRowPerPage] = useState(10);
  const location = useLocation();
  const handlePageChange = (_, value) => {
    setPage(value);
    history.push({
      pathname: location.pathname,
      search: "?page=" + value + "&count=" + rowPerPage
    });
  };
  const handleRowPerPageChange = event => {
    setRowPerPage(event.target.value);
    history.push({
      pathname: location.pathname,
      search: "?page=" + page + "&count=" + event.target.value
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialPage = params.get("page");
    const initialCount = params.get("count");
    if (initialPage) {
      setPage(parseInt(initialPage));
    } else {
      setPage(1);
    }
    if (initialCount) {
      setRowPerPage(parseInt(initialCount));
    } else {
      setRowPerPage(10);
    }
  }, []);

  useEffect(() => {
    setPage(1);
  }, [profiles]);

  /**
   * Permet de récupérer, pour un interco, le label de la région affiché dans la colonne "Mobilité".
   * @param {string[]} ids Correspond aux ids de région et de départements choisis.
   */
  const getLocationlabel = ids => {
    let displayedIds = ids.slice(0, 2);
    let labels = getLocationLabelsById(workLocations, displayedIds);
    return labels.join(", ") + (ids.length >= 3 ? ", ..." : "");
  };
  const commonProps = { currentSort, sortBy };

  const displayedProfiles = profiles.slice(
    rowPerPage * (page - 1),
    rowPerPage * page
  );
  const pagesCount = useMemo(() => Math.ceil(profiles.length / rowPerPage), [
    profiles,
    rowPerPage
  ]);
  return (
    <>
    <TableWrapper columns={7}>
        <TableHeaderCell label="title" {...commonProps} colSpan={2}>
          Titre
        </TableHeaderCell>
        { nameSpace === 'needs' &&
          <TableHeaderCell label="type" {...commonProps}>
            Émetteur
          </TableHeaderCell>
        }
        { nameSpace !== 'needs' &&
          <TableHeaderCell label="experience" {...commonProps}>
            Expérience
          </TableHeaderCell>
        }
        <TableHeaderCell label="tjm" {...commonProps}>
          TJM{ nameSpace === 'needs' ? 'MAX' : ''}
        </TableHeaderCell>
        <TableHeaderCell label="mobility" {...commonProps}>
          { nameSpace === 'needs' ? 'Localisation' : 'Mobilité' }
        </TableHeaderCell>
        <TableHeaderCell label="availability" {...commonProps}>
          { nameSpace === 'needs' ? 'Date de démarrage' : 'Dispo dans' }
        </TableHeaderCell>
        <TableHeaderCell label="updated" {...commonProps}>
          Date MàJ
        </TableHeaderCell>
      {displayedProfiles.map(profile => {
        const isNewProfile =
          moment().diff(moment(profile.createdAt), "days") <= 7;

        const RowComponent = auth.logged ? RedirectTableRow : TableRow;
        const componentProps = auth.logged
          ? { to: `/${nameSpace === 'needs' ? 'besoin' : 'profil' }/${profile.hash}` }
          : { onClick: openSignInModal };
        return (
          <>
            <Row
              style={{ cursor: "pointer" }}
              key={profile.hash}
              onClick={() => {
                if (auth.logged) history.push(`/${nameSpace === 'needs' ? 'besoin' : 'profil' }/${profile.hash}`);
                else openSignInModal();
              }}
            >
              <TableRowCell highlighted>
                <CustomTooltip
                  placement="top"
                  title={tooltipContent[profile.userProfile]}
                >
                    <Image
                      src={ getLogo(nameSpace, profile, profile.user.profile) }
                      size="32px"
                    />
                </CustomTooltip>
              </TableRowCell>
              <TableRowCell>
                <span className="profileTitle" title={profile.title} style={{ marginLeft: 5 }}>
                  {profile.title}
                </span>
                <div style={{ marginLeft: 5 }}>
                  <Image
                    width="24px"
                    src={isNewProfile ? "/assets/icons/new.png" : ""}
                  />
                </div>
              </TableRowCell>
              { nameSpace !== 'needs' &&
                <TableRowCell>
                  {experienceYearsMap[profile.experienceYears]}
                </TableRowCell>
              }
              {
                nameSpace === 'needs' &&
                <TableRowCell>
                  {profile.profile === 'end-client' ? 'Client final' : 'ESN'}
                </TableRowCell>
              }
              <TableRowCell highlighted>
                {!profile.priceHidden && !profile.negotiable ? `${profile.price} €` : "N/C"}
              </TableRowCell>
              <TableRowCell>
                {getLocationlabel(profile.workLocations)}
              </TableRowCell>
              <TableRowCell highlighted>
                {profile.delta < 1
                  ? "Immédiatement"
                  : nameSpace === 'needs'
                    ? profile.minDate
                    : profile.delta >= 7
                      ? "Dans plus de 6 mois"
                      : `${profile.delta} mois`}
              </TableRowCell>
              <TableRowCell>
                {moment(profile.updatedAt).format("DD/MM/YYYY")}
              </TableRowCell>
            </Row>
            <HorizontalSeparator className="row-border" />
          </>
        );
      })}
      </TableWrapper>
      {page && (
        <Pagination
          items={items}
          page={page}
          pagesCount={pagesCount}
          rowPerPage={rowPerPage}
          handlePageChange={handlePageChange}
          handleRowPerPageChange={handleRowPerPageChange}
        />
      )}
    </>
  );
}

const TableWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 32px repeat(${props => props.columns - 1}, 1fr);
  overflow: scroll;
  column-gap: 5px;
  margin-top: 20px;

  & > .row-border {
    grid-column: 1 / -1;
  }
`;

const Row = styled.div`
  display: contents;
  grid-column: 1 / -1;
`

/* ***** */
const sharedStyle = css`
  background-color: #FFF;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  overflow: visible;
  z-index: 10;
  position: relative;
  &:hover {
    box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.25);
    border-color: white;
    z-index: 100;
  }
`;

const TableRow = styled.div`
  display: contents;
  ${sharedStyle}
`;

const RedirectTableRow = styled(Link)`
  display: contents;
  ${sharedStyle}
`;
/* ***** */

const TableRowCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #42387a;
  font-family: ${props =>
    props.highlighted ? "Prompt Semi Bold" : "Prompt"};
  font-size: 14px;
  margin: 15px 0;

  & > .profileTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
  }
`;

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.35);",
    fontSize: 11,
    maxWidth: 600
  },
  tooltipPlacementTop: {
    margin: "4px 0"
  }
})(Tooltip);
