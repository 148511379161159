import React from "react";
import "./App.css";
import { AuthProvider } from "./contexts/auth.context";
import AppRouter from "./navigation/app-router.navigation";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </div>
  );
}

export default App;
