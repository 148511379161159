import ProfileCard from "../../../specific/components/personal-account/profiles/profile-card.component";
import { LightTooltip } from "../../../shared/utils/styled-components-library.utils";
import styled from "styled-components";
import Button from "../../../shared/components/buttons/button.component";
import Image from "../../../shared/components/images/image.component";
import { changeNeedStatus } from "../../../shared/utils/needs.utils";
import SkillCard from "../../../shared/components/skill-card.component";
import moment from "moment";

const columnNames = new Map();
columnNames.set('waiting', 'En attente de réponse');
columnNames.set('refused', 'Refusé');
columnNames.set('interesting', 'Intéressant');
columnNames.set('won', 'Gagné');

const logos = new Map();
logos.set('waiting', { logo: '/assets/icons/waiting.png', size: '50px' });
logos.set('refused', { logo: '/assets/icons/refused.png', size: '40px' });
logos.set('interesting', { logo: '/assets/icons/interesting.png', size: '50px' });
logos.set('won', { logo: '/assets/icons/won.png', size: '40px' });

export default function ItemColumn({ nameSpace, subItemStatus, subItems, setSubItems, setConfirmMessage, frozen, setNeedWon, setChangeOccured, shouldGreyOut }) {
  const deleteCandidate = async(needHash) => {
    setSubItems((prevSubItems) => {
      return prevSubItems.filter((subItem) => subItem.needId !== needHash);
    });
    setChangeOccured(true);
  }


  const sidebar = (need) => {
    if (subItemStatus === 'refused') {
      return undefined;
    }
    return (
      <>
      {
        subItemStatus === 'waiting'  && <div className="deleteButton" onClick={() => deleteCandidate(need.needId)}>
            <Image src="/assets/icons/bin.png" width="27px" />
          </div>
      }
      {
        (subItemStatus === 'interesting' || subItemStatus === 'won') &&
          <LightTooltip
            title={ <><div>{need.user?.fullname}</div><div>{need.user?.phone}</div><div>{ need.user?.email }</div></>}
            placement='top'
          >
          <div>
            <Image src="/assets/icons/phone.svg" width="30px" />
          </div>
          </LightTooltip>
      }
      </>
    );
  };

  const setSubItemStatus = (profileId, accept) => {
      setSubItems((prevSubItems) => {
        return prevSubItems.map((subItem) => {
          const newSubItem = { ...subItem };
          if (subItem.profileId === profileId) {
            newSubItem.status = changeNeedStatus({ initialStatus: subItemStatus, accept });
          }
          // If one item is accepted, a confirm message appears
          if (newSubItem.status === 'won') {
            setNeedWon({
              previousStatus: subItemStatus,
            })
            setConfirmMessage(true);
          }
          return newSubItem;
        });
      });
      setChangeOccured(true);
  }

  const profileButtons = (profileId) => (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: 20 }}>
      {
            subItemStatus !== 'refused' && subItemStatus !== 'won' &&
            <Button
              outlineButton
              onClick={() => setSubItemStatus(profileId, false)}
              style={{ flex: 1 }}
            >
              Refuser
            </Button>
      }
      {
        (subItemStatus === 'waiting' || subItemStatus === 'refused' || subItemStatus === 'interesting') &&
            <Button
              onClick={() => setSubItemStatus(profileId, true)}
              outlineButton={ subItemStatus === 'refused' }
              style={{ flex: 1 }}
              secondary={ subItemStatus !== 'refused'}>
              { subItemStatus === 'refused' ? 'Valider' : subItemStatus === 'interesting' ? 'Gagné' : 'Accepter' }
            </Button>
      }
    </div>
  );
  const subItemsToDisplay = subItems.filter(subItem => subItem.status === subItemStatus);

  const logoImg = logos.get(subItemStatus);
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
      <div style={{ width: 50, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src={ logoImg?.logo } width={ logoImg?.size } />
      </div>
      <ColumnTitle>{ columnNames.get(subItemStatus) }</ColumnTitle>
      <Column overflow="auto" flex>{ 
          subItemsToDisplay.map((subItem, index) => {
            return (
              <>
                <ProfileCard
                  key={subItem.hash}
                  fixed
                  borderless
                  topClickable
                  nameSpace={nameSpace}
                  profile={subItem}
                  additionalInformation={[
                    {
                      label: `Date de ${nameSpace === 'needs' ? 'positionnement' : 'candidature'}`,
                      value: moment(subItem.candidateAt).format('DD/MM/YYYY')
                    }
                  ]}
                  bottom={
                    <>
                      { subItem && <SkillCard profile={subItem} /> }
                      { nameSpace === 'profiles' && (frozen !== true && shouldGreyOut === false ? profileButtons(subItem.profileId) : null) }
                    </>
                  }
                  sidebar={ nameSpace === 'needs' ? sidebar(subItem) : null }
                  greyOut={ shouldGreyOut }
                />
                { index !== subItemsToDisplay.length - 1 && <div style={{ width: '90%', height: '2px',  backgroundColor: '#918fa3' }}></div> }
              </>
            );
          })
        }
      </Column>
    </div>
  );


}

const ColumnTitle = styled.div`
  color: #241f47;
  font-family: "Prompt Semi Bold";
  font-size: 20px;
  margin: 0;
  margin-bottom: 30px;
`

const Column = styled.div`
  overflow: ${ props => props.overflow ?? 'initial' };
  gap: ${ props => props.gap ?? '0' };
  flex: ${ props => props.flex ?? 'initial' };
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: ${props => props.square ? 'initial' : '40px'};
  box-shadow: ${props => props.borderless ? 'initial' : '2px 2px 10px rgba(0,0,0,0.3)'};
`
