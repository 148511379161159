import React, { useState } from 'react';
import styled from "styled-components";
import { TextField, InputAdornment, IconButton, withStyles } from "@material-ui/core";
import { LightTooltip } from "../../utils/styled-components-library.utils";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PasswordStrengthBar from "react-password-strength-bar";

const StyledTextField = withStyles({
	root: {
		width: '100%',
		'& label': {
			fontFamily: 'Prompt Light',
			color: '#42387a',
			left: '13px',
		},
		'& label.Mui-focused, & label.Mui-disabled': {
			color: '#42387a',
		},
		'& .MuiInputLabel-asterisk': {
			color: '#f2a0ad',
		},
		'& .MuiOutlinedInput-root': {
			fontFamily: 'Prompt Light',
			color: '#42387a',
			'& input': {
				padding: "18.5px 27px",
			},
			'& fieldset': {
				borderColor: '#ffc4ce',
				width: '100%',
				boxSizing: 'border-box',
				borderRadius: '100px',
				borderWidth: '1px',
				padding: '0 20px',
			},
			'&:hover fieldset': {
				borderColor: '#f2a0ad',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#f2a0ad',
			},
			'&.Mui-disabled fieldset': {
				fontWeight: 'bold',
				borderColor: '#ffc4ce',
			},
			'&.Mui-disabled fieldset': {
				fontWeight: 'bold',
				borderColor: '#ffc4ce',
			}
		},

	},
})(TextField);

export default function PasswordInput(props) {
	const [isHovered, setIsHovered] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	return (
		<InputContainer>
			<LightTooltip
				open={!!props.showPasswordRules && isHovered}
				placement="right"
				title={
					<>
						<span>Le mot de passe doit contenir :</span>
						<ul>
							<li>Au minimum 10 caractères</li>
							<li>1 majuscule</li>
							<li>1 chiffre</li>
							<li>1 caractère spécial ({"!@#$%^&*-_=+;:,."})</li>
						</ul>
					</>
				}
			>
				<StyledTextField
					type={showPassword ? "text" : "password"}
					label={props.label}
					variant="outlined"
					value={props.value}
					onChange={props.onChangeHandler}
					disabled={props.disabled}
					required={props.required}
					error={props.error}
					onMouseEnter={() => setIsHovered(true)}
					onMouseOut={() => setIsHovered(false)}
					helperText={props.error ? props.errorText : ""}
					InputProps={{
						endAdornment: <InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword(prevState => !prevState)}
								edge="end"
							>
								{showPassword ? <Visibility style={{ color: '#42387a' }} /> : <VisibilityOff style={{ color: '#42387a' }} />}
							</IconButton>
						</InputAdornment>,
					}}
				/>
			</LightTooltip>
			{
				!!props.showPasswordStrength ?
					<StyledPasswordStrenghtBar
						password={props.value}
						shortScoreWord="très faible"
						scoreWords={['très faible', 'faible', 'moyen', 'fort', 'très fort']}
					/> : null
			}
		</InputContainer>
	);
}

const InputContainer = styled.div`
	width: 100%;
	margin-bottom: 24px;
`;

const StyledPasswordStrenghtBar = styled(PasswordStrengthBar)`
	margin: 0 24px;
`;