import React, { useState, useEffect, createContext, useCallback } from "react";
import { englishLevelOptions } from "../constants/select-options.constants";

const FiltersContext = createContext();

const initialFilters = {
  tjm: [0, 900], // taux journalier moyen
  fields: [], // secteurs d'activité
  profileType: [], // société ou freelance
  workLocations: [], // mobilité
  studyLevel: [], // niveau d'études
  englishLevel: [], // niveau d'anglais
  experienceYears: [], // années d'expérience
  availability: [], // disponibilité
  workFormat: [], // télétravail disponible
};

const defaultProfileTypeItems = [
  { label: "Interco", value: 1, checked: false },
  { label: "Freelance", value: 2, checked: false },
];
const defaultStudyLevelItems = [
  { label: "< BAC+2", value: 1, checked: false },
  { label: "BAC+2", value: 2, checked: false },
  { label: "BAC+3", value: 3, checked: false },
  { label: "BAC+4", value: 4, checked: false },
  { label: "BAC+5", value: 5, checked: false },
  { label: "> BAC+5", value: 6, checked: false },
];
const defaultEnglishLevelItems = englishLevelOptions.map((item) => ({
  ...item,
  checked: false,
}));
const defaultExperienceItems = [
  { label: "- de 3 ans", value: 1, checked: false },
  { label: "3 à 5 ans", value: 2, checked: false },
  { label: "6 à 9 ans", value: 3, checked: false },
  { label: "10 ans et plus", value: 4, checked: false },
];
const defaultAvailabilityItems = [
  { label: "Immédiatement", value: 1, checked: false },
  { label: "Moins de 3 mois", value: 2, checked: false },
  { label: "Entre 3 et 6 mois", value: 3, checked: false },
  { label: "Plus de 6 mois", value: 4, checked: false },
];
const defaultRemoteItems = [
  { label: "Compatible en télétravail", value: 0, checked: false },
];

let initialFilterItems = {
  profileType: defaultProfileTypeItems,
  studyLevel: defaultStudyLevelItems,
  englishLevel: defaultEnglishLevelItems,
  experienceYears: defaultExperienceItems,
  availability: defaultAvailabilityItems,
  workFormat: defaultRemoteItems,
  fields: [], // asynchrone, on récupère les secteurs via une requête GET au chargement de la page
};

const FiltersProvider = (props) => {
  const [configLoaded, setConfigLoaded] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [filterItems, setFilterItems] = useState(initialFilterItems);
  const [appliedSearch, setAppliedSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");


  const resetFilters = () => {
    setFilters(initialFilters);
    setFilterItems(initialFilterItems);
    setSearchValue("");
    setAppliedSearch("");
  };

  const removeFilter = (filter) => {
    if (filter.type === "workLocations") {
      setFilters((filters) => ({
        ...filters,
        workLocations: filters.workLocations.filter(
          (item) => item !== filter.value
        ),
      }));
    } else if (filter.type === "fields") {
      setFilters((filters) => ({
        ...filters,
        fields: filters.fields.filter(
          (item) => !item.label || item.label !== filter.label
        ),
      }));
      setFilterItems((items) => ({
        ...items,
        [filter.type]: items[filter.type].map((item) =>
          item.label && item.label === filter.label
            ? { ...item, checked: !item.checked }
            : item
        ),
      }));
    } else {
      setFilters((filters) => ({
        ...filters,
        [filter.type]: filters[filter.type].filter(
          (item) => item.value !== filter.value
        ),
      }));
      setFilterItems((items) => ({
        ...items,
        [filter.type]: items[filter.type].map((item) =>
          item.value === filter.value
            ? { ...item, checked: !item.checked }
            : item
        ),
      }));
    }
  };

  const initFieldItems = (fieldItems) => {
    const defaultFieldItems = fieldItems.map((item) =>
      typeof item === "string" || item instanceof String
        ? { isTitle: true, value: item }
        : { ...item, checked: false }
    );
    initialFilterItems = { ...initialFilterItems, fields: defaultFieldItems };
    resetFilters();
  };

  /**
   * Fonction permettant d'actualiser l'affichage des items en fonction des filtres appliqués.
   */
  const refreshItems = useCallback(() => {
    if (initialFilterItems.fields.length) {
      const items = JSON.parse(JSON.stringify(initialFilterItems));
      for (const type in items) {
        const typeFiltersSelected = filters[type].map((filter) =>
          filter.value ? filter.value : filter.label
        );
        items[type] = items[type].map((item) => {
          if (item.value && !item.isTitle) {
            return typeFiltersSelected.includes(item.value)
              ? { ...item, checked: true }
              : { ...item, checked: false };
          } else
            return typeFiltersSelected.includes(item.label)
              ? { ...item, checked: true }
              : { ...item, checked: false };
        });
      }
      setFilterItems(items);
    }
  }, [filters]);

  // permet de charger une configuration de filtres
  const loadFilters = (loadedFilters) => {
    setFilters(loadedFilters);
    setConfigLoaded(true);
  };
  useEffect(() => {
    if (configLoaded) {
      refreshItems();
      setConfigLoaded(false);
    }
  }, [configLoaded, refreshItems]);

  return (
    <FiltersContext.Provider
      value={{
        nameSpace: 'profiles',
        filters,
        setFilters,
        filterItems,
        setFilterItems,
        resetFilters,
        removeFilter,
        initFieldItems,
        refreshItems,
        loadFilters,
        searchValue,
        setSearchValue,
        appliedSearch,
        setAppliedSearch,
      }}
    >
      {props.children}
    </FiltersContext.Provider>
  );
};

export { FiltersContext, FiltersProvider };
