import React, { useState, useEffect } from "react";
import styled from "styled-components";

import TreeSelect from "../../../../shared/components/inputs/tree-select-input.component";
import RoutingButtons from "../../createinterco/shared/routing-buttons.component";
import Select from "../../../../shared/components/inputs/select-input.component";
import { workFormatOptions,  experienceYearsOptions, englishLevelOptions } from "../../../../constants/select-options.constants";
import { SelectContainers, CustomLabel } from "../../createinterco/shared/styled-components-library";
import { fetchAllEmpowerments, fetchAllWorkLocations } from "../../../../shared/utils/fetch.utils";
import { SmallTitle } from "../../createinterco/shared/styled-components-library";
import YesNoButtons from "../../createinterco/shared/yes-no-buttons.component";
import AutocompleteWithChips from "../../../../shared/components/autocomplete-with-chips.component";
import { FormHelperText } from "@material-ui/core";

export default function MiscellaneousForm({
	workLocations,
	setWorkLocations,
	workFormat,
	setWorkFormat,
        experienceYears,
        setExperienceYears,
        englishLevel,
        setEnglishLevel,
        hasEmpowerment,
        setHasEmpowerment,
        empowerments,
        setEmpowerments,
	handlePrevious,
	handleNext,
        isEditMode = false,
}) {
	const [showErrors, setShowErrors] = useState(false);
        const [loadedWorkLocations, setLoadedWorkLocations] = useState([]);
        const [loadedEmpowerments, setLoadedEmpowerments] = useState([]);
        useEffect(() => {
          fetchAllWorkLocations((_locations) => setLoadedWorkLocations(_locations));
          fetchAllEmpowerments((_empowerments) => setLoadedEmpowerments(_empowerments));
        }, []);

	const onChange = (value) => {
		setWorkLocations([...value]);
	};

	const handleValidationAndNext = () => {
		const hasErrors = !workLocations.length
			|| workFormat === ""
                        || experienceYears === ""
                        || englishLevel === ""
                        || (hasEmpowerment && !empowerments.length);
		if (hasErrors) {
			setShowErrors(true);
		} else {
			handleNext();
		}
	}

	const addEmpowerment = (inputValue) => {
		const value = inputValue.trim();
		const alreadyAdded = empowerments.find(empowerment => empowerment === value);
		if (!alreadyAdded && !!value.trim()) {
			const updatedEmpowerments = empowerments.concat([value]);
			setEmpowerments(updatedEmpowerments);
		}
	}

	const deleteEmpowerment = (label) => {
		const updatedEmpowerments = empowerments.filter(emp => emp !== label);
		setEmpowerments(updatedEmpowerments);
	}


	return (
		<>
                  <SelectContainers>
                    <CustomLabel>Localisation *</CustomLabel>
                    <TreeSelect
                            treeData={loadedWorkLocations}
                            value={workLocations}
                            onChange={onChange}
                            placeholder="Cliquez ici pour ajouter une zone"
                            error={showErrors && !workLocations.length}
                            errorText="Veuillez sélectionner au moins une option."
                    />
                    <Select
                            options={workFormatOptions}
                            value={workFormat}
                            onChangeHandler={e => setWorkFormat(e.target.value)}
                            label="Format de travail *"
                            error={showErrors && !workFormat}
                            errorText="Veuillez sélectionner une option."
                    />
                    <Select options={experienceYearsOptions} value={experienceYears} onChangeHandler={(e) => setExperienceYears(e.target.value)} label="Nombre d'années d'expérience minimale *" error={showErrors && !experienceYears} errorText="Veuillez sélectionner une option." />
                    <Select options={englishLevelOptions} value={englishLevel} onChangeHandler={(e) => setEnglishLevel(e.target.value)} label="Pratique de l'anglais *" error={showErrors && !englishLevel} errorText="Veuillez sélectionner une option." />
                  </SelectContainers>
                    <SmallTitle>Faut-il une habilitation ?</SmallTitle>
                    <YesNoButtons style={{ marginBottom: "50px" }} isChoicePositive={hasEmpowerment} setChoice={setHasEmpowerment} />
                    {
                            hasEmpowerment ?
                                    <>
                                            <AutocompleteWithChips
                                                    addData={addEmpowerment}
                                                    deleteData={deleteEmpowerment}
                                                    dataChoice={loadedEmpowerments.map(empowerment => ({ label: empowerment, id: empowerment }))}
                                                    data={empowerments}
                                                    setData={setEmpowerments}
                                                    placeholder="Lesquelles ?"
                                            />
                                            {showErrors && (hasEmpowerment && !empowerments.length) &&
                                                    <FormHelperText error>
                                                            Veuillez indiquer au moins une habilitation.
                                                    </FormHelperText>
                                            }
                                    </>
                                    : null
                    }
          { !isEditMode && <RoutingButtons
                          handlePrevious={handlePrevious}
                          handleNext={handleValidationAndNext}
                  />
          }
		</>
	);
}

const Disclaimer = styled.p`
	font-family: "Prompt Light Italic";
	font-size: 14px;
	line-height: 15px;
	color: #241f47;

	& span {
		display: block;
	}
`;