import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { isValid } from "../../../../shared/utils/input-validation.utils";

import UnderlineInput from "../../../../shared/components/inputs/big-underline-text-input.component";
import TreeSelect from "../../../../shared/components/inputs/tree-select-input.component";
import RoutingButtons from "../shared/routing-buttons.component";
import Select from "../../../../shared/components/inputs/select-input.component";
import { workFormatOptions } from "../../../../constants/select-options.constants";
import { SelectContainers, CustomLabel, NamesContainer } from "../shared/styled-components-library";
import { fetchAllWorkLocations } from "../../../../shared/utils/fetch.utils";

export default function LocationForm({
	workLocationsSelected,
	setWorkLocationsSelected,
	firstname,
	setFirstname,
	lastname,
	setLastname,
	workFormat,
	setWorkFormat,
	handlePrevious,
	handleNext,
	isFreelance,
	isEditMode
}) {
	const [showErrors, setShowErrors] = useState(false);
        const [workLocations, setWorkLocations] = useState([]);
        useEffect(() => {
          fetchAllWorkLocations((_locations) => setWorkLocations(_locations));
        }, []);

	const onChange = (value) => {
		setWorkLocationsSelected([...value]);
	};

	const handleValidationAndNext = () => {
		const hasErrors = !workLocationsSelected.length
			|| (!isFreelance && (!firstname.trim() || !isValid("text", firstname)))
			|| (!isFreelance && (!lastname.trim() || !isValid("text", lastname)))
			|| workFormat === "";
		if (hasErrors) {
			setShowErrors(true);
		} else {
			handleNext();
		}
	}

	return (
		<>
			{
				!isFreelance ?
					<NamesContainer>
						<UnderlineInput
							placeholder="Prénom *"
							value={firstname}
							onChangeHandler={e => setFirstname(e.target.value)}
							error={showErrors && (!firstname.trim() || !isValid("text", firstname))}
							errorText={!isValid("text", firstname) ? "Ce champ ne doit pas comporter de chiffres." : "Ce champ est obligatoire."}
						/>
						<UnderlineInput
							placeholder="Nom *"
							value={lastname}
							onChangeHandler={e => setLastname(e.target.value)}
							error={showErrors && (!lastname.trim() || !isValid("text", lastname))}
							errorText={!isValid("text", lastname) ? "Ce champ ne doit pas comporter de chiffres." : "Ce champ est obligatoire."}
						/>
						<Disclaimer>
							<span>
								Le nom et le prénom de votre intercontrat ne seront pas visibles aux
								autres tribus.
							</span>
							<span>Uniquement pour vous repérer dans votre espace personnel.</span>
						</Disclaimer>
					</NamesContainer>
					: null
			}
			<SelectContainers>
				<CustomLabel>Zones géographique d'intervention *</CustomLabel>
				<TreeSelect
					treeData={workLocations}
					value={workLocationsSelected}
					onChange={onChange}
					placeholder="Cliquez ici pour ajouter une zone"
					error={showErrors && !workLocationsSelected.length}
					errorText="Veuillez sélectionner au moins une option."
				/>
				<Select
					options={workFormatOptions}
					value={workFormat}
					onChangeHandler={e => setWorkFormat(e.target.value)}
					label="Format de travail *"
					error={showErrors && !workFormat}
					errorText="Veuillez sélectionner une option."
				/>
			</SelectContainers>
			{
				!isEditMode ?
					<RoutingButtons
						handlePrevious={handlePrevious}
						handleNext={handleValidationAndNext}
					/>
					: null
			}
		</>
	);
}

const Disclaimer = styled.p`
	font-family: "Prompt Light Italic";
	font-size: 14px;
	line-height: 15px;
	color: #241f47;

	& span {
		display: block;
	}
`;