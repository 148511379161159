import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Alert } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";

import { FormContainer } from "./shared/styled-components-library";
import {
	MediumTitle,
	Paragraph
} from "../../../shared/utils/styled-components-library.utils";
import RoutingButtons from "./shared/routing-buttons.component";
import DragDrop from "../../../shared/components/drag-drop.component";
import { Delete } from "@material-ui/icons";
import { GetApp } from "@material-ui/icons";

export default function DocumentsForm(props) {
	const { submitAlert, handleSubmit, handlePrevious } = props;
	const [formData, setFormData] = useContext(props.context);
	const [isAnonymous, setIsAnonymous] = useState(false);
	const [documentFile, setDocumentFile] = useState(null);
	const [documentFilename, setDocumentFilename] = useState("");

	useEffect(() => {
		loadFormData();
	}, []); // eslint-disable-line

	useEffect(
		() => {
			if (submitAlert.show && submitAlert.type === "success") {
				setTimeout(() => {
					window.location.href = "/";
				}, 5000);
			}
		},
		[submitAlert]
	);

	const loadFormData = () => {
		if (formData && formData.documentsForm) {
			setDocumentFilename(formData.documentsForm.documentFilename);
			setDocumentFile(formData.documentsForm.documentFile);
		}
		if (formData && formData.infoForm) {
			setIsAnonymous(formData.infoForm.anonymous);
		}
	};

	const saveAndHandleSubmit = () => {
		saveFormData();
		handleSubmit(documentFilename);
	};

	const saveAndHandlePrevious = () => {
		saveFormData();
		handlePrevious();
	};

	const saveFormData = () => {
		setFormData(formData => ({
			...formData,
			documentsForm: {
				documentFilename,
				documentFile
			}
		}));
	};

	const stateProps = {
		isAnonymous,
		documentFile,
		documentFilename,
		setDocumentFile,
		setDocumentFilename
	};

	return (
		<DocumentsFormLayout
			{...props}
			{...stateProps}
			saveAndHandleSubmit={saveAndHandleSubmit}
			saveAndHandlePrevious={saveAndHandlePrevious}
		/>
	);
}

/* Le layout est utile pour la création d'un profil MAIS AUSSI pour l'édition d'un profil dans l'espace personnel */
export const DocumentsFormLayout = props => {
	const {
		submittingInterco,
		isAnonymous,
		documentFile,
		documentFilename,
		setDocumentFile,
		setDocumentFilename,
		saveAndHandlePrevious,
		saveAndHandleSubmit,
		submitAlert, // object : { show: boolean, type: string, message: string }
		isFreelance,
		isEditMode,
		previousDocumentFilename,
		updateStatus,
		disableDelete = false
	} = props;

	const removeFileHandler = () => {
		setDocumentFilename("");
		setDocumentFile(null);
	};

	return (
		<FormContainer width={isEditMode ? "80%" : "50%"}>
			{!isEditMode ? (
				<MediumTitle>
					Importer le {isFreelance ? "CV" : "Dossier de Compétences"}
				</MediumTitle>
			) : null}
			{isAnonymous ? (
				<AnonymousAlert>
					<span>Attention ! Votre profil est anonymisé.</span>
					<span>
						Les autres tribus ne pourront pas télécharger votre{" "}
						{isFreelance ? "CV" : "Dossier de Compétences"}.
					</span>
				</AnonymousAlert>
			) : null}
			<DragDrop
				label={
					isEditMode
						? `Glissez un document ici pour remplacer le ${
						isFreelance ? "CV" : "Dossier de Compétences"
						}`
						: "Glissez votre document ici (optionnel mais recommandé)"
				}
				acceptedFileExtensions={[
					"application/pdf",
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				]}
				acceptedFileExtensionsLabels={["PDF, DOCX"]}
				file={documentFile}
				setFile={setDocumentFile}
				disableDelete={disableDelete}
				removeFile={removeFileHandler}
				uploadCallback={filename => setDocumentFilename(filename)}
				updateStatus={updateStatus}
			/>
			{isEditMode &&
				documentFilename && (
					<EditActions>
						{
							previousDocumentFilename === documentFilename && (
								<IconButton
									title="Télécharger le dossier de compétences existant"
									variant="contained"
									target="_blank"
									rel="noopener noreferrer"
									href={`https://1terco-uploads.s3.eu-west-3.amazonaws.com/userUploads/${documentFilename}`}
								>
									<StyledGetAppIcon />
								</IconButton>
						)}
						<IconButton
							title="Supprimer le dossier de compétences existant"
							variant="contained"
							color="secondary"
							onClick={() => {
								setDocumentFilename("");
								setDocumentFile(null);
								//removeFile();
								//setAlert({ show: false, type: null, message: null });
							}}
						>
							<Delete />
						</IconButton>
					</EditActions>
				)}
			{!isAnonymous && (
				<ParagraphContainer>
					<Paragraph style={{ textAlign: 'center' }}>
						Le {isFreelance ? "CV" : "Dossier de Compétences"} sera
						téléchargeable par les autres tribus
					</Paragraph>
				</ParagraphContainer>
			)}
			{!isEditMode && (
				<>
					<RoutingButtons
						isLastStep
						loading={submittingInterco}
						handleSubmit={saveAndHandleSubmit}
						handlePrevious={saveAndHandlePrevious}
					/>
					{submitAlert.show && (
						<Alert severity={submitAlert.type}>{submitAlert.message}</Alert>
					)}
				</>
			)}
		</FormContainer>
	);
};

const StyledGetAppIcon = styled(GetApp)`
	color: #f29daa;
`;

const AnonymousAlert = styled.p`
	color: #FF1731;
	font-size: 16px;
	font-family: "Prompt";
	line-height: 24px;

	& span {
		display: block;
	}
`;

const ParagraphContainer = styled.div`
	margin: 40px 0px 10px 0px;
`;

const EditActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;