import ModalTemplate from '../../shared/components/modal-template.component';
import { useState, useEffect } from 'react';
import { MainWrapper } from '../../shared/modals/confirm-modal';
import TextInput from "../../shared/components/inputs/text-input.component";
import AutocompleteWithChips from "../../shared/components/autocomplete-with-chips.component";
import {Alert} from "@material-ui/lab";
import ModalButtonContainer from '../../shared/components/buttons/modal-button-container.component';
import Button from '../../shared/components/buttons/button.component';
import { post, patch } from "../../shared/utils/api.utils";

const PanelComponent = ({panel, setPanel, loadedChoice, showErrors}) => {
  return (
    <>
      <AutocompleteWithChips
        addData={(companyId) => {
          if (!(loadedChoice.find(({ id }) => id === companyId))) {
            return;
          }
          setPanel((panel) => panel.concat([companyId]))
        } }
        deleteData={(company) => {
          setPanel((panel) => panel.filter((id) => id !== company.id))
        }}
        dataChoice={loadedChoice}
        data={panel.map(companyId => ({id: companyId, label: loadedChoice.find(({ id }) => id === companyId).label}))}
        placeholder="Rechercher une entreprise"
      />
      {
        showErrors && panel?.length === 0 &&
          <div>
          <Alert severity="error">Veuillez sélectionner au moins une entreprise</Alert>
          </div>
      }
    </>
  );
}

export default function CompanyListModal(props) {
  const { loadedCompanies, setShouldRefresh, itemToModify, ...modalProps } = props;
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
	const [showErrors, setShowErrors] = useState(false);
  const [companyPanel, setCompanyPanel] = useState([]);

  useEffect(() => {
    if (modalProps.showModal)
      return;
    setTitle('');
    setCompanyPanel([]);
  }, [modalProps.showModal]);

  useEffect(() => {
    if (!itemToModify)
      return;
    setTitle(itemToModify.title);
    setCompanyPanel(itemToModify.content);
  }, [itemToModify]);

  const modifyItem = async(companyListId, title, companies) => {
    if (!title.trim() || companies.length === 0) {
      setShowErrors(true);
      return;
    }
    setLoading(true);
    patch(`/company-list/${companyListId}`, { title, companies }).finally(() => {
      modalProps.closeModal();
      setShouldRefresh(true);
      setLoading(false);
    });
  }

  const createItem = async(title, companies) => {
    if (!title.trim() || companies.length === 0) {
      setShowErrors(true);
      return;
    }
    setLoading(true);
    post("/company-list", { title, companies }).finally(() => {
      modalProps.closeModal();
      setShouldRefresh(true);
      setLoading(false);
    });
  }


  return (
    <ModalTemplate
      width="50%"
      height={ props.height ?? 'auto' }
      {...modalProps}
      closeModal={() => {
        if (loading) return;
        modalProps.closeModal();
      }}
    >
      <MainWrapper>
        <TextInput
          value={title}
          label="Titre"
          error={
            showErrors &&
            !title.trim()
          }
          onChangeHandler={e => setTitle(e.target.value)}
          errorText="Un titre est obligatoire."
          required
          style={{ width: 40 }}
        />
        <PanelComponent
          panel={companyPanel}
          setPanel={setCompanyPanel}
          loadedChoice={Array.from(loadedCompanies)}
          showErrors={showErrors}
          style={{ flexGrow: 2 }}
        />
        <ModalButtonContainer>
          <Button uppercase onClick={() => {
            if (itemToModify)
              modifyItem(itemToModify.companyListId, title , companyPanel);
            else
              createItem(title, companyPanel);
          }} style={{ paddingBottom: 15, paddingTop: 15}}>Enregistrer</Button>
        </ModalButtonContainer>
      </MainWrapper>
    </ModalTemplate>
  );
}
