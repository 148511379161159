import React, { useState, createContext } from 'react';

const CreateIntercoContext = createContext();

const CreateIntercoProvider = (props) => {
	const [formData, setFormData] = useState({
		infoForm: null,
		skillsForm: null,
		experiencesForm: null,
		wishesForm: null,
		pricesForm: null,
		documentsForm: null
	});
	return (
		<CreateIntercoContext.Provider value={[formData, setFormData]}>
			{props.children}
		</CreateIntercoContext.Provider>
	);
};

export { CreateIntercoContext, CreateIntercoProvider };