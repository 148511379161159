import styled from "styled-components";

export const ProfileInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	background-color: white;
	box-shadow: 3px 0 20px rgba(0, 0, 0, 0.3);
	border-radius: 70px;
	padding: 4em;
`;

export const ProfileTitle = styled.div`
	text-align: right;
	font-family: "Prompt Black";
	font-size: 41px;
	color: #241f47;
	line-height: 35px;

	.anonymous {
		display: block;
  		color: #7978a8;
  		font-family: "Prompt Light Italic";
  		font-size: 22px;
	}
`;

export const ActionButton = styled.button`
	font-family: "Prompt";
	font-size: 14px;
	color: #241f47;
	padding: 10px 0;
	outline: none;
	border: 1px solid #f296ac;
	border-radius: 15px;
	cursor: pointer;
	background-color: #FFF;
	transition: all 300ms ease;

	:hover {
		background-color: #f9d4de;
	}
`;

export const DeleteWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	cursor: pointer;

	&:hover {
		& svg > g > g {
			fill: #f296ac;
		}
	}
`;