import React from "react";
import styled from "styled-components";

import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as UploadIcon } from "../../../svg-assets/upload.svg";

export default ({ onFileChange, companyLogo }) => {
	return (
		<InputWrapper htmlFor="contained-button-file">
			<div className="filename">{companyLogo ? companyLogo : "Pas de logo ajouté"}</div>
			<input
				accept="image/*"
				id="contained-button-file"
				type="file"
				style={{ display: "none" }}
				onChange={onFileChange}
			/>
			<IconButton
				color="#42387a"
				aria-label="upload picture"
				component="span"
			>
				<UploadIcon />
			</IconButton>
		</InputWrapper>
	);
};

const InputWrapper = styled.label`
	width: 100%;
	display: flex;
	align-items: center;
	border: 1px solid #ffc4ce;
	border-radius: 28px;
	padding: 11px 27px;
	margin-bottom: 24px;
	font-family: "Prompt Light";
	color: #42387a;
	cursor: pointer;

	& > .filename {
		flex: 1;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;