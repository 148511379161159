import moment from "moment";
import styled from "styled-components";
import Image from "../../../../shared/components/images/image.component";

export default function Message({ name, message, isSenderSelf }) {
  return (
    <MainWrapper>
      {
        isSenderSelf ?
          <div style={{width: '45px'}} />
        :
          <ImageWrapper left>
            <Image src="/assets/icons/big_avatar_company.png" width="35px"/>
          </ImageWrapper>
      } 
      <MessageWrapper>
        <MessageHeader>
          <div className="grey">{ moment(message.createdAt).format("DD/MM/YY - HH[h]mm") }</div>
          <div className="name">{ name }</div>
        </MessageHeader>
        <MessageContent>
          { message.content }
        </MessageContent>
      </MessageWrapper>
      {
        isSenderSelf ?
          <ImageWrapper>
            <Image src="/assets/icons/big_avatar_company.png" width="35px"/>
          </ImageWrapper>
        :
          <div style={{width: '45px'}} />
      }
    </MainWrapper>
  );
}

const MessageWrapper = styled.div`
  box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.1);
  border: 1px solid #d8d8d8;
	border-radius: 40px;
  padding: 10px 30px;
  flex: 1;
`;

const MainWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  margin: 15px;
  ${props => props.left ? 'margin-left: 0' : 'margin-right: 0'}; 
`

const MessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .grey {
    color: #a1a1a1;
  }
  
  & .name {
    font-weight: bold;
  }
`

const MessageContent = styled.div`
  margin-top: 5px;
  font-size: 18px;
  color: #a1a1a1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
  
  
