import { InputBase } from "@material-ui/core";
import Button from "../../../../shared/components/buttons/button.component";
import { useState } from 'react';
import { post } from "../../../../shared/utils/api.utils";
import styled from "styled-components";

export default function MessageInput({ correspondant, setRefreshChannel }) {
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const sendMessage = (e) => {
    e.preventDefault();
    const content = e.target.message.value;
    e.target.reset();
    setIsSendingMessage(true);
    post(`/messages/${correspondant.userId}`, {
      content,
    }).finally((messages) => {
      setIsSendingMessage(false);
      setRefreshChannel(true);
    });
  };

  return (
    <>
      <Ruler />
      <form onSubmit={sendMessage}>
      <InputBase
        id="outlined-multiline-static"
        fullWidth
        multiline
        rows={4}
        placeholder="Texte..."
        name="message"
        inputProps={{ maxLength: 2000 }}
      />
      <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="submit"
          secondary
          disabled={isSendingMessage}
        >
          Envoyer
        </Button>
      </div>
      </form>
    </>
  );
}

const Ruler = styled.div`
  width: 100%;
  height: 4px;
  margin-top: 20px;
  background-color: #d8d8d8;
`
