import React from 'react';
import Modal from 'react-modal';
import styled from "styled-components";
import {ReactComponent as Times } from "../../svg-assets/times.svg";

Modal.setAppElement("#root");

export default function ModalTemplate({ showModal, closeModal, children, width, height }) {
  const modalCustomStyles = {
    content: {
      height: height ?? undefined,
      maxHeight: '90vh',
      width: width ?? '100%',
      borderRadius: 70,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: "0px",
      zIndex: 101
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: 'linear-gradient(to right, #241f47bb 0%, #42387abb 100%)',
      zIndex: 100
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      style={ modalCustomStyles }
    >
      <ModalBody>
        <CloseButtonContainer>
          <CloseIcon onClick={closeModal}/>
        </CloseButtonContainer>
        {children}
      </ModalBody>
    </Modal>
  );
}

const ModalBody = styled.div`
  display: block;
  padding: 20px;
  height: 100%;
`;

const CloseButtonContainer = styled.div`
  width: calc(100% - 50px);
  height: 20px;
  margin-top: 30px;
  justify-content: flex-end;
  display: flex;
`;

const CloseIcon = styled(Times)`
  cursor: pointer;
`;