import React from 'react';
import styled from "styled-components";
import { TextField, withStyles } from "@material-ui/core";

export default function Input(props) {
	return (
		<InputContainer noMargin={props.noMargin}>
			<StyledTextField
        name={props.name}
				id={props.id}
				type={props.type || "text"}
				label={props.label}
				placeholder={props.placeholder}
				variant="outlined"
				value={props.value}
				onChange={props.onChangeHandler}
				disabled={props.disabled}
				required={props.required}
				error={props.error}
				multiline={props.multiline}
				rows={props.rows || 1}
				onBlur={(e) => {
					if (props.onBlur) {
						props.onBlur(e);
					}
				}}
				onKeyDown={props.onKeyDown}
				helperText={props.error ? props.errorText : ""}
			/>
		</InputContainer>
	);
}

const InputContainer = styled.div`
	width: 100%;
	margin-bottom: ${props => props.noMargin ? "0px" : "24px"};
`;

const StyledTextField = withStyles({
	root: {
		width: '100%',
		'& label': {
			fontFamily: 'Prompt Light',
			color: '#42387a',
			left: '13px',
		},
		'& label.Mui-focused, & label.Mui-disabled': {
			color: '#42387a',
		},
		'& .MuiInputLabel-asterisk': {
			color: '#f2a0ad',
		},
		'& .MuiOutlinedInput-root': {
			fontFamily: 'Prompt Light',
			color: '#42387a',
			'& input': {
				padding: "18.5px 27px !important",
				zIndex: 1,
			},
			'& textarea': {
				zIndex: 1,
			},
			'& fieldset': {
				backgroundColor: 'white',
				borderColor: '#ffc4ce',
				width: '100%',
				boxSizing: 'border-box',
				borderRadius: '28px',
				borderWidth: '1px',
				padding: '0 20px',
			},
			'&:hover fieldset': {
				borderColor: '#f2a0ad',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#f2a0ad',
			},
			'&.Mui-disabled fieldset': {
				fontWeight: 'bold',
				borderColor: '#ffc4ce',
			},
			'&.Mui-disabled fieldset': {
				fontWeight: 'bold',
				borderColor: '#ffc4ce',
			}
		},

	},
})(TextField);