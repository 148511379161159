import React from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { ExpandMore, ExpandLess } from '@material-ui/icons';

/**
 * 
 * @param {any} children Contenu de la cellule du header.
 * @param {string} label Nom du champ associé à cette cellule.
 * @param {object} currentSort Méthode de tri utilisé actuellement.
 * @param {function} sortBy Fonction permettant de changer la méthode de tri actuelle.
 */
export default function TableHeaderCell({ children, label, currentSort, sortBy, colSpan }) {
	return (
		<StyledTableHeaderCell colSpan={colSpan} deactivated={!sortBy} onClick={() => sortBy(label)}>
			<span>{children}</span>
			{
				sortBy ?
					<IconButton
						size="small"
					>
						{
							currentSort && currentSort.field === label ?
								<>
									{
										currentSort.mode === 0 || currentSort.mode === 1 ?
											<ExpandLess color={currentSort.mode === 1 ? "action" : "disabled"} />
											:
											<ExpandMore />
									}
								</>
								:
								<ExpandLess color="disabled" />
						}
					</IconButton>
					: null
			}
		</StyledTableHeaderCell>
	);
};

const StyledTableHeaderCell = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	user-select: none;
	border-radius: 25px;
	border: 1px solid #F194AC;
	padding: 5px 6px 5px 16px;
	color: #42387a;
	font-family: "Prompt";
	font-size: 14px;
        grid-column: span ${props => props.colSpan ? props.colSpan : "1"};
	cursor: ${props => props.deactivated ? "auto" : "pointer"};
	pointer-events: ${props => props.deactivated ? "none" : "auto"};

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
`;