import Image from "../../../../shared/components/images/image.component";
import { ReactComponent as MailIcon } from "../../../../svg-assets/mail.svg";
import { ReactComponent as EyeIcon  } from "../../../../svg-assets/oeil.svg";
import { LightTooltip } from "../../../../shared/utils/styled-components-library.utils";
import styled from "styled-components";

export default function ProfileCardSidebar({ profile, deleteProfile }) {
	const messagesReceivedNb = profile.stats?.mail;
	const profileSeenNb = profile.stats?.seen;

	return (
		<MainWrapper>
				{ messagesReceivedNb !== undefined && messagesReceivedNb !== 0 && <LightTooltip placement="top" title={`${messagesReceivedNb} messages(s) reçu(s)`}>
							<InfoIndicator>
								<div className="image">
									<MailIcon />
								</div>
								<span>{messagesReceivedNb}</span>
							</InfoIndicator>
						</LightTooltip>
				}
				{ profileSeenNb !== undefined && <LightTooltip placement="top" title={`Profil consulté ${profileSeenNb} fois`}>
							<InfoIndicator>
								<div className="image">
									<EyeIcon />
								</div>
								<span>{profileSeenNb}</span>
							</InfoIndicator>
						</LightTooltip>
				}
				<div className="deleteButton" onClick={() => deleteProfile(profile)}>
					<Image src="/assets/icons/bin.png" width="27px" />
				</div>
		</MainWrapper>
	);
}

const MainWrapper = styled.div`
	width: 30px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	& > * {
		margin-bottom: 20px;
	}

	& .deleteButton {
		margin-top: auto;
		cursor: pointer;

		&:hover {
			filter: saturate(2);
			opacity: 1;
		}
	}
`;

const InfoIndicator = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	user-select: none;
	background-image: linear-gradient(to right, #f9cdd5 0%, #f29daa 100%);
	height: 40px;
	width: 40px;
	border-radius: 20px;

	& span {
		font-family: "Prompt";
		font-size: 12px;
		color: white;
	}
	
	& .image {
		width: 18px;
		height: 18px;
	}

	& svg > g > g {
		fill: white;
	}
`;

