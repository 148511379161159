import React from "react";
import styled from "styled-components";
import { ActionButton } from "../shared/styled-components.library";
import { LightTooltip } from "../../../../shared/utils/styled-components-library.utils";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmModal from "../../../../shared/modals/confirm-modal";

// Si setProfile est nul, il n'est pas proposé d'afficher le profil
const ProfileCardActions = ({ profile, setProfile, updateProfile, updateProfileActivation, renewProfile, nameSpace, setShouldRefreshUser }) => {
  const [showConfirmActivation, setShowConfirmActivation] = React.useState(false);
  const [showConfirmRenew, setShowConfirmRenew] = React.useState(false);
  const history = useHistory();
	const location = useLocation();
  const tab = nameSpace === 'needs' ? 'besoins' : 'intercos';
  const itemName = nameSpace === 'needs' ? 'besoin' : 'profil';

  const renew = async() => {
    await renewProfile(profile);
    setShouldRefreshUser(true);
  };

  const activationMessage = (profile) => {
    if (profile.isActive) {
      return `Êtes-vous sûr de vouloir désactiver ce ${itemName} ? Toutes les candidatures seront perdues.`;
    } else {
      return `Êtes-vous sûr de vouloir réactiver ce ${itemName} ?${ profile.won ? ` Toutes les candidatures seront perdues.` : '' }`;
    }
  };

  return (
    <>
      { setProfile && <ActionButton onClick={() => history.push(`${location.pathname}?onglet=${tab}&show=${profile.hash}`)}>Afficher</ActionButton> }
      <ActionButton onClick={() => updateProfile(profile)}>Modifier</ActionButton>
      <ActionButton onClick={setShowConfirmActivation}>{profile.isActive ? "Désactiver" : "Réactiver"}</ActionButton>
      { (profile.isActive === 1 || nameSpace === 'needs' && profile.won === true) &&
          <ActionButton onClick={() => history.push(`${location.pathname}?onglet=${tab}&candidatures=${profile.hash}`)}>Gérer les { nameSpace === 'needs' ? 'candidatures reçues' : 'positionnements' }</ActionButton> 
      }
      { profile.isActive === 1 &&
        <>
          <LightTooltip
            placement="top"
            title={ `Fait remonter ${nameSpace === 'needs' ? 'le besoin' : 'l\'interco'} dans les résultats de recherche` }
          >
            <ActionButton onClick={() => setShowConfirmRenew(true)}>Renouveler</ActionButton>
          </LightTooltip>
          { !(nameSpace === 'needs' && profile.won === true) &&
              <ActionButton onClick={() => history.push(`${location.pathname}?onglet=${tab}&matching=${profile.hash}`)}>Matching automatique</ActionButton>
          }
        </>
      }
      <ConfirmModal
          onConfirm={() => updateProfileActivation(profile)}
          onCancel={() => setShowConfirmActivation(false)}
          showModal={showConfirmActivation}
          message={activationMessage(profile)}
          closeModal={() => setShowConfirmActivation(false)}
      />
      <ConfirmModal
          onConfirm={renew}
          onCancel={() => setShowConfirmRenew(false)}
          showModal={showConfirmRenew}
          message={`Êtes-vous sûr de vouloir faire remonter ce ${itemName} dans les résultats de recherche ?`}
          closeModal={() => setShowConfirmRenew(false)}
      />
    </>
  );
};

export default ProfileCardActions;
