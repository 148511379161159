import React from 'react';
import styled from "styled-components";
import Image from "../../../shared/components/images/image.component";
import { useHistory, useLocation } from "react-router-dom";
import { abstractChoice } from '../../../pages/auth/personal-account.page'; 

export function Sidebar({ user, currentTab }) {
  const history = useHistory();
  const location = useLocation();
  return (
    <MainWrapper>
      {
        user ?
          <>
            <AvatarWrapper>
              {
                user.companyLogo ?
                  <Image src={`https://1terco-uploads.s3.eu-west-3.amazonaws.com/userUploads/${user.companyLogo}`} width="100%" />
                  : <Image src={user.profile === "freelance" ? "/assets/icons/big_avatar_freelance.png" : "/assets/icons/big_avatar_company.png"} width="100%" />
              }

            </AvatarWrapper>
            <NameWrapper>
              <span className="hello">Bonjour</span>
              <span>{user ? user.firstname : "Utilisateur"}</span>
              <span className="type">{user ? (user.profile === "freelance" ? "Freelance" : user.companyName) : ""}</span>
            </NameWrapper>
            <ActionsWrapper>
              {
                    abstractChoice.getAllTabKeys({ user })
                      .map(key => {
                        if (!abstractChoice.validationFunction({ tab: key, conditions: { user } }))
                          return null;
                        return (
                          <ActionTab key={key} active={currentTab === key} onClick={() => {
                            abstractChoice.changeTab({ newTab: key });
                            history.replace(`${location.pathname}?onglet=${abstractChoice.getAttachments({ tab: key }).queryParam}`);
                          }}>
                            <span>{key}</span>
                          </ActionTab>
                        )
                  })
              }
            </ActionsWrapper></>
          : null
      }
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  width: 350px;
  min-width: 350px;
  flex: 1 1 auto;
  padding-bottom: auto;
  padding-top: 35px;
  padding-left: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
`;

const AvatarWrapper = styled.div`
  width: 195px;
  height: 195px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NameWrapper = styled.div`
  margin: 17px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  font-size: 20px;
  line-height: 22px;
  font-family: "Prompt";
  color: #241f47;
  
  & > * {
    margin: 2px 0px;
  }
  
  & .hello {
    font-family: "Prompt Light";
    color: #58567a;
  }
  
  & .type {
    color: #817cc4;
    font-family: "Prompt Light Italic";
    opacity: 0.7;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  & > * {
    margin: 20px 0px;
  }
`;

const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  & svg > g > g {
    fill: ${props => props.active ? "#FFF" : "#241f47"};
    stroke: ${props => props.active ? "#FFF" : "#241f47"};
  }
`;

const ActionTab = styled.div`
  padding: 18px 20px;
  background: ${props => props.active ? "linear-gradient(to right, #f9cdd5 0%, #f29daa 100%);" : "#FFF"};
  color: ${props => props.active ? "#FFF" : "#241f47"};
  border-radius: 50px;
  font-size: 14px;
  font-family: "Prompt";
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: background 0.3s ease;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  transition: 150ms;
  
  ${props => !props.active ? `
    &:hover {
      background: #F8F8F8;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    }
  `: ""}
`;