import React, { useState, useEffect, useContext } from 'react';
import styled from "styled-components";
import { Alert } from "@material-ui/lab";
import { useLocation } from "react-router-dom";

import { AuthContext } from "../../contexts/auth.context";
import { BigTitle } from "../../shared/utils/styled-components-library.utils";
import PasswordInput from "../../shared/components/inputs/password-input.component";
import Button from "../../shared/components/buttons/button.component";
import { isValid } from "../../shared/utils/input-validation.utils";
import { post } from "../../shared/utils/api.utils";

export default function UpdatePasswordPage() {
	const [auth] = useContext(AuthContext);
	const location = useLocation();
	const [token, setToken] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [submitState, setSubmitState] = useState({ sent: false, type: null, message: "" });
	const [passwordModified, setPasswordModified] = useState(false);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const token = params.get("token") || "";
		setToken(token);
	}, [location]);

	useEffect(() => {
		if (auth.logged) {
			window.location.href = "/";
		}
	}, [auth]);

	const submitHandler = () => {
		if (!passwordModified) {
			setSubmitState({ sent: false, type: null, message: "" });
			post("/auth/reset-password", { password: newPassword, token }).then(response => {
				if (response && response.success) {
					setSubmitState({ sent: true, type: "success", message: "Votre mot de passe a été modifié avec succès." });
					setPasswordModified(true);
				} else {
					const errorMessage = (response.error && response.message) || "Une erreur s'est produite lors de la modification de votre mot de passe.";
					setSubmitState({ sent: true, type: "error", message: errorMessage });
				}
			});
		}
	}

	const shouldDisableSubmit = () => {
		return !isValid("password", newPassword) || newPassword.trim() !== confirmNewPassword.trim() || passwordModified;
	}

	return (
		<PageContainer>
			<BigTitle>
				<div>RÉINITIALISATION DE</div>
				<div>VOTRE MOT DE PASSE</div>
			</BigTitle>
			<PasswordFormContainer>
				<PasswordInput showPasswordRules showPasswordStrength value={newPassword} onChangeHandler={(e) => setNewPassword(e.target.value)} label="Nouveau mot de passe" required error={!!newPassword && !isValid("password", newPassword)} errorText="Le mot de passe entré n'est pas valide." />
				<PasswordInput value={confirmNewPassword} onChangeHandler={(e) => setConfirmNewPassword(e.target.value)} label="Confirmation nouveau mot de passe" required error={!!newPassword && !!confirmNewPassword && newPassword !== confirmNewPassword} errorText="Les deux mots de passe ne correspondent pas." />
				<SubmitButtonContainer>
					<Button onClick={submitHandler} disabled={shouldDisableSubmit()}>Réinitialiser</Button>
					{
						submitState.sent ? <StyledAlert severity={submitState.type}>{submitState.message}</StyledAlert> : null
					}
				</SubmitButtonContainer>
			</PasswordFormContainer>
		</PageContainer>
	);
}

const PageContainer = styled.div`
	width: 60%;
	margin: 20px 20%;
`;

const PasswordFormContainer = styled.div`
	width: 50%;
	margin: 0 25%;
`;

const SubmitButtonContainer = styled.div`
	margin: 30px 0px 100px 0px;
`;

const StyledAlert = styled(Alert)`
	margin: 20px 0px;
`;