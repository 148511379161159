import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Alert as Warning } from "@material-ui/lab";
import moment from "moment";

import SaveSearchModal from "../../../modals/save-search.modal";
import DeleteSearchModal from "../../../modals/delete-search.modal";

import { FiltersContext, FiltersProvider } from "../../../../contexts/filters.context";
import { NeedFiltersContext, NeedFiltersProvider } from "../../../../contexts/need-filters.context";

import Button from "../../../../shared/components/buttons/button.component";
import SearchInput from "../shared/search-input.component";
import CheckboxInput from "../../../../shared/components/inputs/checkbox-input.component";
import { DeleteWrapper } from "../shared/styled-components.library";
import { ReactComponent as DeleteIcon } from "../../../../svg-assets/poubelle.svg";
import { ReactComponent as RedirectIcon } from "../../../../svg-assets/rediriger.svg";
import { get } from "../../../../shared/utils/api.utils";
import { useHistory } from "react-router-dom";
import {
  HorizontalSeparator,
} from "../../../../shared/utils/styled-components-library.utils";

export default function ItemAlertComponent({
  user,
  loadedFields,
  loadedWorkLocations,
  searchNameSpace,
}) {
  const history = useHistory();
  const [inputSearch, setInputSearch] = useState("");
  const [multipleSelectActive, setMultipleSelectActive] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState([]);

  const [alerts, setAlerts] = useState([]);
  const [alertsLoaded, setAlertsLoaded] = useState(false);
  const [displayedAlerts, setDisplayedAlerts] = useState([]);
  const [isCreatingSearch, setIsCreatingSearch] = useState(false);

  const [showUpdateSearchModal, setShowUpdateSearchModal] = useState(false);
  const [searchToUpdate, setSearchToUpdate] = useState(null);

  const [showDeleteSearchModal, setShowDeleteSearchModal] = useState(false);
  const [searchesToDelete, setSearchesToDelete] = useState([]);

  /**
   * Fonction appelée au chargement de la page, mais aussi après la création et la modification d'une alerte.
   */
  const refreshUserAlerts = useCallback(() => {
    setAlertsLoaded(false);
    get(`/users/${user.userId}/search?nameSpace=${searchNameSpace}`).then((alerts) => {
      if (alerts) {
        setAlerts(alerts);
        setDisplayedAlerts(alerts);
        setAlertsLoaded(true);
      }
    });
  }, [user, searchNameSpace]);

  const toggleMultipleSelect = () =>
    setMultipleSelectActive((prevState) => !prevState);

  const toggleSearchCheckbox = (search) => {
    const found = selectedAlerts.find(
      (alert) => alert.userSearchId === search.userSearchId
    );
    if (found) {
      setSelectedAlerts((prevAlerts) =>
        prevAlerts.filter(
          (_alert) => _alert.userSearchId !== search.userSearchId
        )
      );
    } else {
      setSelectedAlerts((prevAlerts) => prevAlerts.concat([search]));
    }
  };

  const toggleAllCheckbox = () => {
    if (displayedAlerts.length === selectedAlerts.length) {
      setSelectedAlerts([]);
    } else {
      setSelectedAlerts(displayedAlerts);
    }
  };

  const deleteSearch = (search) => {
    setSearchesToDelete([search]);
    setShowDeleteSearchModal(true);
  };

  const deleteSearches = () => {
    if (selectedAlerts.length) {
      setSearchesToDelete(selectedAlerts);
      setShowDeleteSearchModal(true);
    }
  };

  useEffect(() => {
    refreshUserAlerts();
  }, [refreshUserAlerts]);

  useEffect(() => {
    if (!inputSearch.trim().length) {
      setDisplayedAlerts(alerts);
    } else {
      const searchValue = inputSearch.toLowerCase();
      const alertsToDisplay = alerts.filter(
        (alert) => alert.title.toLowerCase().indexOf(searchValue) !== -1
      );
      setDisplayedAlerts(alertsToDisplay);
    }
  }, [inputSearch, alerts]);

  const updateSearchModalProps = {
    context: searchNameSpace === 'needs' ? NeedFiltersContext : FiltersContext,
    refreshUserAlerts,
    showModal: showUpdateSearchModal,
    closeModal: () => setShowUpdateSearchModal(false),
    mode: isCreatingSearch ? "create" : "edit",
    loadedWorkLocations,
    userId: user && user.userId,
    search: searchToUpdate,
    loadedFields: loadedFields,
  };

  return (
    <>
      <TopWrapper>
        <SearchInput
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          placeholder="Nom de l'alerte"
        />
        <Button
          secondary
          gradient
          onClick={() => {
            setIsCreatingSearch(true);
            setShowUpdateSearchModal(true);
          }}
        >
          <span>+</span>&nbsp;&nbsp;&nbsp;&nbsp;Créer une alerte
        </Button>
      </TopWrapper>
      {alerts.length ? (
        <>
          <SelectMultipleWrapper active={multipleSelectActive}>
            <span>
              {multipleSelectActive && (
                <span>
                  <CheckboxInput
                    checked={selectedAlerts.length === displayedAlerts.length}
                    onChangeHandler={toggleAllCheckbox}
                  />
                </span>
              )}
              <span onClick={toggleMultipleSelect}>Sélection multiple</span>
            </span>
            {multipleSelectActive && (
              <IconButton onClick={deleteSearches}>
                <DeleteIcon />
              </IconButton>
            )}
          </SelectMultipleWrapper>
          <AlertsWrapper>
            { !alertsLoaded ? <CircularProgress /> :
            displayedAlerts.map((alert, index) => {
              const matchingProfiles = alert.stats?.matchingProfiles;
              const matchingNewProfiles = alert.stats?.matchingNewProfiles;
              return (
                <div key={alert.userSearchId}>
                  <AlertRow key={alert.userSearchId}>
                    {multipleSelectActive && (
                      <div>
                        <CheckboxInput
                          checked={selectedAlerts.some(
                            (_alert) =>
                              _alert.userSearchId === alert.userSearchId
                          )}
                          onChangeHandler={() => toggleSearchCheckbox(alert)}
                        />
                      </div>
                    )}
                    <div className="title" title={alert.title}>
                      {alert.title}
                    </div>
                    <div className="date">
                      <span className="grey">Date de création</span>
                      {moment(alert.createdAt).format("DD.MM.YYYY")}
                    </div>
                    <div className="profileCount">
                      {matchingProfiles} { searchNameSpace === 'needs' ? 'besoins' : 'interco' }(s)
                      <span className="grey">dont</span>
                      {matchingNewProfiles} nouveau(x)
                    </div>
                    {!multipleSelectActive && (
                      <>
                        <div className="update">
                          <Button
                            style={{ color: "#241f47" }}
                            onClick={() => {
                              setSearchToUpdate({ ...alert });
                              setIsCreatingSearch(false);
                              setShowUpdateSearchModal(true);
                            }}
                            outlineButton
                            secondary
                          >
                            Modifier
                          </Button>
                        </div>
                        <div className="redirect">
                          <RedirectWrapper
                            onClick={() => {
                              localStorage.setItem(
                                searchNameSpace === 'needs' ? "needs-filters" : "profiles-filters",
                                alert.payload
                              );
                              history.push(searchNameSpace === 'needs' ? "/liste-besoin" : "/bourse-interco");
                            }}
                          >
                            <RedirectIcon />
                          </RedirectWrapper>
                        </div>
                        <div className="delete">
                          <DeleteWrapper onClick={() => deleteSearch(alert)}>
                            <DeleteIcon />
                          </DeleteWrapper>
                        </div>
                      </>
                    )}
                  </AlertRow>
                  {index !== displayedAlerts.length - 1 && (
                    <HorizontalSeparator color="rgba(178,178,178, 0.4)" />
                  )}
                </div>
              );
            })}
          </AlertsWrapper>
        </>
      ) : alertsLoaded ? (
        <Warning style={{ width: "auto" }} severity="warning">
          Vous n'avez pas encore créé d'alertes. Cliquez sur le bouton "Créer
          une alerte" pour en ajouter une.
        </Warning>
      ) : <CircularProgress />}
      { searchNameSpace === 'needs'
        ? <NeedFiltersProvider>
            <SaveSearchModal {...updateSearchModalProps} />
          </NeedFiltersProvider>
        : <FiltersProvider>
            <SaveSearchModal {...updateSearchModalProps} />
          </FiltersProvider>
      }
      <DeleteSearchModal
        showModal={showDeleteSearchModal}
        closeModal={() => setShowDeleteSearchModal(false)}
        user={user}
        searches={searchesToDelete}
        setAlerts={setAlerts}
      />
    </>
  );
}

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectMultipleWrapper = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) => (props.active ? "2px solid #241f47" : "none")};

  & > span {
    color: ${(props) => (props.active ? "#241f47" : "#42387abb")};
    font-family: "Prompt";
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
  }
`;

const AlertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlertRow = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "Prompt";
  color: #42387a;
  border-bottom: 1px solid #42387a;
  padding-left: 0.7em;

  & .title {
    flex: 5;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .date {
    flex: 5;
  }

  & .profileCount {
    flex: 6;
    font-size: 16px;
  }

  & .update {
    flex: 3;
  }

  & .redirect {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .delete {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & span.grey {
    opacity: 0.5;
    margin: 0 5px;
  }
`

export const RedirectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  cursor: pointer;
`;;
