import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { IconButton, CircularProgress } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useDropzone } from "react-dropzone";

import { uploadFile } from "../utils/files.utils";
import Image from "./images/image.component";

export default function DragDrop({
	label,
	file,
	setFile,
	updateStatus,
	imagePreview = false,
	acceptedFileExtensions,
	acceptedFileExtensionsLabels,
	uploadCallback,
	removeFile,
	disableDelete = false
}) {
	const [alert, setAlert] = useState({
		show: false,
		type: null,
		message: null
	});
	const [status, setStatus] = useState("idle"); // statuts disponibles : 'idle', 'loading'
	const [filename, setFilename] = useState(null);
	const onDrop = useCallback(
		acceptedFiles => {
			setAlert({ show: false, type: null, message: null });
			// on n'utilise que le premier fichier déposé
			const newFile = acceptedFiles[0];
			if (newFile) {
				if (
					["image/jpeg", "image/png", "image/jpg"].indexOf(newFile.type) > -1
				) {
					newFile.preview = URL.createObjectURL(newFile);
				}

				setFile(newFile);
				setStatus("loading");
				if (updateStatus) updateStatus("loading");

				uploadFile(newFile).then(uploadedFileName => {
					if (!!uploadedFileName) {
						setAlert({
							show: true,
							type: "success",
							message:
								"Votre fichier a bien été enregistré ! Vous pouvez à tout moment le changer en cliquant à nouveau sur la zone ci-dessus."
						});
					} else {
						setAlert({
							show: true,
							type: "error",
							message:
								"Une erreur s'est produise lors de l'enregistrement de votre fichier."
						});
					}
					setStatus("idle");
					if (updateStatus) updateStatus("idle");
					if (uploadCallback) {
						uploadCallback(uploadedFileName);
					}
				});
			} else {
				setAlert({
					show: true,
					type: "error",
					message: "Ce type de fichier n'est pas accepté."
				});
			}
		},
		[setFile, uploadCallback] // eslint-disable-line
	);

	const { getRootProps, getInputProps } = useDropzone({
		accept: acceptedFileExtensions,
		onDrop
	});

	useEffect(
		() => {
			if (!file?.name) {
				setAlert({ show: false, type: null, message: null });
			}
		},
		[file]
	);

	useEffect(() => {
		let newName =
			(file?.name.length > 30 ? file?.name.substring(0, 30) : file?.name) ||
			null;

		setFilename(newName);
	});

	return (
		<>
			<DragDropContainer {...getRootProps()}>
				<input {...getInputProps()} />
				<DragDropContent>
					{status === "loading" ? (
						<CircularProgress size={100} />
					) : (
						<>
							{file ? (
								imagePreview ? (
									<Thumbnail>
										<ThumbnailContent>
											<ThumbnailImage src={file.preview} />
										</ThumbnailContent>
									</Thumbnail>
								) : (
									<FilePreview>
										Fichier déposé : <span>{filename}</span>
									</FilePreview>
								)
							) : (
								<>
									<DragDropTextContent>{label}</DragDropTextContent>
									{acceptedFileExtensionsLabels &&
									acceptedFileExtensionsLabels.length > 0 ? (
										<DragDropFilesAccepted>
											Format(s) accepté(s) :{" "}
											{acceptedFileExtensionsLabels.join(", ")}
										</DragDropFilesAccepted>
									) : null}

									<Image
										width="32px"
										src="/assets/icons/download.png"
										alt="download icon"
									/>
								</>
							)}
						</>
					)}
				</DragDropContent>
			</DragDropContainer>
			<AdditionalActionsContainer>
				{!disableDelete &&
					removeFile &&
					file && (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<IconButton
								color="secondary"
								onClick={() => {
									removeFile();
									setAlert({ show: false, type: null, message: null });
								}}
							>
								<Delete />
							</IconButton>
						</div>
					)}
				{alert.show && <Alert severity={alert.type}>{alert.message}</Alert>}
			</AdditionalActionsContainer>
		</>
	);
}

const DragDropContainer = styled.div`
	width: 100%;
	height: 100%;
	min-height: 360px;
	border-radius: 35px;
	box-shadow: 0 0 6px #ccc;
	cursor: pointer;
	margin-bottom: 15px;
	display: flex;
	transition: box-shadow 150ms ease-in;

	&:focus {
		outline: none;
	}

	&:hover {
		box-shadow: 0 0 6px #aaa;
	}
`;

const DragDropContent = styled.div`
	margin: 60px 78px;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	user-select: none;
`;

const DragDropTextContent = styled.p`
	color: #241f47;
	font-size: 16px;
	font-family: "Prompt";
	text-align: center;
`;

const DragDropFilesAccepted = styled.p`
	color: #f296ac;
	font-style: italic;
	font-size: 14px;
	text-align: center;
`;

const Thumbnail = styled.div`
	display: inline-flex;
	width: 300px;
	height: 300px;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
`;

const ThumbnailContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 0;
	object-fit: scale-down;
`;

const ThumbnailImage = styled.img`
	display: block;
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
`;

const FilePreview = styled.div`
	box-sizing: border-box;
	border: 3px dashed #ccc;
	margin: 10px;
	padding: 5% 10%;
	font-size: 24px;
	font-weight: bold;
	font-family: "Prompt";
	text-align: center;
	color: #241f47;
	background-color: #FFF;

	& span {
		font-style: italic;
		font-weight: normal;
	}
`;

const AdditionalActionsContainer = styled.div`
	width: 100%;

	&& {
		& .MuiAlert-root {
			text-align: left;
			width: 100%;
			display: flex;
			align-items: center;
		}
	}

	& > * {
		margin: 10px 0px;
	}
`;
