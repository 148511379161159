import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import ChipContainer from "../../../shared/components/deleteable-chip-container.component";
import { getLocationLabelById } from "../../../shared/utils/profiles.utils";

export default function FiltersSelected({ filters, removeFilterHandler, appliedSearch, removeSearchFilter, workLocations, profilesFound }) {
	const [activeFilters, setActiveFilters] = useState([]);
	useEffect(() => {
		let currentFilters = [];
		if (!!appliedSearch) {
			const searchLabel = appliedSearch.length > 20 ? appliedSearch.substring(0, 20) + "..." : appliedSearch;
			currentFilters = currentFilters.concat([{ label: searchLabel, type: "search" }]);
		}
		if (!!filters.workLocations?.length) {
			currentFilters = currentFilters.concat(filters.workLocations.map(locationId => ({ value: locationId, label: getLocationLabelById(workLocations, locationId), type: "workLocations" })));
		}
		if (!!filters.fields?.length) currentFilters = currentFilters.concat(filters.fields.map(item => ({ ...item, type: "fields" })));
		if (!!filters.profileType?.length) currentFilters = currentFilters.concat(filters.profileType.map(item => ({ ...item, type: "profileType" })));
		if (!!filters.studyLevel?.length) currentFilters = currentFilters.concat(filters.studyLevel.map(item => ({ ...item, type: "studyLevel" })));
		if (!!filters.englishLevel?.length) currentFilters = currentFilters.concat(filters.englishLevel.map(item => ({ ...item, type: "englishLevel" })));
		if (!!filters.experienceYears?.length) currentFilters = currentFilters.concat(filters.experienceYears.map(item => ({ ...item, type: "experienceYears" })));
		if (!!filters.availability?.length) currentFilters = currentFilters.concat(filters.availability.map(item => ({ ...item, type: "availability" })));
		if (!!filters.workFormat?.length) currentFilters = currentFilters.concat(filters.workFormat.map(item => ({ ...item, type: "workFormat" })));
		setActiveFilters(currentFilters);
	}, [appliedSearch, filters, workLocations]);

	const deleteChipHandler = (item) => {
		if (item.type === "search") {
			removeSearchFilter();
		} else {
			removeFilterHandler(item);
		}
	}

	return (
		<>
			{
				!!activeFilters.length ?
					<MainWrapper>
						<FiltersSelectedWrapper>
							<span>Filtres sélectionnés</span>
							<ChipContainer chips={activeFilters} deleteChipHandler={deleteChipHandler} />
						</FiltersSelectedWrapper>
						<span>{profilesFound} profile(s) trouvé(s)</span>
					</MainWrapper>
					: null
			}
		</>
	);
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > span {
		align-self: flex-end;
		font-size: 16px;
		font-family: "Prompt";
		color: #241f47;
	}
`;

const FiltersSelectedWrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > span {
		color: #333333;
		font-size: 14px;
		margin-bottom: 8px;
		font-family: "Prompt";
	}
`;