import React, { useState, createContext } from 'react';

const CreateNeedContext = createContext();

const CreateNeedProvider = (props) => {
	const [formData, setFormData] = useState({
		infoForm: null,
		descriptionForm: null,
                skillsForm: null,
		pricesForm: null,
	});

  return (
		<CreateNeedContext.Provider value={[formData, setFormData]}>
			{props.children}
		</CreateNeedContext.Provider>
  );
};

export { CreateNeedContext, CreateNeedProvider };
