import Image from "../../../shared/components/images/image.component";
import { useState, useEffect } from "react";
import { BigTitle } from "../../../shared/utils/styled-components-library.utils";
import { get, deleteReq } from "../../../shared/utils/api.utils";
import styled from "styled-components";
import { fetchAllCompanies } from "../../../shared/utils/fetch.utils";
import { StyledChip as Chip } from "../../../shared/components/deleteable-chip-container.component";
import DeleteModal from "../../../shared/modals/confirm-modal.js";
import CreateModal from "../../modals/company-list.modal.js";
import Button from "../../../shared/components/buttons/button.component";
import { CircularProgress } from "@material-ui/core";
import { AnimatedButton } from "./company-profiles.component";

export default function ReferralList({ user }) {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedCompanies, setLoadedCompanies] = useState([]);
  const [myCompanyPanels, setMyCompanyPanels] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [focusedItem, setFocusedItem] = useState(null);
  const [itemToModify, setItemToModify] = useState(null);

  useEffect(() => {
    fetchAllCompanies((companies) => {
      setLoadedCompanies(
        companies.map(({ companyId, name }) => ({ id: companyId, label: name }))
      );
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    setIsLoading(true);
    get("/company-list")
      .then((response) => {
        setMyCompanyPanels(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!shouldRefresh) return;
    setIsLoading(true);
    get("/company-list")
      .then((response) => {
        setMyCompanyPanels(response);
      })
      .finally(() => {
        setIsLoading(false);
        setShouldRefresh(false);
      });
  }, [shouldRefresh]); // eslint-disable-line

  const deleteItem = async (companyListId) => {
    deleteReq(`/company-list/${companyListId}`).finally(() => {
      setShowDeleteModal(false);
      setShouldRefresh(true);
    });
  };

  return (
    <>
      <MainWrapper>
        <BigTitle>RÉFÉRENCEMENTS</BigTitle>
        {shouldRefresh}

        {isLoading ? (
          <CircularProgress />
        ) : (
          <CompanyListsWrapper>
            {myCompanyPanels.map(({ title, content, companyListId }) => (
              <CompanyListCard key={companyListId}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2>{title}</h2>
                    {user.type === "admin" && (
                      <div
                        className="deleteButton"
                        onClick={() => {
                          setFocusedItem(companyListId);
                          setShowDeleteModal(true);
                        }}
                      >
                        <Image src="/assets/icons/bin.png" width="27px" />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      gap: 10,
                      flexWrap: "wrap",
                    }}
                  >
                    {content.map((id) => (
                      <Chip
                        key={id}
                        label={
                          loadedCompanies.find(
                            ({ id: companyId }) => companyId === id
                          ).label
                        }
                      />
                    ))}
                  </div>
                </div>
                {user.type === "admin" && (
                  <ButtonWrapper>
                    <Button
                      secondary
                      onClick={() => {
                        setItemToModify({ title, content, companyListId });
                        setShowCreateModal(true);
                      }}
                    >
                      Modifier
                    </Button>
                  </ButtonWrapper>
                )}
              </CompanyListCard>
            ))}
          </CompanyListsWrapper>
        )}
        {user.type === "admin" && (
          <div style={{ width: "25%", marginTop: 30 }}>
            <AnimatedButton onClick={() => setShowCreateModal(true)}>
              Créer un référencement
            </AnimatedButton>
          </div>
        )}
      </MainWrapper>
      <DeleteModal
        showModal={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        onConfirm={() => deleteItem(focusedItem)}
        onCancel={() => setShowDeleteModal(false)}
        message={`Êtes-vous sûr de vouloir supprimer ce référencement ? Tous les besoins utilisant ce référencement seront supprimés`}
      />
      <CreateModal
        itemToModify={itemToModify}
        showModal={showCreateModal}
        closeModal={() => setShowCreateModal(false)}
        loadedCompanies={loadedCompanies}
        setShouldRefresh={setShouldRefresh}
      />
    </>
  );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CompanyListsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  margin: 30px 0px;
`;

const CompanyListCard = styled.div`
  background-color: "red";
  width: ${(props) =>
    props.someWidth !== undefined ? props.someWidth : "initial"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${(props) => (props.flex ? "auto" : "475px")};
  min-height: ${(props) => (props.flex ? "auto" : "120px")};
  flex-direction: column;
  padding: 20px 20px;
  background-color: #fff;
  transition: all 200ms ease-out;
  box-shadow: ${(props) =>
    props.borderless ? "initial" : "2px 2px 10px 0px rgba(0,0,0,0.3)"};
  border-radius: ${(props) => (props.borderless ? "0px" : "40px")};
  overflow-y: auto;

  &:hover {
    transform: ${(props) => (props.fixed ? "initial" : "translateY(-6px)")};
    box-shadow: ${(props) =>
      props.borderless ? "initial" : "2px 2px 10px rgba(0,0,0,0.3)"};
  }

  @media (max-width: 1530px) {
    width: ${(props) =>
      props.borderless ? "100%" : props.large ? "95%" : "45%"};
  }
`;
