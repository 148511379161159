import { post, patch } from "./api.utils";

/**
 * Fonction permettant d'obtenir les profiles.
 * @param {object} params
 */
export const getNeeds = async (search = null) => {
  return await post("/needs/get-elastic-needs", { search });
};

export const changeNeedStatus = ({ initialStatus, accept }) => {
  let finalStatus = initialStatus;
	switch (initialStatus) {
		case "waiting":
			finalStatus = accept ? "interesting" : "refused";
			break;
		case "interesting":
			finalStatus = accept ? "won" : "refused";
			break;
		case "refused":
			finalStatus = "interesting";
			break;
		case "won":
			finalStatus = accept ? "" : "refused";
			break;
	}
  return finalStatus;
}


export const updateNeed = async ({ needHash, changes }) => {
  return await patch(`/needs/${needHash}/candidates`, { changes });
}

