import React, { useEffect } from "react";
import {
  Select,
  FormControl,
  InputLabel,
  Input,
  Checkbox,
  MenuItem,
  ListItemText,
  makeStyles,
  FormHelperText,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 500,
  },
}));

export default function MultiSelect({
  options,
  values,
  label,
  handleChange,
  error,
  errorText,
  required,
}) {
  const classes = useStyles();

  return (
    <CustomFormControl fullWidth variant="outlined" error={error}>
      <CustomInputLabel>
        {label}
        {required && <span className="MuiInputLabel-asterisk"> *</span>}
      </CustomInputLabel>
      <CustomSelect
        multiple
        value={values}
        onChange={handleChange}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          classes: { paper: classes.menuPaper },
        }}
        required={required}
        renderValue={(selected) => {
          const renderValue = selected.map((select) => {
            return select.label;
          });
          return renderValue.join(", ");
        }}
      >
        {options.map((option, index) => {
          return typeof option === "string" || option instanceof String ? (
            <MenuItem
              disabled
              key={index}
              value={option}
              styles={styles.menugroup}
            >
              <h3>{option}</h3>
            </MenuItem>
          ) : (
            <MenuItem key={index} value={option} styles={styles.menuitem}>
              <Checkbox
                checked={!!values.find((value) => value.label === option.label)}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        })}
        ))
      </CustomSelect>
      {error && <FormHelperText error>{errorText}</FormHelperText>}
    </CustomFormControl>
  );
}

const styles = {
  menugroup: { opacity: 1 },
  menuitem: { paddingLeft: 30 },
};

const CustomFormControl = withStyles({
  root: {
    width: "100%",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
})(FormControl);

const CustomInputLabel = withStyles({
  root: {
    backgroundColor: "white",
    padding: "0 7px",
    fontFamily: "Prompt Light",
    color: "#42387a",
    left: "13px",

    "& .MuiInputLabel-asterisk": {
      color: "#f2a0ad",
    },
  },
})(InputLabel);

const CustomSelect = withStyles({
  root: {
    backgroundColor: "white",
    padding: "18.5px 27px",
    border: "1px solid #ffc4ce",
    borderRadius: "28px",
    fontFamily: "Prompt Light",
    color: "#42387a",

    "&:focus": {
      borderRadius: "28px",
      backgroundColor: "white",
    },
  },

  icon: {
    color: "#42387a",
  },
})(Select);
