import React, { useState, useContext, useEffect } from 'react';
import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";

import { AuthContext } from "../../../../contexts/auth.context";

import UnderlineInput from "../../../../shared/components/inputs/big-underline-text-input.component";
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Select from "../../../../shared/components/inputs/select-input.component";
import MultiSelect from "../../../../shared/components/inputs/multi-select-input.component";
import RoutingButtons from "../shared/routing-buttons.component";
import { CustomTitle, CustomCheckbox } from "../shared/styled-components-library";

import { availabilityOptions, durationOptions } from "../../../../constants/select-options.constants";
import { SelectContainers, MultiSelectWrapper } from "../shared/styled-components-library";
import { fetchAllFieldsByCategories } from '../../../../shared/utils/fetch.utils'; 

export default function DurationForm({ jobTitle, setJobTitle, fields, fieldIds, setFieldIds, availability, setAvailability, duration, setDuration, isRenewable, setIsRenewable, handlePrevious, handleNext, isFreelance, isEditMode, openSignInModal, title, jobTitlePlaceholder, timePlaceholder, durationPlaceholder }) {
	const [auth] = useContext(AuthContext);
	const [showErrors, setShowErrors] = useState(false);

	const handleValidationAndNext = () => {
		if (auth.logged) {
			const hasErrors = !jobTitle.trim() || fieldIds.length === 0 || availability === "" || duration === "";
			if (hasErrors) {
				setShowErrors(true);
			} else {
				handleNext();
			}
		} else {
			openSignInModal();
		}
	}

	return (
		<>
			{
				!isEditMode ?
					<CustomTitle>{ title }</CustomTitle>
					: null
			}
			<UnderlineInput
                          placeholder={ `${ jobTitlePlaceholder } (exemple : Développeur fullstack) *` }
                          value={jobTitle}
                          onChangeHandler={e => setJobTitle(e.target.value)} error={showErrors && !jobTitle.trim()} errorText="Ce champ est obligatoire." />
			<SelectContainers>
				<MultiSelectWrapper>
					<MultiSelect label="Secteurs d'activité" options={fields} values={fieldIds} handleChange={(e) => setFieldIds(e.target.value)} error={showErrors && fieldIds.length === 0} errorText="Veuillez sélectionner au moins une option." required/>
				</MultiSelectWrapper>
				<Select options={availabilityOptions} value={availability} onChangeHandler={(e) => setAvailability(e.target.value)} label={timePlaceholder} error={showErrors && !availability} errorText="Veuillez sélectionner une option." />
				<Select
					options={durationOptions}
					value={duration}
					onChangeHandler={(e) => {
						setDuration(e.target.value);
					}}
					label={durationPlaceholder}
					error={showErrors && !duration}
					errorText="Veuillez sélectionner une option."
				/>
			</SelectContainers>
			<RenewableWrapper deactivated={duration === 1}>
				<CustomFormControlLabel
					label="La période est renouvelable"
					labelPlacement="end"
					control={<CustomCheckbox
						checked={isRenewable}
						onChange={e => setIsRenewable(e.target.checked)}
						icon={<CircleUnchecked />}
						checkedIcon={<RadioButtonCheckedIcon />}
					/>}
				/>
			</RenewableWrapper>
			{
				!isEditMode ?
					<RoutingButtons handlePrevious={handlePrevious} handleNext={handleValidationAndNext} />
					: null
			}
		</>
	);
}

const RenewableWrapper = styled.div`
	pointer-events: ${props => props.deactivated ? "none" : "auto"};
	opacity: ${props => props.deactivated ? 0.5 : 1};
	user-select: ${props => props.deactivated ? "none" : "auto"};
`;

const CustomFormControlLabel = styled(FormControlLabel)`
	&& {
		.MuiFormControlLabel-label {
			font-family: "Prompt";
		}
	}
`;