import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import Button from "../../../../shared/components/buttons/button.component";

export default ({ loading, isLastStep, disableNext, disablePrevious, handlePrevious, handleNext, handleSubmit, nextStepButtonLabel = null }) => (
	<BackAndNextButtonsContainer>
		{
			loading ? <CircularProgress /> : <>
				<Button onClick={handlePrevious} outlineButton secondary disabled={disablePrevious}>Précédent</Button>
				{
					!isLastStep ? (
						<Button onClick={handleNext} disabled={disableNext} secondary gradient>
							{nextStepButtonLabel || "Valider"}
						</Button>
					) : (
							<Button onClick={handleSubmit} disabled={disableNext} secondary gradient>
								Terminer
					</Button>
						)
				}
			</>
		}
	</BackAndNextButtonsContainer>
);

const BackAndNextButtonsContainer = styled.div`
	margin: 50px 0px;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	& > * {
		margin-left: 20px;
	}
`;
