import React, { useState } from "react";
import styled from "styled-components";
import { Alert } from "@material-ui/lab";

import Stepper from "../../../shared/components/stepper.component";
import PasswordInput from "../../../shared/components/inputs/password-input.component";
import RoutingButtons from "./routing-buttons.component";

import { isValid } from "../../../shared/utils/input-validation.utils";
import Select from "../../../shared/components/inputs/select-input.component";
import { acquisitionOptions } from "../../../constants/select-options.constants";

const PasswordForm = ({
  stepperLabels,
  activeStep,
  completedSteps,
  userPassword,
  userAcquisition,
  setUserPassword,
  userConfirmPassword,
  setUserConfirmPassword,
  setUserAcquisition,
  userCguAccepted,
  handlePrevious,
  handleStep,
  handleSubmit,
  submittingForm,
  formSubmittedError,
}) => {
  const [showErrors, setShowErrors] = useState(false);

  const handleValidationAndSubmit = () => {
    const hasErrors =
      !userCguAccepted ||
      !userPassword.trim() ||
      !userConfirmPassword.trim() ||
      !isValid("password", userPassword.trim()) ||
      userPassword.trim() !== userConfirmPassword.trim() ||
      submittingForm;
    if (hasErrors) {
      setShowErrors(true);
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <Stepper
        labels={stepperLabels}
        activeStep={activeStep}
        handleStep={handleStep}
        completedSteps={completedSteps}
        style={{ marginLeft: "-10%" }}
      />
      <PasswordFormContainer>
        <PasswordInput
          showPasswordRules
          showPasswordStrength
          value={userPassword}
          onChangeHandler={(e) => setUserPassword(e.target.value)}
          label="Mot de passe"
          required
          error={showErrors && !isValid("password", userPassword)}
          errorText="Veuillez entrer un mot de passe valide."
        />
        <PasswordInput
          value={userConfirmPassword}
          onChangeHandler={(e) => setUserConfirmPassword(e.target.value)}
          label="Confirmer le mot de passe"
          required
          error={
            showErrors &&
            !!userPassword &&
            !!userConfirmPassword &&
            userPassword !== userConfirmPassword
          }
          errorText="Les 2 mots de passe ne correspondent pas."
        />

        <Select
          options={acquisitionOptions}
          label="Comment j'ai découvert 1terco ?"
          value={userAcquisition}
          onChangeHandler={(e) => setUserAcquisition(e.target.value)}
          error={false}
          errorText="Veuillez sélectionner une option."
        />
        <RoutingButtons
          isLastStep={true}
          loading={submittingForm}
          disablePrevious={submittingForm}
          handlePrevious={handlePrevious}
          handleSubmit={handleValidationAndSubmit}
        />
        {formSubmittedError ? (
          <Alert severity="error">
            Cette addresse mail est déjà liée à un autre compte 1terco.
          </Alert>
        ) : null}
      </PasswordFormContainer>
    </>
  );
};

export default PasswordForm;

const PasswordFormContainer = styled.div`
  @media screen and (min-width: 520px) {
    width: 80%;
  }
`;
