import React from 'react';
import styled from "styled-components";

export default function YesNoButtons({ style, isChoicePositive, setChoice, disabled }) {
	return (
		<ButtonsContainer style={style}>
			<CustomButton active={isChoicePositive} onClick={() => {
				setChoice(true);
			}}>Oui</CustomButton>
			<CustomButton active={!isChoicePositive} onClick={() => {
				setChoice(false);
			}}>Non</CustomButton>
		</ButtonsContainer>
	);
}

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > * {
		margin: 4px 0px;
	}
`;

export const CustomButton = styled.button`
	cursor: pointer;
	font-size: 16px;
	font-family: "Prompt";
        color: ${props => props.disabled ? '#CCC' : '#241f47'};
        border: 1px solid ${props => props.disabled ? '#CCC' : '#F2B6BC'};
	border-radius: 20px;
	padding: 5px 15px;
	background-color: ${props => props.active ? "#F2B6BC" : "transparent"};
	transition: all 0.25s ease;

	&:hover {
		background-color: "#F2B6BC";
	}

	&:focus {
		outline: none;
	}
`;