import React, { useEffect } from "react";
import styled from "styled-components";
// import { Select, MenuItem } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { withStyles } from "@material-ui/core";

export default function PaginationComponent({
	items,
	label,
	page,
	pagesCount,
	rowPerPage,
	handlePageChange,
	handleRowPerPageChange
}) {

	return (
		<PaginationWrapper>
      { handleRowPerPageChange && <div className="changeRowPerPage">
				<span>Afficher :</span>
				<select onChange={handleRowPerPageChange} value={rowPerPage}>
					{items.map(item => (
						<option key={item} value={item}>
							{item}
						</option>
					))}
				</select>
				<span>{label || "lignes"}</span>
			</div> }
			{page && (
				<Pagination
					count={pagesCount}
					page={page}
					onChange={handlePageChange}
					renderItem={item => item.type === 'page' ? <CustomPageItem {...item} /> : <CustomArrowItem {...item} />}
					//defaultPage={page}
				/>
			)}
		</PaginationWrapper>
	);
}

const PaginationWrapper = styled.div`
	width: 100%;
	margin: 20px 0;
	display: flex;
	justify-content: flex-end;
	font-family: "Prompt";
	color: #241f47;

	& > * {
		margin-right: 10px;
	}

	& select {
		outline: none;
		cursor: pointer;
		border: 1px solid #f29daa;
		color: #f29daa;
		padding-right: 8px;
		border-radius: 8px;
		appearance: none;
		background-image: url("data:image/svg+xml;utf8,<svg fill='pink' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
		background-repeat: no-repeat;
		background-position-x: 100%;
		background-position-y: 2px;
	}

	& select option {
		color: black;
	}

	& > .changeRowPerPage {
		display: flex;
		align-items: center;
		font-size: 16px;

    & > span {
      margin: 0 5px;
    }
    
    & > select {
      padding-left: 6px;
    }
	}
`;

const CustomArrowItem = withStyles({
	root: {
		color: `#f29daa`,
	},

	disabled: {
		color: `black`,
	}
})(PaginationItem);

const CustomPageItem = withStyles({
	root: {
		backgroundImage: `linear-gradient(to right, #f9cdd5 0%, #f29daa 100%)`,
		color: `#241f47`,
		fontFamily: `Prompt`,
		height: `22px`,
		minWidth: `22px`,
	},
	selected: {
		backgroundImage: `linear-gradient(to right, #42387A 0%, #241f47 100%)`,
		color: 'white'
	}
})(PaginationItem);
