import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";

import {
	FormContainer,
	SmallTitle,
	CustomCheckbox
} from "./shared/styled-components-library";
import UnderlineInput from "../../../shared/components/inputs/big-underline-text-input.component";
import YesNoButtons from "./shared/yes-no-buttons.component";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RoutingButtons from "./shared/routing-buttons.component";

export default function PricesForm(props) {
	const { handleNext, setCompletedSteps, activeStep } = props;
	const [formData, setInterco] = useContext(props.context);
	const [preEmploymentOpen, setPreEmploymentOpen] = useState(false);
	const [hasRecommendation, setHasRecommendation] = useState(false);
	const [price, setPrice] = useState("");
	const [priceHidden, setPriceHidden] = useState(false);
	const [showPriceError, setShowPriceError] = useState(false);

	useEffect(() => {
		loadFormData();
	}, []); // eslint-disable-line

	const loadFormData = () => {
		if (formData && formData.pricesForm) {
			setPreEmploymentOpen(formData.pricesForm.preEmploymentOpen);
			setHasRecommendation(formData.pricesForm.hasRecommendation);
			setPrice(formData.pricesForm.price);
			setPriceHidden(formData.pricesForm.priceHidden);
		}
	};

	const handleValidationAndNext = () => {
		const formattedPrice = price
			.toString()
			.replace(" ", "")
			.replace("€", "")
			.replace(",", ".")
			.trim();
		const priceError = isNaN(formattedPrice) || price === "";
		if (priceError && !priceHidden) {
			setShowPriceError(true);
		} else {
			setCompletedSteps(completedSteps => ({
				...completedSteps,
				[activeStep]: true
			}));
			saveFormData();
			handleNext();
		}
	};

	const saveFormData = () => {
		setInterco(formData => ({
			...formData,
			pricesForm: {
				preEmploymentOpen,
				hasRecommendation,
				price: price
					.toString()
					.replace(" ", "")
					.replace("€", "")
					.replace(",", ".")
					.trim(),
				priceHidden
			}
		}));
	};

	const stateProps = {
		preEmploymentOpen,
		setPreEmploymentOpen,
		hasRecommendation,
		setHasRecommendation,
		price,
		setPrice,
		priceHidden,
		setPriceHidden
	};

	return (
		<PricesFormLayout
			{...props}
			{...stateProps}
			showPriceError={showPriceError}
			handleValidationAndNext={handleValidationAndNext}
		/>
	);
}

/* Le layout est utile pour la création d'un profil MAIS AUSSI pour l'édition d'un profil dans l'espace personnel */
export const PricesFormLayout = props => {
	const {
		showPriceError,
		preEmploymentOpen,
		setPreEmploymentOpen,
		hasRecommendation,
		setHasRecommendation,
		price,
		setPrice,
		priceHidden,
		setPriceHidden,
		handlePrevious,
		handleValidationAndNext,
		isFreelance,
		isEditMode
	} = props;

	return (
		<FormContainer width={isEditMode ? "95%" : "50%"}>
			{!isFreelance ? (
				<>
					<SmallTitle>Êtes-vous ouvert à la pré-embauche ?</SmallTitle>
					<YesNoButtons
						style={{ margin: "30px 0px" }}
						isChoicePositive={preEmploymentOpen}
						setChoice={setPreEmploymentOpen}
					/>
				</>
			) : null}
			<SmallTitle>
				{isFreelance ? "Avez-vous" : "A-t-il"} des lettres de recommandations ?
			</SmallTitle>
			<YesNoButtons
				style={{ margin: "30px 0px" }}
				isChoicePositive={hasRecommendation}
				setChoice={setHasRecommendation}
			/>
			<SmallTitle>Taux journalier moyen</SmallTitle>
			<PriceContainer>
				<UnderlineInput
					deactivate={priceHidden}
					placeholder="Préciser le TJM (€)"
					value={price}
					onChangeHandler={e => {
						let newPrice = e.target.value;
						if (!isNaN(newPrice)) {
							setPrice(Math.max(parseFloat(newPrice), 0));
						}
					}}
					error={showPriceError}
					errorText="Veuillez indiquer un TJM, dans un format valide."
					type="number"
					step="1"
					min="1"
				/>
				<CustomFormControlLabel
					style={{ marginTop: "30px" }}
					label="Ne pas afficher"
					labelPlacement="end"
					control={
						<CustomCheckbox
							checked={priceHidden}
							onChange={e => {
                                                          setPrice("")
                                                          setPriceHidden(e.target.checked)
                                                        }}
							icon={<CircleUnchecked />}
							checkedIcon={<RadioButtonCheckedIcon />}
						/>
					}
				/>
			</PriceContainer>
			{!isEditMode ? (
				<RoutingButtons
					handleNext={handleValidationAndNext}
					handlePrevious={handlePrevious}
				/>
			) : null}
		</FormContainer>
	);
};

const PriceContainer = styled.div`
	width: 60%;
`;

const CustomFormControlLabel = styled(FormControlLabel)`
	& .MuiFormControlLabel-label {
		font-family: "Prompt Light";
	}
`;