import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import moment from "moment";
import { Alert } from "@material-ui/lab";

import ModalTemplate from "../../shared/components/modal-template.component";
import { post, deleteReq } from "../../shared/utils/api.utils";
import Button from "../../shared/components/buttons/button.component";

export default function DeleteProfileModal(props) {
	const { searches, user, setAlerts, ...modalProps } = props;
	const [submit, setSubmit] = useState({ show: false, type: null, message: null });

	useEffect(() => {
		if (modalProps.showModal) {
			setSubmit({ show: false, type: null, message: null });
		}
	}, [modalProps.showModal]);


	const deleteSearch = () => {
		if (submit.type !== "success") {
			if (searches.length === 1) {
				const userSearchId = searches[0].userSearchId;
				deleteReq(`/users/${user.userId}/search/${userSearchId}`).then(response => {
					if (response && response.success) {
						setAlerts(alerts => alerts.filter(_alert => _alert.userSearchId !== userSearchId));
						setSubmit({ show: true, type: "success", message: "Votre recherche a bien été supprimée." });
					} else {
						setSubmit({ show: true, type: "error", message: "Une erreur s'est produite lors de la suppression de votre recherche, veuillez réessayer ultérieurement." });
					}
				});
			} else if (searches.length > 1) {
				post(`/users/${user.userId}/search/delete-requests`, {
					searchIds: searches.map(search => search.userSearchId)
				}).then(response => {
					if (response && response.success) {
						setAlerts(alerts => alerts.filter(_alert => !searches.some(search => search.userSearchId === _alert.userSearchId)));
						setSubmit({ show: true, type: "success", message: "Vos recherches ont bien été supprimées." });
					} else {
						setSubmit({ show: true, type: "error", message: "Une erreur s'est produite lors de la suppression de votre recherche, veuillez réessayer ultérieurement." });
					}
				});
			}
		}
	}
	const search = searches && searches[0];
	return (
		<ModalTemplate {...modalProps}>
			<MainWrapper>
				<CustomTitle>
					{searches.length === 1 ? "SUPPRESSION D'UNE RECHERCHE" : "SUPPRESSION DE PLUSIEURS RECHERCHES"}
				</CustomTitle>
				{
					searches && searches.length &&
					<>
						{
							searches.length === 1 && <InfoWrapper>
								<div><b>Titre de la recherche</b> : {search.title}</div>
								<div><b>Créée le</b> : {moment(search.createdAt).format("DD.MM.YYYY")}</div>
							</InfoWrapper>
						}
						<Alert severity="warning">En confirmant cette action, la suppression des données sera permanente : la récupération des données sera donc impossible.</Alert>
						<Button onClick={deleteSearch} style={{ width: "50%", margin: "0 auto" }}>Confirmer</Button>
						{
							submit.show && <AlertWrapper>
								<Alert severity={submit.type}>{submit.message}</Alert>
							</AlertWrapper>
						}
					</>
				}
			</MainWrapper>
		</ModalTemplate>
	);
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	& > * {
		margin-bottom: 30px;
	}
`;

const CustomTitle = styled.h2`
	color: #241f47;
	font-family: "Prompt Semi Bold";
	font-size: 30px;
	text-align: center;
`;

const InfoWrapper = styled.div`
	color: #241f47;
	font-family: "Prompt";
	font-size: 16px;
	margin-bottom: 20px;
`;

const AlertWrapper = styled.div`
	margin-top: 10px;
`;