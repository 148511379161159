import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Alert } from "@material-ui/lab";

import { MediumTitle } from "../../shared/utils/styled-components-library.utils";
import Button from "../../shared/components/buttons/button.component";
import TextInput from "../../shared/components/inputs/text-input.component";
import { post } from "../../shared/utils/api.utils";

Modal.setAppElement("#root");

export default function ContactItemOwnerModal({
  closeModal,
  showModal,
  item,
  nameSpace,
}) {
  const [content, setContent] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    type: null,
    message: null,
  });
  const [showErrors, setShowErrors] = useState(false);

  const sendMessage = () => {
    if (content.trim().length > 2048) {
      setAlert({
        show: true,
        type: "error",
        message: "Votre message est trop long.",
      });
      return;
    }
    if (content.trim().length === 0) {
      setAlert({
        show: true,
        type: "error",
        message: "Votre message est vide.",
      });
      setShowErrors(true);
      return;
    }
    setAlert({ show: false, type: null, message: null });
    setShowErrors(false);
    post("/misc/contact-item-owner", {
      itemId: item[`${nameSpace === "needs" ? "need" : "profile"}Id`],
      content,
      nameSpace,
    }).then((response) => {
      if (response && response.success) {
        setAlert({
          show: true,
          type: "success",
          message: "Votre message a bien été envoyé !",
        });
        setContent("");
        setShowErrors(false);
      } else {
        setAlert({
          show: true,
          type: "error",
          message:
            "Une erreur s'est produite lors de l'envoi du message, veuillez réessayer ultérieurement.",
        });
      }
    });
  };

  const whomToContact =
    nameSpace === "needs"
      ? "demandeur"
      : item.user.profile === "freelance"
      ? "freelance"
      : "chargé d'affaire";
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      style={modalCustomStyles}
    >
      <ModalBody>
        <CloseButtonContainer>
          <CloseButton onClick={closeModal}>&#10006;</CloseButton>
        </CloseButtonContainer>
        <MediumTitle>Contacter le {whomToContact}</MediumTitle>
        <TextInput
          value={content}
          multiline
          rows={13}
          onChangeHandler={(e) => setContent(e.target.value)}
          label="Contenu du message"
          error={showErrors}
          errorText="Ce champ est obligatoire."
        />
        <Button onClick={sendMessage}>Contacter</Button>
        {alert.show && (
          <AlertContainer>
            <Alert severity={alert.type}>{alert.message}</Alert>
          </AlertContainer>
        )}
      </ModalBody>
    </Modal>
  );
}

const modalCustomStyles = {
  content: {
    borderRadius: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    overflow: "hidden",
    zIndex: 101,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 100,
  },
};

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 650px;
  height: 500px;
  margin: 20px 40px;

  overflow-y: auto;
`;

const CloseButtonContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`;

const CloseButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #f0ebe5;
  line-height: 48px;
  text-align: center;
  user-select: none;
  cursor: pointer;
`;

const AlertContainer = styled.div`
  margin-top: 20px;
`;
