const config = {
  stripePublishableKey:
    "pk_test_51NfIXUEbnMiVam8pkp8wdUcj0B60pTSyLhAFhr4afemEj38KW2muk5jQ4okwEKyNRTIcYjCfPyqhiCLtGKDNTfEB00wNGcnv2h",
  websiteUrl:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000"
      : "https://1terco.com",
  apiUrl:
    process.env.NODE_ENV === "development"
      ? "http://localhost:60123/api"
      : "https://api.1terco.com/api",
  googleMapsApiKey: "AIzaSyBAf084iVX0_zKYLm9uLk-Z1dFUguyE-JE",
  elasticSearchUrl:
    "https://search-interco-elasticsearch-3sqos77nvg25sbasqg67ulx44i.eu-west-3.es.amazonaws.com",
};

export default config;
