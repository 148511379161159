import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Chip, withStyles } from "@material-ui/core";

/**
 * @param {string[]} chips
 * @param {Function} deleteChipHandler
 */
export default function ChipContainer({ chips, deleteChipHandler }) {
	const [isStringArray, setIsStringArray] = useState(false);
	useEffect(() => {
		if (!!chips.length && typeof chips[0] === "string") {
			setIsStringArray(true);
		} else {
			setIsStringArray(false);
		}
	}, [chips]);

	return (
		<ChipsContainer>
			{
				chips.map(chip => (
					<StyledChip
						key={isStringArray ? chip : chip.type + chip.value}
						label={isStringArray ? chip : chip.label}
						color="primary"
						onDelete={() => deleteChipHandler(chip)}
					/>
				))
			}
		</ChipsContainer>
	);
}

const ChipsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	& > * {
		margin-right: 10px;
		margin-bottom: 4px;
	}
`;

export const StyledChip = withStyles({
	root: {
		backgroundColor: "#241f47",
		color: "#FFF"
	},
	icon: {
		color: "#FFF"
	}
})(Chip);

// const DeleteIcon = styled.span`

// `;