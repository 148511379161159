import { alertOptions } from "../../../constants/select-options.constants";
import Select from "../../../shared/components/inputs/select-input.component";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { AbstractChoice } from "../../../shared/components/layout/abstract-choice.component"; 
import {
  BigTitle,
} from "../../../shared/utils/styled-components-library.utils";
import ItemAlertComponent from "./search-alerts/item-alert.component";

const dropdownMap = new Map();
dropdownMap.set("profiles", {
  attachments: {
    nameSpace: 'profiles'
  },
  component() {
    return ItemAlertComponent;
  },
  validationFunction({ user }) {
    if (user.profile === "freelance")
      return false;
    return true;
  },
  key: 'profiles'
});
dropdownMap.set("needs", {
  attachments: {
    nameSpace: 'needs'
  },
  component() {
    return ItemAlertComponent;
  },
  validationFunction({ user }) {
    if (user.profile === "end-client")
      return false;
    return true;
  },
  key: 'needs'
});

export const abstractChoice = new AbstractChoice({ tabsMap: dropdownMap });

export default function UserSearchAndAlerts({
  user,
  loadedFields,
  loadedWorkLocations,
}) {
  const [, updateState] = useState();
  const [searchNameSpace, setSearchNameSpace] = useState('profiles');
  const forceUpdate = useCallback(() => updateState({}), []);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    abstractChoice.setOnTabChange(({ newAttachments }) => {
      if (newAttachments?.nameSpace) {
        setSearchNameSpace(newAttachments.nameSpace);
      }
    });
  }, []);

  useEffect(() => {
    if (!user) return;
    abstractChoice.findFirstValidTab({ user })
    forceUpdate();
  }, [user]);

  useEffect(() => {
    if (!user) return;
    const choices = abstractChoice.getAllTabKeys({ user });
    setShowDropdown(choices.length > 1);
  }, [user]);

  return (
    <MainWrapper>
      <BigTitle>MES RECHERCHES ET ALERTES</BigTitle>
      <BodyWrapper>
        { showDropdown === true &&
          <div style={{ marginBottom: "5%" }}>
            <Select
              options={alertOptions}
              value={abstractChoice.currentTab ?? ""}
              onChangeHandler={(e) => {
                abstractChoice.changeTab({ newTab: e.target.value })
                forceUpdate();
              }}
              errorText="Veuillez sélectionner une option."
            />
          </div>
        }
      {
        abstractChoice.currentComponent({
          conditions: { user },
          props: {
            user,
            loadedFields,
            loadedWorkLocations,
            setSearchNameSpace,
            searchNameSpace,
          }
        })
      }
      </BodyWrapper>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyWrapper = styled.div`
  background-color: white;
  border-radius: 70px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
  padding: 1em 4em 4em;
`;

