import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from "styled-components";
import { Alert } from "@material-ui/lab";

import { MediumTitle } from "../../shared/utils/styled-components-library.utils";
import Button from "../../shared/components/buttons/button.component";
import TextInput from "../../shared/components/inputs/text-input.component";
import { post } from "../../shared/utils/api.utils";
import { isValid } from "../../shared/utils/input-validation.utils";

Modal.setAppElement("#root");

export default function ShareProfileModal({ closeModal, showModal, profile, nameSpace }) {
	const [mail, setMail] = useState("");
	const [alert, setAlert] = useState({ show: false, type: null, message: null });
	const [hasSharedProfile, setHasSharedProfile] = useState(false);
	const [showErrors, setShowErrors] = useState(false);

	const sendMail = () => {
		setAlert({ show: false, type: null, message: null });
		if (isValid("email", mail) && !!profile && !hasSharedProfile) {
			setShowErrors(false);
			post("/misc/share-item", { sendTo: mail, itemHash: profile.hash, nameSpace }).then(response => {
				if (response && response.success) {
					setHasSharedProfile(true);
					setAlert({ show: true, type: "success", message: `Le ${nameSpace === 'profiles' ? 'profil' : 'besoin'} a bien été partagé !` });
				} else {
					setAlert({ show: true, type: "error", message: `Une erreur s'est produite lors du partage du ${nameSpace === 'profiles' ? 'profil' : 'besoin'}, veuillez réessayer ultérieurement.` });
				}
			});
		} else {
			setShowErrors(true);
		}
	}

	return (
		<Modal
			isOpen={showModal}
			onRequestClose={closeModal}
			style={modalCustomStyles}
		>
			<ModalBody>
				<CloseButtonContainer>
					<CloseButton onClick={closeModal}>&#10006;</CloseButton>
				</CloseButtonContainer>
				<MediumTitle>Partager le {nameSpace === 'profiles' ? 'profil' : 'besoin'}</MediumTitle>
				<TextInput
					value={mail}
					onChangeHandler={(e) => setMail(e.target.value)}
					label="L'adresse e-mail du destinataire"
					error={showErrors && !isValid("email", mail)}
					errorText="L'adresse e-mail entrée n'est pas valide."
				/>
				<Button onClick={sendMail}>Partager</Button>
				{
					alert.show && <AlertContainer>
						<Alert severity={alert.type}>{alert.message}</Alert>
					</AlertContainer>
				}
			</ModalBody>
		</Modal>
	);
}

const modalCustomStyles = {
	content: {
		borderRadius: 0,
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: "0px",
		overflow: "hidden",
		zIndex: 101
	},
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		zIndex: 100
	}
};

const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 650px;
	height: 320px;
	margin: 20px 40px;
	
	overflow-y: auto;
`;

const CloseButtonContainer = styled.div`
	width: 100%;
	justify-content: flex-end;
	display: flex;
`;

const CloseButton = styled.div`
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 1px solid #F0EBE5;
	line-height: 48px;
  text-align: center;
	user-select: none;
	cursor: pointer;
`;

const AlertContainer = styled.div`
	margin-top: 20px;
`;