import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { CSSTransition } from "react-transition-group";

import { AuthContext } from "../../contexts/auth.context";
import { BigTitle } from "../../shared/utils/styled-components-library.utils";
import RoundedButton from "../../shared/components/buttons/rounded-button.component";
import Image from "../../shared/components/images/image.component";
import VideoPlayer from "../../shared/components/video-player.component";

import "./home-page.css";

const intercoPresentationVideoUrl =
  "https://1terco.com/assets/videos/15_12_20_INTERCO_M05_V05.mp4";

const responsiveMaxWidth = "800px";

export default function Home({ openSignInModal }) {
  const history = useHistory();
  const [auth] = useContext(AuthContext);

  const [transition, setTransition] = useState({
    one: false,
    two: false,
    three: false,
  });
  const [isVisible, setIsVisible] = useState({
    one: false,
    two: false,
    three: false,
  });
  const inView1 = useInView({ threshold: 0.2 });
  const inView2 = useInView({ threshold: 0.2 });
  const inView3 = useInView({ threshold: 0.2 });

  const clickHandler = () => {
    if (auth && auth.logged) {
      history.push("/creer-fiche-profil");
    } else {
      openSignInModal();
    }
  };

  useEffect(() => {
    if (inView1.inView) {
      setIsVisible({ ...isVisible, one: true });
      setTransition({ ...transition, one: true });
    }
    if (inView2.inView) {
      setIsVisible({ ...isVisible, two: true });
      setTransition({ ...transition, two: true });
    }
    if (inView3.inView) {
      setIsVisible({ ...isVisible, three: true });
      setTransition({ ...transition, three: true });
    }
  }, [inView1.inView, inView2.inView, inView3.inView, isVisible, transition]);

  return (
    <PageWrapper>
      <MainSection>
        <GorillaImageContainer>
          <Image width="80%" src="/assets/images/gorille-1.png" alt="" />
        </GorillaImageContainer>
        <BigTitle>
          Rejoignez la tribu où projets et talents se rencontrent
        </BigTitle>
        <ButtonsContainer
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {!auth.logged || auth.profile === "company" ? (
            <RoundedButton
              width="416px"
              onClick={() => clickHandler("/creer-interco")}
            >
              VOUS AVEZ UN INTERCO
            </RoundedButton>
          ) : null}
          {!auth.logged ||
          auth.profile === "end-client" ||
          auth.profile === "company" ? (
            <RoundedButton
              width="416px"
              onClick={() => clickHandler("creer-besoin")}
            >
              VOUS AVEZ UN BESOIN
            </RoundedButton>
          ) : null}
          {!auth.logged ||
          auth.profile === "end-client" ||
          auth.profile === "company" ? (
            <RoundedButton
              width="416px"
              onClick={() => clickHandler("bourse-interco")}
            >
              VOUS CHERCHEZ UN INTERCO
            </RoundedButton>
          ) : null}
          {!auth.logged || auth.profile === "freelance" ? (
            <RoundedButton
              width="416px"
              onClick={() => clickHandler("/creer-fiche-profil")}
            >
              FREELANCE, PROPOSEZ VOTRE PROFIL
            </RoundedButton>
          ) : null}
        </ButtonsContainer>
      </MainSection>
      <DiscoverInterco>
        <DiscoverIntercoTitle>
          Au fait, c’est quoi 1terco ?
        </DiscoverIntercoTitle>
        <SectionDescription
          style={{
            maxWidth: "800px",
            color: "white",
            margin: "0 10px",
            fontWeight: "bold",
          }}
        >
          Dans la jungle du conseil, 1terco est une plateforme innovante qui
          simplifie la mise en relation entre clients finaux, ESN, et
          freelances. Grâce à notre solution, trouvez rapidement les talents
          dont vous avez besoin, gérez vos projets en toute transparence et
          digitalisez vos processus de recrutement. Avec 1terco décuplez vos
          opportunités et donnez un coup de boost à votre activité.
        </SectionDescription>
      </DiscoverInterco>
      <SectionsContainer>
        <Section ref={inView1.ref}>
          <CSSTransition
            in={transition.one}
            timeout={600}
            classNames="slideinright"
          >
            <SectionImageContainer className="section-image willSlideInRight">
              <Image src="/assets/images/left-arm.png" width="90%" />
            </SectionImageContainer>
          </CSSTransition>
          <CSSTransition
            in={transition.one}
            timeout={600}
            classNames="slideinleft"
          >
            <SectionText className="section-text willSlideInLeft">
              <SectionTitle>ESN / SSII</SectionTitle>
              <SectionDescription>
                1terco est la plateforme qui vous permettra de décupler vos
                opportunités de business en accédant à un vivier de besoins de
                clients finaux et de confrères. La plateforme vous facilite la
                gestion de vos intercontrats grâce à la possibilité de diffuser
                leurs profils à tous les membres de la tribu. Trouvez également
                la bonne ressource en sollicitant le vivier de freelances, ainsi
                que les intercontrats de vos confrères.
              </SectionDescription>
            </SectionText>
          </CSSTransition>
        </Section>
        <Section ref={inView2.ref}>
          <CSSTransition
            in={transition.two}
            timeout={600}
            classNames="slideinright"
          >
            <SectionText className="section-text willSlideInRight">
              <SectionTitle>Client final et acheteur</SectionTitle>
              <SectionDescription>
                1terco vous permet de trouver les meilleurs prestataires pour
                vous accompagner sur vos projets. La plateforme vous permet en
                quelques clics de diffuser vos besoins à notre réseau d’ESN et
                freelances ou exclusivement à votre panel d’ESN. Grâce à son
                ergonomie intuitive, simplifiez vos échanges avec vos
                partenaires, sélectionnez rapidement et efficacement les
                meilleurs experts pour vos projets.
              </SectionDescription>
            </SectionText>
          </CSSTransition>
          <CSSTransition
            in={transition.two}
            timeout={600}
            classNames="slideinleft"
          >
            <SectionImageContainer className="willSlideInLeft">
              <Image src="/assets/images/gorille-6.png" width="90%" />
            </SectionImageContainer>
          </CSSTransition>
        </Section>
        <Section
          ref={inView3.ref}
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
          }}
        >
          <CSSTransition
            in={transition.three}
            timeout={600}
            classNames="slideinright"
          >
            <SectionImageContainer className="willSlideInRight">
              <Image src="/assets/images/gorille-7.png" width="90%" />
            </SectionImageContainer>
          </CSSTransition>
          <CSSTransition
            in={transition.three}
            timeout={600}
            classNames="slideinleft"
          >
            <SectionText className="section-text willSlideInLeft">
              <SectionTitle>
                Freelance, vous cherchez une nouvelle mission ?
              </SectionTitle>
              <SectionSubtitle>Proposez votre profil</SectionSubtitle>
              <SectionDescription>
                Freelance, vous venez de finir votre mission et vous cherchez
                actuellement une nouvelle mission? Venez proposer votre profil
                aux sociétés de conseil!
              </SectionDescription>
            </SectionText>
          </CSSTransition>
        </Section>
      </SectionsContainer>
      <DiscoverInterco style={{ marginBottom: "80px" }}>
        <DiscoverIntercoTitle>
          Cherchez ou proposez un interco
        </DiscoverIntercoTitle>
        <DiscoverIntercoVideoContainer>
          <VideoPlayer
            videoUrl={intercoPresentationVideoUrl}
            thumbnailUrl="/assets/images/thumbnail.png"
          />
        </DiscoverIntercoVideoContainer>
      </DiscoverInterco>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  max-width: 100%;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
  position: relative;

  & > * {
    margin: 1% 0px;
  }
`;

const GorillaImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    width: 50%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 20px;
  & > * {
    margin: 0px 9px;
  }

  @media (max-width: 1300px) {
    flex-direction: column;

    & > * {
      margin: 10px 0px;
    }
  }
`;

const DiscoverInterco = styled.div`
  position: relative;
  background-image: url("/assets/images/background_gorilla.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 180px 0 40px 0;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    padding: 100px 0 20px 0;
  }
`;

const DiscoverIntercoTitle = styled.h2`
  color: #fff;
  font-size: 40px;
  font-family: "Prompt Black";
  letter-spacing: 1px;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    margin: 0 10px;
    font-size: 30px;
  }
`;

const DiscoverIntercoVideoContainer = styled.div`
  margin: 32px;
  width: 80%;
  height: 750px;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    margin: 10px 5px;
    height: 320px;
  }
`;

const SectionsContainer = styled.div`
  margin-top: calc(5em + 2vw);
  margin-bottom: 3em;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    padding: 0 10%;
  }

  @media screen and (min-width: ${responsiveMaxWidth}) {
    & .section-text:nth-child(even) {
      padding-right: 10%;
    }

    & .section-text:nth-child(odd) {
      padding-left: 10%;
    }
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionImageContainer = styled.div`
  width: 300vw;
  min-width: 350px;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    display: none;
  }
`;

const SectionText = styled.div`
  text-align: left;
  padding-bottom: 5em;
  margin: 100px 100px 100px 0px;
  width: 250vw;
`;

const SectionTitle = styled.h3`
  font-family: "Prompt Black";
  color: #241f47;
  font-size: 46px;
  text-transform: uppercase;
  line-height: 55px;
  margin-bottom: 0;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    font-size: 28px;
    line-height: 32px;
  }
`;

const SectionSubtitle = styled.h5`
  font-family: "Prompt Extra Bold";
  color: #f29daa;
  font-size: 32px;
  text-transform: uppercase;
  line-height: 36px;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    font-size: 22px;
  }
`;

const SectionDescription = styled.div`
  font-family: "Prompt Light";
  color: #42387a;
  font-size: 18px;
  margin-top: 15px;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    font-size: 16px;
  }
`;
