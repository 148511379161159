import React, { useState, createContext } from 'react';

const AuthContext = createContext();

const AuthProvider = (props) => {
	const [authState, setAuthState] = useState({
		logged: false,
		type: null,
		profile: null,
		userId: null,
    subscriptionPaid: false
	});
	return (
		<AuthContext.Provider value={[authState, setAuthState]}>
			{props.children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };