import React, { useState, useMemo } from 'react';
import styled from "styled-components";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

import ModalTemplate from "../../shared/components/modal-template.component";
import { deleteReq } from "../../shared/utils/api.utils";
import Button from "../../shared/components/buttons/button.component";

export default function DeleteItemModal(props) {
	const { nameSpace, profile, user, setUser, setShouldRefreshUser, ...modalProps } = props;
	const [deletingItem, setDeletingItem] = useState(false);
	const [alert, setAlert] = useState({ show: false, type: null, message: null });
	const deleteItem = () => {
		if (!deletingItem) {
			setDeletingItem(true);
			setAlert({ show: false, type: null, message: null });
			const id = nameSpace === 'needs' ? profile.needId : profile.profileId;
			deleteReq(`/${nameSpace}/${id}`).then(response => {
				if (response && response.success) {
					if (nameSpace !== 'needs')
						setUser(user => ({ ...user, profiles: user.profiles.filter(_profile => _profile.profileId !== profile.profileId) }));
					else
						setUser(user => ({ ...user, needs: user.needs.filter(_need => _need.needId !== profile.needId) }));
					setShouldRefreshUser(true);
					modalProps.closeModal();
				} else {
					setAlert({ show: true, type: "error", message: "Une erreur est survenue lors de la tentative de suppression de votre profil, veuillez réessayer ultérieurement." });
				}
				setDeletingItem(false);
			});
		}
	}

	const managerName = useMemo(() => user?.type === "admin" && user?.userId !== (profile?.managerUserId || profile?.user?.userId || 0) ? profile?.managerName || profile?.user?.fullname : null, [user, profile]);
	let modalTitle = 'SUPPRESSION DU BESOIN';
	if (nameSpace !== 'needs')
		modalTitle = user?.profile === "freelance" ? "SUPPRESSION DE VOTRE PROFIL" : "SUPPRESSION DU PROFIL";
	return (
		<ModalTemplate {...modalProps}>
			<MainWrapper>
				<CustomTitle>
					{ modalTitle }
				</CustomTitle>
				{profile && user?.profile !== "freelance" &&
					<>
						<ReferenceWrapper>
							{profile.hash}
						</ReferenceWrapper>
						<InfoWrapper>
							{managerName && <span><b>Manager : </b>{managerName}</span>}
							{nameSpace !== 'needs' && 
								<span><b>Interco : </b>{profile.firstName} {profile.lastName.toUpperCase()}</span>
							}
							<span><b>Titre : </b>{profile.title}</span>
						</InfoWrapper>
					</>
				}
				<Alert severity="warning">En confirmant cette action, la suppression des données sera permanente : la récupération des données sera donc impossible.</Alert>
				{
					deletingItem ? <CircularProgress />
						: <Button onClick={deleteItem} style={{ width: "50%", margin: "0 auto" }}>Confirmer</Button>
				}
				{
					alert.show && <AlertWrapper>
						<Alert severity={alert.type}>{alert.message}</Alert>
					</AlertWrapper>
				}
			</MainWrapper>
		</ModalTemplate>
	);
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	& > * {
		margin-bottom: 30px;
	}
`;

const CustomTitle = styled.h2`
	color: #241f47;
	font-family: "Prompt Semi Bold";
	font-size: 30px;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #241f47;
	font-family: "Prompt";
	font-size: 16px;
	margin-bottom: 20px;
`;

const ReferenceWrapper = styled(InfoWrapper)`
	margin-top: -30px;
	color: #B2B2B2;
`;

const AlertWrapper = styled.div`
	margin-top: 10px;
`;