import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { TextField, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { FormContainer, CustomTitle } from "./shared/styled-components-library";
import SwitchInput from "../../../shared/components/inputs/switch-input.component";
import AddValueInput from "../../../shared/components/inputs/add-value-input.component";
import TextInput from "../../../shared/components/inputs/text-input.component";
import UnderlineInput from "../../../shared/components/inputs/big-underline-text-input.component";
import RoutingButtons from "./shared/routing-buttons.component";
import DeleteableChipContainer from "../../../shared/components/deleteable-chip-container.component";
import Image from "../../../shared/components/images/image.component";

const defaultStartDate = moment().format("YYYY-MM-DD");
const defaultEndDate = moment()
	.add(1, "year")
	.format("YYYY-MM-DD");
const experienceTemplate = {
	title: "",
	clientName: "",
	anonymous: false,
	startDate: defaultStartDate,
	endDate: defaultEndDate,
	description: "",
	tools: []
};

export default function ExperiencesForm(props) {
	const { handleNext, setCompletedSteps, activeStep } = props;
	const [formData, setFormData] = useContext(props.context);
	const [experiences, setExperiences] = useState([
		{ ...experienceTemplate, key: new Date() }
	]);
	const [showErrors, setShowErrors] = useState(false);

	useEffect(() => {
		loadFormData();
	}, []); // eslint-disable-line

	const loadFormData = () => {
		if (formData && formData.experiencesForm) {
			setExperiences(formData.experiencesForm.experiences);
		}
	};

	const saveFormData = () => {
		setFormData(formData => ({
			...formData,
			experiencesForm: {
				experiences
			}
		}));
	};

	const handleValidationAndNext = () => {
		let hasErrors = false;
		for (const experience of experiences) {
			if (!experience.title.trim() && !experience.clientName.trim()) continue;
			if (
				!experience.title.trim() ||
				(!experience.anonymous && !experience.clientName.trim())
			) {
				hasErrors = true;
				break;
			}
		}
		if (hasErrors) {
			setShowErrors(true);
		} else {
			setCompletedSteps(completedSteps => ({
				...completedSteps,
				[activeStep]: true
			}));
			saveFormData();
			handleNext();
		}
	};

	return (
		<ExperiencesFormLayout
			{...props}
			showErrors={showErrors}
			setShowErrors={setShowErrors}
			experiences={experiences}
			setExperiences={setExperiences}
			handleValidationAndNext={handleValidationAndNext}
		/>
	);
}

/* Le layout est utile pour la création d'un profil MAIS AUSSI pour l'édition d'un profil dans l'espace personnel */
export const ExperiencesFormLayout = props => {
	const {
		showErrors,
		setShowErrors,
		experiences,
		setExperiences,
		handlePrevious,
		handleValidationAndNext,
		isFreelance,
		isEditMode
	} = props;
	const alertRef = useRef(null);
	const [alert, setAlert] = useState({
		show: false,
		type: null,
		message: null
	});

	const scrollToRefObject = ref =>
		ref.current && window.scrollTo(0, ref.current.offsetTop);

	const getNewExperienceTemplate = () => ({
		...experienceTemplate,
		key: new Date()
	});

	const addExperience = () => {
		let canCreateExperience = true;
		setAlert({ show: false, type: null, message: null });
		for (const experience of experiences) {
			if (
				!experience.title.trim() ||
				(!experience.anonymous && !experience.clientName.trim())
			) {
				canCreateExperience = false;
			}
		}
		if (canCreateExperience) {
			const updatedExperiences = experiences.concat([
				getNewExperienceTemplate()
			]);
			setExperiences(updatedExperiences);
			if (setShowErrors) setShowErrors(false);
		} else {
			setAlert({
				show: true,
				type: "error",
				message:
					"Veuillez compléter les informations de la dernière expérience créée avant d'en ajouter une autre."
			});
			if (setShowErrors) setShowErrors(true);
			if (alertRef) scrollToRefObject(alertRef);
		}
	};

	const removeExperience = experienceKey => {
		const updatedExperiences = experiences.filter(
			experience => experience.key !== experienceKey
		);
		setExperiences(updatedExperiences);
		if (setShowErrors) setShowErrors(false);
	};

	/**
	 * Permet à React de facilement détecter les changements sur une expérience et de pouvoir modifier
	 * le render de manière correcte.
	 */
	const getExperienceDeepCopy = experienceKey => {
		const updatedExperiences = experiences.map(experience => ({
			...experience,
			tools: [...experience.tools]
		}));
		const experience = updatedExperiences.find(
			experience => experience.key === experienceKey
		);
		return { updatedExperiences, experience };
	};

	const changeExperienceField = (experienceKey, fieldName, newValue) => {
		const { updatedExperiences, experience } = getExperienceDeepCopy(
			experienceKey
		);
		experience[fieldName] = newValue;
		setExperiences(updatedExperiences);
	};

	const addExperienceTool = (experienceKey, toolName) => {
		const { updatedExperiences, experience } = getExperienceDeepCopy(
			experienceKey
		);
		const alreadyAdded = experience.tools.includes(toolName);
		if (!alreadyAdded && !!toolName.trim()) {
			experience.tools = experience.tools.concat([toolName]);
			setExperiences(updatedExperiences);
		}
	};

	const removeExperienceTool = (experienceKey, toolName) => {
		const { updatedExperiences, experience } = getExperienceDeepCopy(
			experienceKey
		);
		experience.tools = experience.tools.filter(
			experienceTool => experienceTool !== toolName
		);
		setExperiences(updatedExperiences);
	};

	useEffect(
		() => {
			if (showErrors) {
				setAlert({
					show: true,
					type: "error",
					message: `Veuillez entrer les informations obligatoires avant de continuer la création de votre ${
						isFreelance ? "profil" : "interco"
					} (titre, et nom du client si l'expérience n'est pas anonyme).`
				});
				if (alertRef) scrollToRefObject(alertRef);
			}
		},
		[showErrors, isFreelance]
	);

	const [first] = experiences;
	const nextStepButtonLabel =
		experiences.length === 1 &&
		(!first.title.trim() && !first.clientName.trim())
			? "Passer cette étape"
			: "Valider";
	return (
		<FormContainer width={isEditMode ? "90%" : "60%"}>
			{!isEditMode ? <CustomTitle>Expériences</CustomTitle> : null}
			<ExperiencesContainer>
				{experiences.map(experience => (
					<Experience key={experience.key}>
						<ExperienceHeader>
							<UnderlineInput
								placeholder="Titre du projet"
								value={experience.title}
								onChangeHandler={e =>
									changeExperienceField(experience.key, "title", e.target.value)
								}
								error={showErrors && !experience.title.trim()}
								errorText="Ce champ est obligatoire."
							/>
							<StyledIconButton
								onClick={() => {
									removeExperience(experience.key);
								}}
							>
								<Image src="/assets/icons/trash.svg" width="48px"/>
							</StyledIconButton>
						</ExperienceHeader>
						<ExperienceCard>
							<ExperienceInfos>
								<div>
									<CustomTextField
										deactivated={experience.anonymous}
										placeholder="Nom du client"
										value={experience.clientName}
										onChange={e =>
											changeExperienceField(
												experience.key,
												"clientName",
												e.target.value
											)
										}
										error={
											showErrors &&
											!experience.anonymous &&
											!experience.clientName.trim()
										}
										helperText={
											showErrors &&
											!experience.anonymous &&
											!experience.clientName.trim()
												? "Ce champ est obligatoire."
												: ""
										}
									/>
								</div>
								<AnonymousContainer>
									<span>Anonyme</span>
									<SwitchInput
										checked={experience.anonymous}
										onChangeHandler={e =>
											changeExperienceField(
												experience.key,
												"anonymous",
												e.target.checked
											)
										}
									/>
								</AnonymousContainer>
								<TextField
									InputProps={{ inputProps: { max: experience.endDate } }}
									label="Date début"
									type="date"
									value={experience.startDate}
									onChange={e =>
										changeExperienceField(
											experience.key,
											"startDate",
											e.target.value
										)
									}
									InputLabelProps={{
										shrink: true
									}}
									defaultValue={new Date()}
								/>

								<TextField
									InputProps={{ inputProps: { min: experience.startDate } }}
									label="Date de fin"
									type="date"
									value={experience.endDate}
									onChange={e =>
										changeExperienceField(
											experience.key,
											"endDate",
											e.target.value
										)
									}
									InputLabelProps={{
										shrink: true
									}}
									defaultValue={new Date()}
								/>
							</ExperienceInfos>
							<div style={{ marginTop: "10px" }}>
								<TextInput
									value={experience.description}
									onChangeHandler={e =>
										changeExperienceField(
											experience.key,
											"description",
											e.target.value
										)
									}
									placeholder={`Description (optionnelle mais fortement recommandée si vous ne joignez pas de ${
										isFreelance ? "CV" : "dossier de compétence"
									} au profil dans les étapes suivantes).`}
									noMargin
									multiline
									rows={10}
								/>
							</div>
						</ExperienceCard>
						<ExperienceCard noTopMargin>
							<AddValueInput
								addValueHandler={toolName =>
									addExperienceTool(experience.key, toolName)
								}
								placeholder="Ajouter un outil"
							/>
							<DeleteableChipContainer
								chips={experience.tools}
								deleteChipHandler={toolName =>
									removeExperienceTool(experience.key, toolName)
								}
							/>
						</ExperienceCard>
					</Experience>
				))}
				<AddExperience onClick={addExperience}>
					<h4>Cliquez pour ajouter une expérience</h4>
				</AddExperience>
				{alert.show && (
					<Alert ref={alertRef} severity={alert.type}>
						{alert.message}
					</Alert>
				)}
			</ExperiencesContainer>
			{!isEditMode && (
				<RoutingButtons
					handleNext={handleValidationAndNext}
					handlePrevious={handlePrevious}
					nextStepButtonLabel={nextStepButtonLabel}
				/>
			)}
		</FormContainer>
	);
};

const StyledIconButton = styled(IconButton)`
	&& {
		&.MuiIconButton-root {
			padding: 0;
			margin-bottom: auto;
		}
	}
`;

const ExperiencesContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	
	& > * {
		width: 100%;
		margin: 24px 0px;
	}
`;

const AddExperience = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 80px 180px;
	box-shadow: 0 0 10px rgba(0, 0, 0 , 0.2);
	border-radius: 40px;
	cursor: pointer;

	& > h4 {
		font-size: 16px;
		font-family: "Prompt Light Italic";
		color: #F194AC;
	}
`;

const Experience = styled.div`
	display: flex;
	flex-direction: column;

	& > * {
		margin: 5px 0px;
	}
`;

const ExperienceHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ExperienceCard = styled.div`
	background-color: #FFF;
	border-radius: 40px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	padding: ${props => (props.noTopMargin ? "0 16px 16px 16px" : "16px")};
	display: flex;
	flex-direction: column;
`;

const ExperienceInfos = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 0px;
`;

const AnonymousContainer = styled.div`
	display: flex;
	align-items: center;

	& > span {
		font-size: 16px;
		font-family: "Prompt";
		color: #B2B2B2;
	}

	& > * {
		margin: 0 5px;
	}
`;

const CustomTextField = styled(TextField)`
	&& {
		.MuiInputBase-input {
			color: ${props => (props.deactivated ? "#BBB" : "#000")};
		}
	}
`;
