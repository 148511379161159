import React, { useContext } from 'react';

import { AuthContext } from "../../../../contexts/auth.context";
import { Paragraph, MediumTitle } from "../../../../shared/utils/styled-components-library.utils";
import Button from "../../../../shared/components/buttons/button.component";
import YesNoButtons from "../shared/yes-no-buttons.component";

export default function AnonymousForm({ anonymous, setAnonymous, paragraph, handleNext, isFreelance, isEditMode, openSignInModal }) {
	const [auth] = useContext(AuthContext);

	const handleValidationAndNext = () => {
		if (auth.logged) {
			handleNext();
		} else {
			openSignInModal();
		}
	}

	return (
		<>
			<MediumTitle>Souhaitez-vous anonymiser votre {isFreelance ? "profil" : "annonce"} ?</MediumTitle>
			<YesNoButtons style={{ margin: "30px 0px" }} isChoicePositive={anonymous} setChoice={setAnonymous} />
			{
				!isEditMode ?
					<>
						<Paragraph align="center">
                                                  { paragraph }
						</Paragraph>
						<div style={{ marginTop: "40px" }}><Button onClick={handleValidationAndNext} secondary gradient>Valider</Button></div>
					</>
					: null
			}
		</>
	);
}