import React, { useState, useEffect, useContext } from 'react';

import { FormContainer } from "./shared/styled-components-library";

import AnonymousForm from "./subforms/anonymous-form.component";
import DurationForm from "./subforms/duration-form.component";
import LocationForm from "./subforms/location-form.component";
import StudiesForm from "./subforms/studies-form.component";

export default function InfoForm(props) {
  const { subStep, setSubStep } = props;
  const [formData, setFormData] = useContext(props.context);

	// 1er sous-formulaire
	const [anonymous, setAnonymous] = useState(false);

	// 2ème sous-formulaire
	const [title, setTitle] = useState("");
	const [fieldIds, setFieldIds] = useState([]);
	const [availability, setAvailability] = useState("");
	const [duration, setDuration] = useState("");
	const [isRenewable, setIsRenewable] = useState(false);

	// 3ème sous-formulaire
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [workLocationsSelected, setWorkLocationsSelected] = useState([]);
	const [workFormat, setWorkFormat] = useState("");

	// 4ème sous-formulaire
	const [studyLevel, setStudyLevel] = useState("");
	const [experienceYears, setExperienceYears] = useState("");
	const [englishLevel, setEnglishLevel] = useState("");
	const [hasEmpowerment, setHasEmpowerment] = useState(false);
	const [empowerments, setEmpowerments] = useState([]); // habilitations

	useEffect(() => {
		loadFormData();
	}, []); // eslint-disable-line

	const loadFormData = () => {
		if (formData && formData.infoForm) {
			const data = formData.infoForm;
			setAnonymous(data.anonymous);
			setTitle(data.title);
			setFieldIds(data.fieldIds);
			setAvailability(data.availability);
			setDuration(data.duration);
			setIsRenewable(data.isRenewable);
			setFirstname(data.firstname);
			setLastname(data.lastname);
			setWorkLocationsSelected(data.workLocationsSelected);
			setWorkFormat(data.workFormat);
			setStudyLevel(data.studyLevel);
			setExperienceYears(data.experienceYears);
			setEnglishLevel(data.englishLevel);
			setHasEmpowerment(data.hasEmpowerment);
			setEmpowerments(data.empowerments);
		}
	}

	/**
	 * Fonction permettant de sauvegarder dans le contexte les informations relatives à cette partie du formulaire.
	 */
	const saveFormData = () => {
		setFormData(formData => ({
			...formData,
			infoForm: {
				anonymous,
				title,
				fieldIds,
				availability,
				duration,
				isRenewable,
				firstname,
				lastname,
				workLocationsSelected,
				workFormat,
				studyLevel,
				englishLevel,
				experienceYears,
				hasEmpowerment,
				empowerments
			}
		}));

	}

	const handleNext = () => {
		if (subStep === 3) {
			props.setCompletedSteps(completedSteps => ({ ...completedSteps, [props.activeStep]: true }));
			saveFormData();
			props.handleNext();
		} else {
			setSubStep(formerStep => formerStep + 1);
		}
		window.scrollTo(0, 0);
	}

	const handlePrevious = () => {
		setSubStep(formerStep => formerStep - 1);
		window.scrollTo(0, 0);
	}

	const renderCurrentSubForm = (activeSubStep) => {
		const commonSubProps = { handleNext, handlePrevious, isFreelance: props.isFreelance, openSignInModal: props.openSignInModal };
		switch (activeSubStep) {
			case 0:
                          return <AnonymousForm
                              {...commonSubProps}
                              anonymous={anonymous}
                              setAnonymous={setAnonymous}
                              paragraph={
                                <>
                                  <span>Si oui, {props.isFreelance ? "vos informations" : "les informations de votre société"} ne seront pas visibles.</span>
                                  <span>Nous vous recommandons de ne pas {props.isFreelance ? "vous" : "la"} rendre anonyme afin de faciliter les échanges.</span>
                                </>
                              }
                            />;
                        case 1:
                          return <DurationForm
                              {...commonSubProps}
                              fields={props.fields}
                              fieldIds={fieldIds}
                              setFieldIds={setFieldIds}
                              jobTitle={title}
                              setJobTitle={setTitle}
                              availability={availability}
                              setAvailability={setAvailability}
                              duration={duration}
                              setDuration={setDuration}
                              isRenewable={isRenewable}
                              setIsRenewable={setIsRenewable}
                              title={`Informations${props.isFreelance ? '' : ' intercontrat'}`} 
                              jobTitlePlaceholder="Titre du profil"
                              timePlaceholder={ `${props.isFreelance ? "Dans combien de temps êtes-vous disponible ?" : "Dans combien de temps l’intercontrat est disponible ?"}` }
                              durationPlaceholder={ `${props.isFreelance ? "Pendant combien de temps êtes-vous disponible ?" : "Pendant combien de temps le profil est disponible ?"}` }
                            />;
			case 2: return <LocationForm {...commonSubProps} workLocationsSelected={workLocationsSelected} setWorkLocationsSelected={setWorkLocationsSelected} firstname={firstname} setFirstname={setFirstname} lastname={lastname} setLastname={setLastname} workFormat={workFormat} setWorkFormat={setWorkFormat} />;
			case 3: return <StudiesForm {...commonSubProps} studyLevel={studyLevel} setStudyLevel={setStudyLevel} experienceYears={experienceYears} setExperienceYears={setExperienceYears} englishLevel={englishLevel} setEnglishLevel={setEnglishLevel} hasEmpowerment={hasEmpowerment} setHasEmpowerment={setHasEmpowerment} loadedEmpowerments={props.loadedEmpowerments} empowerments={empowerments} setEmpowerments={setEmpowerments} />;
			default: return null;
		}
	}

	return (
		<FormContainer>
			{renderCurrentSubForm(subStep)}
		</FormContainer >
	);
}