import React from 'react';
import ReactPlayer from "react-player";

export default function VideoPlayer(props) {
	
	return (
		<ReactPlayer
			className='react-player'
			light={props.thumbnailUrl || false}
			url={props.videoUrl}
			playIcon={<img src="/assets/icons/play_button.png" alt="play icon" />}
			width='100%'
			height='100%'
			controls={true}
		/>
	)
}