/**
 * Fonction permettant de vérifier la validité d'un input, en fonction de son type, de la valeur
 * entrée et éventuellement de la taille de la valeur.
 * @param {'text' | 'email' | 'number', 'password', 'phone'} type 
 * @param {string} value 
 * @param {number?} length Utile uniquement pour les inputs de type "text", impose une longueur pour
 * la valeur entrée.
 */
export const isValid = (type, value, length = null) => {
	if (typeof value === "string") {
		value = value.trim();
	}
	switch (type) {
		case "text": // comporte uniquement des lettres
			return value && length ? value.length === length : true && /^([^0-9]*)$/.test(value);
		case "email":
			// eslint-disable-next-line
			return value && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
		case "number":
			return value && !isNaN(value);
		case "password":
			return value && /^.*(?=.{10,})((?=.*[!@#$%^&*-_=+;:,.]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(value);
		case "phone":
			return value && /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gmi.test(value);
		// return value && /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/.test(value);
		case "siret":
			return value && /^(\d{14})$/.test(value);
		case "ape":
			return value && /^[0-9][0-9][ .]?[0-9][0-9][A-Z]{1}$/gm.test(value);
                case "website":
                        return value || /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(value);
		default:
			return false;
	}
}

/**
 * Fonction permettant de "raccourcir" une chaîne de caractères à partir d'une certaine longueur.
 * @param {string} input 
 * @param {number} length 
 */
export const getStringAbbreviation = (input, length) => {
	if (input.length < length) return input;
	return input.slice(0, length) + "...";
}