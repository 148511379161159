import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Alert } from "@material-ui/lab";

import ModalTemplate from "../../shared/components/modal-template.component";
import { deleteReq } from "../../shared/utils/api.utils";
import { deleteToken } from "../../shared/utils/auth.utils";
import Button from "../../shared/components/buttons/button.component";

export default function DeleteProfileModal(props) {
	const { user, setUser, selfDelete, ...modalProps } = props; // user : l'utilisateur à supprimer --- setUser : modifier l'administrateur

	useEffect(() => {
		if (modalProps.showModal) {
			setAlert({ show: false, type: null, message: null });
		}
	}, [modalProps.showModal]);

	const [deletingUser, setDeletingUser] = useState(false);
	const [alert, setAlert] = useState({ show: false, type: null, message: null });
	const deleteUser = () => {
		if (!deletingUser) {
			setDeletingUser(true);
			setAlert({ show: false, type: null, message: null });
			deleteReq(`/users/${user.userId}`).then(response => {
				if (response && response.success) {
					if (user?.profile === "freelance" || selfDelete) {
						deleteToken();
						window.location.href = "/";
					} else {
						setUser(_user => ({ ..._user, users: _user.users.filter(manager => manager.userId !== user.userId) }));
						modalProps.closeModal();
					}
				} else {
					setAlert({ show: true, type: "error", message: "Une erreur est survenue lors de la tentative de suppression de cet utilisateur, veuillez réessayer ultérieurement." });
				}
			});
		}
	}

	return (
		<ModalTemplate {...modalProps}>
			<MainWrapper>
				<CustomTitle>
					SUPPRESSION {user?.profile === "freelance" || selfDelete ? "DE VOTRE COMPTE" : "D'UN UTILISATEUR"}
				</CustomTitle>
				{
					user ?
						<>
							{
								!selfDelete &&
								<UserInfo>
									<div>
										<span className="bold">Prénom</span> : {user.firstname}
									</div>
									<div>
										<span className="bold">Nom</span> : {user.lastname.toUpperCase()}
									</div>
								</UserInfo>
							}
							<Alert severity="warning">En confirmant cette action, la suppression des données sera permanente : la récupération des données sera donc impossible.</Alert>
							<Button onClick={deleteUser} style={{ width: "50%", margin: "0 auto" }}>Confirmer</Button>
							{
								alert.show && <AlertWrapper>
									<Alert severity={alert.type}>{alert.message}</Alert>
								</AlertWrapper>
							}
						</>
						: null
				}
			</MainWrapper>
		</ModalTemplate>
	);
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	& > * {
		margin-bottom: 30px;
	}
`;

export const CustomTitle = styled.h2`
	color: #241f47;
	font-family: "Prompt Semi Bold";
	font-size: 30px;
`;

const UserInfo = styled.div`
	color: #333;
	font-family: "Prompt";
	font-size: 20px;

	& .bold {
		font-family: "Prompt Semi Bold";
	}
`;

export const AlertWrapper = styled.div`
	margin-top: 10px;
`;