import React from "react";
import styled from "styled-components";
import {
	Input,
	InputLabel,
	FormControl,
	withStyles,
	FormHelperText
} from "@material-ui/core";

function UnderlineTextInput({
	classes,
	label,
	placeholder,
	value,
	onChangeHandler,
	deactivate,
	error,
	errorText,
	type,
	step,
	min
}) {
	return (
		<FormControl fullWidth error={error}>
			{label ? (
				<InputLabel className={classes.resize}>{label}</InputLabel>
			) : null}
			<CustomInput
				disabled={!!deactivate}
				value={value}
				onChange={onChangeHandler}
				placeholder={placeholder}
				name="name"
				className={classes.textField}
				autoComplete="off"
				type={type || "text"}
				inputProps={{
					step: step || "",
					min: min ? "0" : ""
				}}
			/>
			{error && <FormHelperText error>{errorText}</FormHelperText>}
		</FormControl>
	);
}

const styles = {
	textField: {
		height: 52,
		fontSize: 22
	},
	resize: {
		fontSize: 30
	}
};

const CustomInput = styled(Input)`
	&& {
		.MuiInput-input {
			font-family: "Prompt";
			color: ${props => (props.disabled ? "#BBB" : "#42387a")};
		}
	}
`;

export default withStyles(styles)(UnderlineTextInput);
