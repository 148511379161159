import React from "react";
import styled from "styled-components";

import { BigTitle } from "../../shared/utils/styled-components-library.utils";
import Image from "../../shared/components/images/image.component";

export default function ContactUs() {
  return (
    <PageContainer>
      <PageHeader>
        <BigTitle>À PROPOS</BigTitle>
        <Description align="center">
          1terco est le fruit de la rencontre entre deux amis, Alexandre et
          Rahavan, l’un venant du secteur marketing digital et l’autre du
          secteur de service en ingénierie. Tout deux passionnés de tech, ils
          sont convaincus que les plateformes digitales existantes dans le
          secteur du service, ingénierie et informatiques, sont perfectibles,
          tant au niveau de l’expérience utilisateur, de leur efficacité, ainsi
          que leurs coûts d’utilisation.
        </Description>
      </PageHeader>
      <Image src="/assets/images/about-top.png" width="100%" />
      <ArticlesWrapper>
        <ArticlesContainer>
          <Article>
            <ArticleContent>
              <ArticleBody>
                <ArticleText>
                  <ArticleHeader>Notre philosophie</ArticleHeader>
                  <CustomParagraph>
                    <span>
                      Chez 1TERCO, nous incarnons l'esprit start-up : agilité,
                      rapidité et efficacité sont au cœur de notre approche.
                      Nous savons que chaque minute compte, c'est pourquoi nous
                      nous engageons à vous offrir un service client réactif,
                      disponible du lundi au samedi de 8h30 à 19h.
                    </span>
                    <br />
                    <span>
                      Nous croyons en l'importance d'un accompagnement sur
                      mesure. Dès le départ, nous vous proposons un rendez-vous
                      personnalisé pour définir précisément vos besoins.
                      Ensuite, nous vous offrons une formation complète pour
                      prendre en main notre plateforme de manière optimale.
                    </span>
                    <br />
                    <span>
                      Avec 1TERCO, bénéficiez d'un service rapide, efficace et
                      entièrement adapté à vos attentes, afin que vous puissiez
                      vous concentrer sur ce qui compte vraiment : la réussite
                      de vos projets. opportunités.
                    </span>
                  </CustomParagraph>
                </ArticleText>
                <ArticleImageContainer>
                  <Image
                    src="/assets/images/gorille-rencontre.png"
                    width="100%"
                  />
                </ArticleImageContainer>
              </ArticleBody>
            </ArticleContent>
          </Article>
          <Article>
            <ArticleContent>
              <ArticleBody>
                <ArticleImageContainer>
                  <Image src="/assets/images/gorille-5.png" width="70%" />
                </ArticleImageContainer>
                <ArticleText>
                  <ArticleHeader>Points forts de la plateforme</ArticleHeader>
                  <CustomParagraph>
                    <ol>
                      <li>
                        <b>Responsive Design</b> : La plateforme est entièrement
                        optimisée pour une utilisation sur tous les types
                        d'appareils, que ce soit sur ordinateur, tablette ou
                        smartphone. Grâce à son design responsive, l'expérience
                        utilisateur reste fluide et agréable, quel que soit
                        l'appareil utilisé. Les utilisateurs peuvent accéder à
                        toutes les fonctionnalités sans compromis, où qu'ils se
                        trouvent.
                      </li>
                      <li>
                        <b>Matching Automatique entre Candidats et Missions</b>{" "}
                        : L'un des atouts majeurs de la plateforme est son
                        système de matching intelligent. Grâce à des algorithmes
                        avancés, la plateforme analyse les compétences des
                        candidats et les exigences des missions pour proposer
                        automatiquement les correspondances les plus
                        pertinentes. Ce processus permet de gagner du temps et
                        d'assurer une adéquation optimale entre les profils et
                        les besoins des missions.
                      </li>
                      <li>
                        <b>Simplicité et Rapidité</b> : L'outil a été conçu pour
                        être extrêmement simple et rapide à utiliser. En
                        quelques clics, les utilisateurs peuvent poster une
                        mission, trouver un candidat ou postuler à une offre.
                        Cette simplicité d'utilisation permet de réduire les
                        délais de recrutement et d'optimiser les processus,
                        rendant l'expérience utilisateur plus efficace.
                      </li>
                      <li>
                        <b>Ergonomie</b> : La plateforme se distingue par son
                        interface ergonomique, pensée pour offrir une expérience
                        utilisateur intuitive. Chaque fonctionnalité est
                        facilement accessible, avec un design clair et une
                        organisation logique des informations. Cette ergonomie
                        facilite la navigation et permet aux utilisateurs de se
                        concentrer sur l'essentiel sans perdre de temps à
                        chercher des fonctionnalités.
                      </li>
                    </ol>
                  </CustomParagraph>
                </ArticleText>
              </ArticleBody>
            </ArticleContent>
          </Article>
        </ArticlesContainer>
      </ArticlesWrapper>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -15em;
`;

const PageHeader = styled.div`
  width: 60%;
  margin-bottom: -8vw;

  & > * {
    margin-bottom: 40px;
  }
`;

const ArticlesWrapper = styled.div`
  padding-bottom: 20em;
  background-image: linear-gradient(to right, #f9cdd5 20%, #f29daa 105%);
`;
const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 5em auto 0 auto;
`;

const Article = styled.div`
  background-color: white;
  border-radius: 40px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  margin: 20px 0px;
  flex-direction: ${(props) => (props.reversed ? "row-reverse" : "row")};

  & > * {
    margin: 0 1%;
  }
`;

const ArticleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(2em + 2vw) 2vw;
`;

const ArticleHeader = styled.h3`
  color: #241f47;
  font-size: 26px;
  font-family: "Prompt Black";
  text-transform: uppercase;
  text-align: left;
  font-weight: normal;
  margin-bottom: 20px;
`;

const ArticleBody = styled.div`
  display: flex;
`;

const ArticleText = styled.div`
  flex: 4;
  padding: 0 2em;
`;

const ArticleImageContainer = styled.div`
  flex: 5;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

const Description = styled.p`
  font-family: "Prompt Light";
  font-size: 20px;
  margin: 0 auto;
  color: #241f47;
  line-height: 24px;
  text-align: ${(props) => (props.align ? props.align : "left")};
  max-width: 650px;
`;

const CustomParagraph = styled.p`
  font-family: "Prompt Light";
  font-size: 16px;
  margin: 0;
  color: #241f47;
  line-height: 24px;
  text-align: ${(props) => (props.align ? props.align : "left")};
`;
