import React from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function NotFound() {
	return (
		<NotFoundBody>
			<div>
				<NotFoundTitle>Erreur 404</NotFoundTitle>
				<NotFoundReason>La page n'a pas été trouvée</NotFoundReason>
				<HomeLink to="/">Revenir à la page d'accueil</HomeLink>
			</div>
		</NotFoundBody>
	);
}

const NotFoundBody = styled.div`
	display: flex;
	text-align: left;
	justify-content: space-between;
	width: 70%;
	min-height: 60vh;
	margin: 30px 15%;
	font-family: "Prompt Semi Bold";
`;

const NotFoundTitle = styled.h2`
	font-size: 50px;
	margin: 80px 0px 10px 0px;
	color: #241f47;
`;

const NotFoundReason = styled.h3`
	margin-top: 10px;
	font-size: 30px;
	color: #241f47;
`;

const HomeLink = styled(Link)`
	font-family: "Prompt";
	font-size: 20px;
	color: #111;
`;