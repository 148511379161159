import React, { useState } from 'react';
import styled from "styled-components";
import { TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutocompleteComponent(props) {
	const [inputValue, setInputValue] = useState("");

	return (
		<Wrapper hideOptions={inputValue.length < 2}>
			<Autocomplete
				value={props.value}
				onChange={props.onChange}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				popupIcon={null}
				options={inputValue.length >= 2 ? props.options : []}
				noOptionsText={props.noOptionsLabel || "Pas d'option disponible"}
				getOptionLabel={(option) => option.label || ""}
				renderInput={(params) => <StyledTextField {...params} required={props.required} label={props.placeholder} variant="outlined" error={props.error} helperText={props.error ? props.errorText : ""} />}
				disablePortal
				required={props.required}
			/>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	&& {
		& .MuiAutocomplete-popper {
			display: ${props => props.hideOptions ? "none" : "block"};
		}
	}
`;

const StyledTextField = withStyles({
	root: {
		width: '100%',
		'& label': {
			fontFamily: 'Prompt Light',
			color: '#42387a',
			left: '13px',
		},
		'& label.Mui-focused, & label.Mui-disabled': {
			color: '#42387a',
		},
		'& .MuiInputLabel-asterisk': {
			color: '#f2a0ad',
		},
		'& .MuiInputLabel-asterisk': {
			color: '#f2a0ad',
		},
		'& .MuiInputBase-root': {
			paddingLeft: 20
		},
		'& .MuiOutlinedInput-root': {
			fontFamily: 'Prompt Light',
			color: '#42387a',
			'& input': {
				padding: "18.5px 27px",
				zIndex: 1,
			},
			'& fieldset': {
				backgroundColor: 'white',
				borderColor: '#ffc4ce',
				width: '100%',
				boxSizing: 'border-box',
				borderRadius: '28px',
				borderWidth: '1px',
				padding: '0 20px',
			},
			'&:hover fieldset': {
				borderColor: '#f2a0ad',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#f2a0ad',
			},
			'&.Mui-disabled fieldset': {
				fontWeight: 'bold',
				borderColor: '#ffc4ce',
			},
			'&.Mui-disabled fieldset': {
				fontWeight: 'bold',
				borderColor: '#ffc4ce',
			}
		},
	},
})(TextField);