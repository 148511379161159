import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import ModalTemplate from "../../shared/components/modal-template.component";
import Button from "../../shared/components/buttons/button.component";

import AnonymousForm from "../components/createinterco/subforms/anonymous-form.component";
import DurationForm from "../components/createinterco/subforms/duration-form.component";
import MiscellaneousForm from "../components/createneed/subforms/miscellaneous-form.component";
import { DescriptionFormLayout } from "../components/createneed/description-form.component";
import { SkillsFormLayout } from "../components/createinterco/skills-form.component";
import { PricesFormLayout, priceIsOK } from "../components/createneed/prices-form.component";

import {
  mapWorkFormatLabelToValue,
  mapStudyLevelLabelToValue,
  mapExperienceYearsLabelToValue,
  mapEnglishLevelLabelToValue,
  mapAvailabilityLabelToValue,
  mapDurationLabelToValue,
} from "../../constants/select-options.constants";
import { get, post } from "../../shared/utils/api.utils";

/**
 * @param {object} props
 * @param {object} props.user L'utilisateur qui est dans son espace personnel.
 * @param {object} props.need Le profil que l'on souhaite modifier.
 */
export default function UpdateNeedModal(props) {
  const {
    user,
    need,
    setNeedToUpdate,
    loadedFields,
    loadedWorkLocations,
    refreshUserData,
    ...modalProps
  } = props;
  const isFreelance = user && user.profile === "freelance";

  // lorsqu'on affiche un profil puis qu'on souhaite le modifier, on a toutes les informations pour cela
  // cependant, lorsqu'on a la liste des intercos et qu'on souhaite en modifier un depuis cette interface,
  // alors il nous manque des données
  const isNeedWithInfo = need && !!need.skills;

  const commonFormProps = { isFreelance, isEditMode: true };
  const [defaultArrays, setDefaultArrays] = useState({});
  const [editErrors, setEditErrors] = useState([]);
  const [submittingEditedNeed, setSubmittingEditedNeed] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    type: null,
    message: null,
  });

  const [anonymous, setAnonymous] = useState(false);

  const [title, setTitle] = useState("");
  const [fieldIds, setFieldIds] = useState([]);
  const [availability, setAvailability] = useState("");
  const [duration, setDuration] = useState("");
  const [isRenewable, setIsRenewable] = useState(false);

  const [workLocationsSelected, setWorkLocationsSelected] = useState([]);
  const [workFormat, setWorkFormat] = useState("");

  const [studyLevel, setStudyLevel] = useState("");
  const [experienceYears, setExperienceYears] = useState("");
  const [englishLevel, setEnglishLevel] = useState("");
  const [hasEmpowerment, setHasEmpowerment] = useState(false);
  const [empowerments, setEmpowerments] = useState([]);

  // description form state
  const [missionContext, setMissionContext] = useState("");
  const [description, setDescription] = useState("");
  const [expertise, setExpertise] = useState("");

  const [skills, setSkills] = useState([]);
  const [preEmploymentOpen, setPreEmploymentOpen] = useState(false);
  const [price, setPrice] = useState("");
  const [negotiable, setNegotiable] = useState(false);
  const [companyPanel, setCompanyPanel] = useState(null);
  const [diffusionToAllCompanies, setDiffusionToAllCompanies] = useState(false);
  const [diffusionToAllFreelances, setDiffusionToAllFreelances] = useState(false);
  const [propositionNumber, setPropositionNumber] = useState(0);
  const [notificationFrequence, setNotificationFrequence] = useState('daily');


  const [isSafari, setIsSafari] = useState(false);
  // on récupère les données nécessaires
  useEffect(() => {
    let saf = navigator.userAgent.toLowerCase();
    if (saf.indexOf("safari") > -1 && saf.indexOf("chrome") === -1) {
      setIsSafari(true);
    }
  }, []);

  useEffect(
    () => {
      if (need) {
        if (isNeedWithInfo) {
          autocompleteEditFields(need);
        } else {
          get(`/needs/${need.hash}`).then((response) => {
            if (response && response.success) {
              setNeedToUpdate(response.profile);
            }
          });
        }
      }
    },
    [need] // eslint-disable-line
  );

  useEffect(() => {
    if (!modalProps.showModal) {
      setAlert({ show: false, type: null, message: null });
    }
  }, [modalProps.showModal]);

  /**
   * Fonction permettant de pré-remplir les champs du modal pour modifier le modal.
   * @param {object} need Profil à utiliser pour pré-remplir les champs, il doit comporter
   * toutes les informations existantes (habilitations, expériences, ...).
   */
  const autocompleteEditFields = (need) => {
    // on vérifie que le profil n'est pas vide
    if (need && !!need.title) {
      const defaultWorkLocations = need.workLocations.map((workLocation) => {
        return !!workLocation.departmentId
          ? `${workLocation.regionId}.${workLocation.departmentId}`
          : `${workLocation.regionId}`;
      });
      // loadedFields
      const selectedFieldIds = need.fields.map((field) => field.fieldId);
      const selectedFields = loadedFields.filter((field) =>
        selectedFieldIds.includes(field.fieldId)
      );
      setFieldIds(selectedFields);

      setDefaultArrays({
        fields: selectedFields,
        skills: need.skills,
        empowerments: need.empowerments,
        workLocations: defaultWorkLocations,
      });
      setAnonymous(!!need.anonymous);
      setTitle(need.title);
      setFieldIds(selectedFields);
      setAvailability(mapAvailabilityLabelToValue[need.availability]);
      setDuration(mapDurationLabelToValue[need.duration]);
      setIsRenewable(!!need.renewable);
      setWorkLocationsSelected(defaultWorkLocations);
      setWorkFormat(mapWorkFormatLabelToValue[need.type]);
      setStudyLevel(mapStudyLevelLabelToValue[need.studies]);
      setExperienceYears(mapExperienceYearsLabelToValue[need.experience]);
      setEnglishLevel(mapEnglishLevelLabelToValue[need.englishLevel]);
      setHasEmpowerment(!!need.empowerments.length);
      setEmpowerments(need.empowerments);
      setSkills(need.skills);
      setPreEmploymentOpen(need.trialPeriod === null ? null: need.trialPeriod === 1);
      setPrice(need.price + "");
      setNegotiable(!!need.negotiable);
      setCompanyPanel(need.companyListId);
      setDiffusionToAllCompanies(!need.companyListId);
      setDiffusionToAllFreelances(!!need.allFreelancesNotified);
      setPropositionNumber(need.propositionNumber === null ? -1 : need.propositionNumber);
      setNotificationFrequence(need.notificationFrequence);
      setMissionContext(need.missionContext);
      setDescription(need.missionDescription);
      setExpertise(need.missionExpertise);
    }
  };

  /**
   * Fonction permettant de vérifier si les modifications de l'utilisateur sont aptes à être
   * enregistrées en BDD.
   */
  const canSubmit = () => {
    const errors = [];
    if (!negotiable && !priceIsOK(price)) {
      errors.push("Veuillez indiquer un TJM valable");
    }
    if (!title.trim()) errors.push("Le titre n'est pas indiqué");
    if (!fieldIds.length)
      errors.push("Au moins un secteur d'activité doit être sélectionné");

    if (!workLocationsSelected.length)
      errors.push("Au moins un lieu de travail doit être sélectionné");
    if (hasEmpowerment && !empowerments.length) {
      errors.push(
        `Si ${
          isFreelance ? "vous avez" : "l'interco a"
        } des habilitations, veuillez en ajouter au moins une.`
      );
    }
    if (experienceYears === "") {
      errors.push("Veuillez indiquer le nombre d'années d'expérience");
    }
    if (englishLevel === "") {
      errors.push("Veuillez indiquer le niveau d'anglais");
    }
    if (!skills.length) errors.push("Veuillez ajouter au moins une compétence");

    if (!diffusionToAllCompanies && !companyPanel) {
      errors.push("Veuillez sélectionner au moins une liste de diffusion");
    }

    if (propositionNumber === null) {
      errors.push("Veuillez indiquer le nombre de propositions");
    }

    setEditErrors(errors);
    return !errors.length;
  };

  const submitEdit = () => {
    if (submittingEditedNeed) return null;
    if (!canSubmit()) return null;
    const payload = {
      needId: need.needId,
      anonymous: anonymous,
      title: title,
      fieldIds: fieldIds,
      availability: availability,
      duration: duration,
      renewable: isRenewable,
      workLocations: workLocationsSelected,
      workFormat: workFormat,
      studyLevel: studyLevel,
      experienceYears: experienceYears,
      englishLevel: englishLevel,
      hasEmpowerment: hasEmpowerment,
      empowerments: empowerments,
      missionContext,
      missionDescription: description,
      missionExpertise: expertise,
      skills: skills,
      trialPeriod: preEmploymentOpen,
      price: price,
      negotiable,
      companyList: diffusionToAllCompanies ? null : companyPanel,
      allFreelancesNotified: diffusionToAllFreelances,
      notificationFrequence,
      propositionNumber: propositionNumber === -1 ? null : propositionNumber,
      updatedArrays: {
        fields: defaultArrays.fields !== fieldIds,
        skills: defaultArrays.skills !== skills,
        empowerments: defaultArrays.empowerments !== empowerments,
        workLocations: defaultArrays.workLocations !== workLocationsSelected,
      },
    };
    setSubmittingEditedNeed(true);
    setAlert({ show: false, type: null, message: null });
    post("/needs/update-need", payload).then((response) => {
      if (response && response.success) {
          setSubmittingEditedNeed(false);
          refreshUserData();
          props.closeModal();
      } else {
        setSubmittingEditedNeed(false);
        setAlert({
          show: true,
          type: "error",
          message:
            response.errorMessage ||
            "La modification de votre besoin n'a pas aboutie.",
        });
      }
    });
  };

  return (
    <ModalTemplate {...modalProps} width="900px">
      <MainWrapper>
        <CustomTitle>MODIFICATION DU BESOIN</CustomTitle>
        <ReferenceWrapper>{need && need.hash}</ReferenceWrapper>
        {isNeedWithInfo ? (
          <>
            <FormsWrapper>
              <FormWrapper>
                <AnonymousForm
                  {...commonFormProps}
                  anonymous={anonymous}
                  setAnonymous={setAnonymous}
                  paragraph={
                    <span>Si besoin anonyme, les informations de votre société et vos coordonnées ne seront pas visibles</span>
                  }
                />
              </FormWrapper>
              <FormWrapper width="80%">
                <DurationForm
                  {...commonFormProps}
                  fields={loadedFields}
                  fieldIds={fieldIds}
                  setFieldIds={setFieldIds}
                  jobTitle={title}
                  setJobTitle={setTitle}
                  availability={availability}
                  setAvailability={setAvailability}
                  duration={duration}
                  setDuration={setDuration}
                  isRenewable={isRenewable}
                  setIsRenewable={setIsRenewable}
                  title={'Informations besoin'} 
                  jobTitlePlaceholder="Titre de la mission"
                  timePlaceholder="Dans combien de temps la mission doit démarrer ?"
                  durationPlaceholder="Quelle est la durée de la mission ?"
                />
              </FormWrapper>
              <FormWrapper width="80%">
                <MiscellaneousForm
                  {...commonFormProps}
                  workLocations={workLocationsSelected}
                  setWorkLocations={setWorkLocationsSelected}
                  workFormat={workFormat}
                  setWorkFormat={setWorkFormat}
                  experienceYears={experienceYears}
                  setExperienceYears={setExperienceYears}
                  englishLevel={englishLevel}
                  setEnglishLevel={setEnglishLevel}
                  hasEmpowerment={hasEmpowerment}
                  setHasEmpowerment={setHasEmpowerment}
                  empowerments={empowerments}
                  setEmpowerments={setEmpowerments}
                />

              </FormWrapper>
              <FormWrapper>
                <DescriptionFormLayout
                  {...commonFormProps}
                  missionContext={missionContext}
                  setMissionContext={setMissionContext}
                  description={description}
                  setDescription={setDescription}
                  expertise={expertise}
                  setExpertise={setExpertise}
                />
              </FormWrapper>
              <FormWrapper>
                <SkillsFormLayout
                  {...commonFormProps}
                  skills={skills}
                  setSkills={setSkills}
                />
              </FormWrapper>
              <FormWrapper>
                <PricesFormLayout
                  {...commonFormProps}
                  preEmploymentOpen={preEmploymentOpen}
                  setPreEmploymentOpen={setPreEmploymentOpen}
                  price={price}
                  setPrice={setPrice}
                  negotiable={negotiable}
                  setNegotiable={setNegotiable}
                  companyPanel={companyPanel}
                  setCompanyPanel={setCompanyPanel}
                  diffusionToAllCompanies={diffusionToAllCompanies}
                  setDiffusionToAllCompanies={setDiffusionToAllCompanies}
                  diffusionToAllFreelances={diffusionToAllFreelances}
                  setDiffusionToAllFreelances={setDiffusionToAllFreelances}
                  propositionNumber={propositionNumber}
                  setPropositionNumber={setPropositionNumber}
                  notificationFrequence={notificationFrequence}
                  setNotificationFrequence={setNotificationFrequence}
                />
              </FormWrapper>
            </FormsWrapper>
            {!!editErrors.length && (
              <AlertWrapper>
                <Alert severity="error">
                  <span>
                    La modification ne peut pas être enregistrée, veuillez
                    régler les erreurs suivantes :
                  </span>
                  <ul>
                    {editErrors.map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </Alert>
              </AlertWrapper>
            )}
            {alert.show && (
              <AlertWrapper>
                <Alert severity={alert.type}>{alert.message}</Alert>
              </AlertWrapper>
            )}
            <SubmitWrapper>
              {submittingEditedNeed ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    onClick={submitEdit}
                    secondary
                    gradient
                    style={{
                      paddingTop: isSafari ? "40px" : "16px",
                      paddingBottom: isSafari ? "40px" : "16px",
                    }}
                  >
                    Enregistrer les modifications
                  </Button>
                  <Button
                    outlineButton
                    secondary
                    onClick={ () => modalProps.closeModal() }
                    style={{
                      padding: isSafari ? "40px" : "16px",
                      paddingBottom: isSafari ? "40px" : "16px",
                      color: "#241f47",
                    }}
                  >
                    Annuler
                  </Button>
                </>
              )}
            </SubmitWrapper>
          </>
        ) : null}
      </MainWrapper>
    </ModalTemplate>
  );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  overflow-y: auto;
  margin-right: -20px;
  margin-bottom: 40px;
`;

const CustomTitle = styled.h2`
  color: #241f47;
  font-family: "Prompt Semi Bold";
  font-size: 30px;
  margin: 0;
`;

const ReferenceWrapper = styled.div`
  color: #9e9e9e;
  font-family: "Prompt";
  font-size: 16px;
  margin-bottom: 20px;
  text-decoration: underline;
`;

const FormsWrapper = styled.div`
  width: 100%;
  margin: 15px 0 30px 0;
`;

const FormWrapper = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const SubmitWrapper = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;

  & > * {
    margin: 5px 0;
  }
`;

const AlertWrapper = styled.div`
  margin-top: 15px;
`;
