import React from 'react';
import styled from "styled-components";

export default function ProfileActivity({ active, nameSpace }) {
	return (
		<Wrapper>
			<ActivePill active={active} />
			<span>{ nameSpace === 'needs' ? 'Besoin' : 'Profil' } {active ? "actif" : "désactivé"}</span>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display:flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	white-space: nowrap;

	& > span {
		margin-left: 5px;
		font-size: 14px;
		font-family: "Prompt";
		color: #333;
	}
`;

const ActivePill = styled.div`
	width: 14px;
	height: 14px;
	border-radius: 50px;
	background-color: ${props => props.active ? "#6DD400" : "#F7B500"};
`;