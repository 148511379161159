/**
 * 1 : Inscription
 * 2 : Proposition d'un interco
 * 3 : Recherche & alerte
 * 4 : Proposition d'un besoin
 */

// 1.1 Genre
export const genderOptions = [{ value: 1, label: "Monsieur" }, { value: 2, label: "Madame" }];

// 1.2 Fonction dans l'entreprise
export const jobOptions = [
  { label: 'Gérant', value: 'manager', },
  { label: 'Directeur commercial', value: 'commercial director'},
  { label: 'Responsable Pôle recrutement', value: 'HR', },
  { label: 'Business Manager - Ingénieur d\'affaires', value: 'business manager', },
  { label: 'Chargé de recrutement', value: 'recruitment officer', },
  { label: 'Directeur achat', value: 'purchasing director', },
  { label: 'Acheteur', value: 'buyer', },
  { label: 'Directeur technique', value: 'technical director', },
  { label: 'PDG', value: 'ceo', },
];

// 1.3. Canal d'acquisition
export const acquisitionOptions = [ { label: 'Internet', value: 'internet', }, { label: 'Mailing', value: 'mailing', }, { label: 'Réseau professionnel', value: 'profesional network', }, {label: 'Recommandation', value: 'recommendation'}, {label: 'Autres', value: 'other'}];

// 2.1. Mobilité
export const workFormatOptions = [{ label: "Chez le client", value: 1 }, { label: "Télétravail et/ou chez le client", value: 2 }, { label: "Uniquement télétravail", value: 3 }];
// 2.2. Niveau d'études
export const studyLevelOptions = [{ label: "BAC", value: 1 }, { label: "BAC+2", value: 2 }, { label: "BAC+3", value: 3 }, { label: "BAC+4", value: 4 }, { label: "BAC+5", value: 5 }, { label: "Supérieur à BAC+5", value: 6 }];
// 2.3. Années d'expériences
export const experienceYearsOptions = [{ label: "1 an", value: 1 }, { label: "2 ans", value: 2 }, { label: "3 ans", value: 3 }, { label: "4 ans", value: 4 }, { label: "5 ans", value: 5 }, { label: "6 ans", value: 6 }, { label: "7 ans", value: 7 }, { label: "8 ans", value: 8 }, { label: "9 ans", value: 9 }, { label: "10 ans", value: 10 }, { label: "Supérieur à 10 ans", value: 11 }];
// 2.4. Niveau d'anglais
export const englishLevelOptions = [{ label: "Scolaire", value: 1 }, { label: "Opérationnel", value: 2 }, { label: "Excellent", value: 3 }];
// 2.5. Disponibilité pour l'interco
export const availabilityOptions = [{ label: "ASAP", value: 1 }, { label: "1 mois", value: 2 }, { label: "2 mois", value: 3 }, { label: "3 mois", value: 4 }, { label: "4 mois", value: 5 }, { label: "5 mois", value: 6 }, { label: "6 mois", value: 7 }, { label: "Supérieur à 6 mois", value: 8 }];
// 2.6. Durée de l'interco
export const durationOptions = [{ label: "Inférieur à 3 mois", value: 1 }, { label: "3 mois", value: 2 }, { label: "6 mois", value: 3 }, { label: "12 mois", value: 4 }, { label: "18 mois", value: 5 }, { label: "24 mois", value: 6 }, { label: "Supérieur à 24 mois", value: 7 }];

// 3.1 Jour de notification des alertes
export const dayOptions = [{ label: "Lundi", value: "Monday" }, { label: "Mardi", value: "Tuesday" }, { label: "Mercredi", value: "Wednesday" }, { label: "Jeudi", value: "Thursday" }, { label: "Vendredi", value: "Friday" }, { label: "Samedi", value: "Saturday" }, { label: "Dimanche", value: "Sunday" }];

export const alertOptions = [{ label: "Alertes profils", value: "profiles" }, { label: "Alertes besoins", value: "needs" }];

// 4.1 Onglet prix
export const propositionNumberOptions = [{ label: "Illimité", value: -1 }, { label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }];
export const frequenceOptions = [{ label: "Temps réel", value: "real-time" }, { label: "Quotidienne", value: "daily" }, { label: "Hebdomadaire", value: "weekly" }];

/* *************** */
const getMapLabelToValue = (options) => {
	const map = {};
	for (const option of options) map[option.label] = option.value;
	return map;
}

export const mapWorkFormatLabelToValue = getMapLabelToValue(workFormatOptions);
export const mapStudyLevelLabelToValue = getMapLabelToValue(studyLevelOptions);
export const mapExperienceYearsLabelToValue = getMapLabelToValue(experienceYearsOptions);
export const mapEnglishLevelLabelToValue = getMapLabelToValue(englishLevelOptions);
export const mapAvailabilityLabelToValue = getMapLabelToValue(availabilityOptions);
export const mapDurationLabelToValue = getMapLabelToValue(durationOptions);
