import React from 'react';
import styled from "styled-components";

export default function Logo(props) {
	return (
		<ImageContainer width={props.width}>
			<LogoImage className={props.className} src="/assets/logo.png" alt="logo"/>
		</ImageContainer>
	);
}

const ImageContainer = styled.div`
	width: ${props => props.width || "100px"};
`;

const LogoImage = styled.img`
	width: 100%;
	height: auto;
`;