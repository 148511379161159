import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { LightTooltip } from "../../../shared/utils/styled-components-library.utils";
import { Rating, Alert } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { CustomTitle, FormContainer } from "./shared/styled-components-library";
import RoutingButtons from "./shared/routing-buttons.component";
import AddAutocomplete from "../../../shared/components/inputs/add-value-autocomplete-input.component";
import { fetchAllSkills, } from "../../../shared/utils/fetch.utils";
import Image from "../../../shared/components/images/image.component";

const tooltipTexts = {
  1: "Connaissances uniquement scolaire",
  2: "Pratiqué 6 mois",
  3: "Pratiqué entre 1 an et 2 ans",
  4: "Pratiqué entre 3 et 5 ans",
  5: "Pratiqué plus de 5 ans"
};

export default function SkillsForm(props) {
  const {
    handleNext,
    activeStep,
    setCompletedSteps,
  } = props;
  const [formData, setFormData] = useContext(props.context);
  const [skills, setSkills] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    loadFormData();
  }, []); // eslint-disable-line

  const loadFormData = () => {
    if (formData && formData.skillsForm) {
      setSkills(formData.skillsForm.skills);
    }
  };

  const handleValidationAndNext = () => {
    const hasError = skills.length === 0;
    if (hasError) {
      setShowErrors(true);
    } else {
      setCompletedSteps(completedSteps => ({ ...completedSteps, [activeStep]: true }));
      saveFormData();
      handleNext();
    }
  };

  const saveFormData = () => {
    setFormData(formData => ({
      ...formData,
      skillsForm: {
        skills
      }
    }));
  };

  return (
    <SkillsFormLayout
      {...props}
      showErrors={showErrors}
      skills={skills}
      setSkills={setSkills}
      handleValidationAndNext={handleValidationAndNext}
    />
  );
}

/* Le layout est utile pour la création d'un profil MAIS AUSSI pour l'édition d'un profil dans l'espace personnel */
export const SkillsFormLayout = (props) => {
  const {
    showErrors,
    handlePrevious,
    handleValidationAndNext,
    isEditMode,
                skills,
                setSkills,
  } = props;
  const [tooltipLabel, setTooltipLabel] = useState("");
        const [loadedSkills, setLoadedSkills] = useState([]);
        useEffect(() => {
          fetchAllSkills((_skills) => setLoadedSkills(_skills));
        }, []);

  const addSkill = inputValue => {
    const value = inputValue.trim();
    const alreadyAdded = skills.find(skill => skill.label === value);
    if (!alreadyAdded && !!inputValue) {
      const updatedSkills = skills.concat([{ label: inputValue, rating: 3 }]);
      setSkills(updatedSkills);
    }
  };

  const updateSkillRating = (skillLabel, newValue) => {
    if (!!newValue) {
      const updatedSkills = skills.map(skill => ({ ...skill }));
      const skill = updatedSkills.find(skill => skill.label === skillLabel);
      skill.rating = newValue;
      setSkills(updatedSkills);
    }
  };

  const deleteSkill = skillLabel => {
    const updatedSkills = skills.filter(skill => skill.label !== skillLabel);
    setSkills(updatedSkills);
  };

  return (
    <FormContainer width={isEditMode ? "80%" : "40%"}>
      {
        !isEditMode ?
          <CustomTitle>Compétences</CustomTitle>
          : null
      }
                        <SmallTitle >Outils et métiers</SmallTitle>
      <SkillsContainer>
        {skills.length === 0 ? (
          <ExampleSkill>
            <p>Exemple : Java JEE</p>
            <Rating
              value={4}
              defaultValue={3}
              icon={<Image width="30px" height="30px" src="/assets/icons/banane.png" />}
              emptyIcon={<Image width="30px" height="30px" src="/assets/icons/banane-empty.png" />}
              size="large"
              readOnly
            />
          </ExampleSkill>
        ) : null}
        {skills.map(skill => (
          <Skill key={skill.label}>
            <p>{skill.label}</p>
            <LightTooltip title={tooltipLabel} placement="top">
              <Rating
                size="large"
                value={skill.rating}
                onChange={(_, newValue) => {
                  updateSkillRating(skill.label, newValue);
                }}
                icon={<Image width="30px" height="30px" src="/assets/icons/banane.png" />}
                emptyIcon={<Image width="30px" height="30px" src="/assets/icons/banane-empty.png" />}
                onChangeActive={(_, newHover) => {
                  if ([1, 2, 3, 4, 5].includes(newHover)) {
                    setTooltipLabel(tooltipTexts[newHover]);
                  }
                }}
              />
            </LightTooltip>
            <IconButton size="small" onClick={() => deleteSkill(skill.label)}>
              <Close fontSize="inherit" />
            </IconButton>
          </Skill>
        ))}
      </SkillsContainer>
      <AddAutocomplete
        data={loadedSkills.map(skill => ({ label: skill, id: skill }))}
        onClickHandler={addSkill}
        placeholder="Compétences"
        style={{ width: '100%' }}
      />
      {
        !isEditMode ?
          <RoutingButtons
            handleNext={handleValidationAndNext}
            handlePrevious={handlePrevious}
          />
          : null
      }
      {showErrors && skills.length === 0 &&
        <Alert severity="error">Veuillez ajouter au moins une compétence.</Alert>
      }
    </FormContainer>
  );
}

const SkillsContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 20px 0px;
  }
`;

const Skill = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    color: #333;
    font-size: 18px;
    font-family: "Prompt";
  }

  & > * {
    margin: 6px 0px;
  }
`;

const ExampleSkill = styled(Skill)`
  & > p {
    font-family: "Prompt";
    color: #B2B2B2;
  }
`;

const SmallTitle = styled.p`
  color: #241f47;
  font-size: 16px;
  font-family: "Prompt";
        transform: translate(0px, -10px);
`;