/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import Button from "../buttons/button.component";

const Wrapper = styled.div`
	padding-bottom: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
`;

const ButtonWrapper = styled.div`
	margin-left: 10px;
`;

export default function AddValueAutocomplete({ data, onClickHandler, placeholder, style }) {
	const [value, setValue] = useState("");
	const [inputValue, setInputValue] = useState("");

	const onClick = () => {
		if (value || inputValue) {
			if (value) {
				onClickHandler(value.id);
			}
			else {
				onClickHandler(inputValue);
			}
			setValue("");
			setInputValue("");
		}
	};
	const onChange = (event, newValue) => {
		setValue(newValue);
	};

	const pressKeyHandler = (event) => {
		if (event.keyCode === 13) {
			onClick();
		}
	}

	return (
		<Wrapper style={style}>
			<Autocomplete
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				noOptionsText="Vide"
				clearOnBlur={false}
				freeSolo
				value={value}
				onChange={onChange}
				options={inputValue.length >= 2 ? data : []}
				getOptionLabel={option => option.label || ""}
				fullWidth
				renderInput={params => <TextField {...params} placeholder={placeholder} onKeyDown={pressKeyHandler} />}
				style={{ borderWidth: 0 }}
        disableClearable
			/>
      <IconButton onClick={onClick}>
        <Add />
      </IconButton>
		</Wrapper>
	);
}
