import React from "react";
import styled from "styled-components";

export const KeyValue = ({ name, children }) => {
    if (children === undefined) return null;
    return (
      <div className="grey">{name} : <span className="value">{children}</span></div>
    );
  }

export default function ProfileCard({ nameSpace, item, itemTitle, titleShouldSpanTwoLines, itemInfo, itemSidebar, itemBottom, borderless, fixed, style, topClickable, bottomClickable, onClick, greyOut }) {

  const ClickableArea = ({ children, activated, style }) => {
    if (!activated) return children;
    return (
      <div
        style={{ width: '100%', height: '100%', cursor: 'pointer', ...style }}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }

  return (
    <MainWrapper
      borderless={borderless}
      fixed={ fixed ?? false }
      style={ style }
      greyOut={ greyOut }
    >
      <div style={{ width: '100%', position: 'relative' }}>
        { greyOut && <div style={{ backgroundColor: '#ccc', opacity: '40%', width: '100%', yIndex: 10, position: 'absolute' }}></div> }
        <TopContainer>
          <ProfileCardContent sidebar={ itemSidebar }>
            <ClickableArea
              activated={ topClickable }
            >
              <TitleWrapper titleShouldSpanTwoLines={ titleShouldSpanTwoLines }>
                { itemTitle }
              </TitleWrapper>
              <ProfileCardInfo>
                { itemInfo }
              </ProfileCardInfo>
            </ClickableArea>
          </ProfileCardContent>
          { itemSidebar &&
            <Sidebar>
            { itemSidebar }
            </Sidebar>
          }
        </TopContainer>
      { itemBottom &&
        <ClickableArea
          activated={ bottomClickable }
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: 1 }}
        >
          <Bottom>
            { itemBottom }
          </Bottom>
        </ClickableArea>
        }
      </div>
    </MainWrapper>
  );
}


export const MainWrapper = styled.div`
  width: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
  transition: all 200ms ease-out;
  box-shadow: ${props => props.borderless ? 'initial' : '2px 2px 10px 0px rgba(0,0,0,0.3)'};
  border-radius: ${props => props.borderless ? '0px': '40px'};

  &:hover {
    transform: ${props => props.fixed ? 'initial' : 'translateY(-6px)'};
    box-shadow: ${props => props.borderless ? 'initial' : '2px 2px 10px rgba(0,0,0,0.3)'};
  }
`;

const TopContainer = styled.div`
  width: 100%;
  flex: 1;
  flex-grow: 0;
  flex-direction: row;
  display: flex;
  position: relative;
`

const TitleWrapper = styled.div`
  font-family: "Prompt";
  font-size: 13px;
  color: #333;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;

  & .name {
    font-size: 16px;
    color: #241f47;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .title {
    font-size: 27px;
    text-transform: uppercase;
    font-weight: 600;
    color: #7978a8;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.titleShouldSpanTwoLines ? '2' : '1'};
    -webkit-box-orient: vertical;
  }
`
const ProfileCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  gap: 0px;
  font-family: "Prompt";
  font-size: 13px;
  color: #333;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;

  & .reference {
    margin-right: 40px;
    color: #7978a8;
  }

  & .grey {
    color: #918fa3;
  }

  & .value {
    color: #241f47;
  }

  & > * {
    width: 100%;
  }
`;

const Bottom = styled.div`
  cursor: ${props => props.bottomClickable ? 'pointer' : 'initial'};
  width: '100%';
  padding: 0px 20px;
  padding-bottom: 20px;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
`

const ProfileCardContent = styled.div`
  width: ${props => props.sidebar ? 'calc(100% - 70px)' : '100%'};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: ${props => props.topClickable ? 'pointer' : 'initial'};

  & > * {
    width: 100%;
  }
`;
