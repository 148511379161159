import React, { useState, useEffect } from 'react';
import { FormHelperText } from "@material-ui/core";

import RoutingButtons from "../shared/routing-buttons.component";
import YesNoButtons from "../shared/yes-no-buttons.component";
import Select from "../../../../shared/components/inputs/select-input.component";
import { SmallTitle, SelectContainers } from "../shared/styled-components-library";
import { studyLevelOptions, experienceYearsOptions, englishLevelOptions } from "../../../../constants/select-options.constants";
import { fetchAllEmpowerments } from '../../../../shared/utils/fetch.utils';
import AutocompleteWithChips from "../../../../shared/components/autocomplete-with-chips.component";

export default function StudiesForm({ studyLevel, setStudyLevel, experienceYears, setExperienceYears, englishLevel, setEnglishLevel, hasEmpowerment, setHasEmpowerment, empowerments, setEmpowerments, handlePrevious, handleNext, isFreelance, isEditMode }) {
	const [showErrors, setShowErrors] = useState(false);
        const [loadedEmpowerments, setLoadedEmpowerments] = useState([]);
        useEffect(() => {
          fetchAllEmpowerments((_empowerments) => setLoadedEmpowerments(_empowerments));
        }, []);

	const addEmpowerment = (inputValue) => {
		const value = inputValue.trim();
		const alreadyAdded = empowerments.find(empowerment => empowerment === value);
		if (!alreadyAdded && !!value.trim()) {
			const updatedEmpowerments = empowerments.concat([value]);
			setEmpowerments(updatedEmpowerments);
		}
	}

	const deleteEmpowerment = (label) => {
		const updatedEmpowerments = empowerments.filter(emp => emp !== label);
		setEmpowerments(updatedEmpowerments);
	}

	const handleValidationAndNext = () => {
		const hasErrors = studyLevel === "" || experienceYears === "" || englishLevel === "" || (hasEmpowerment && !empowerments.length);
		if (hasErrors) {
			setShowErrors(true);
		} else {
			handleNext();
		}
	}

	return (
		<>
			<SelectContainers>
				<Select options={studyLevelOptions} value={studyLevel} onChangeHandler={(e) => setStudyLevel(e.target.value)} label="Niveau d'étude *" error={showErrors && !studyLevel} errorText="Veuillez sélectionner une option." />
				<Select options={experienceYearsOptions} value={experienceYears} onChangeHandler={(e) => setExperienceYears(e.target.value)} label="Nombre d'années d'expérience *" error={showErrors && !experienceYears} errorText="Veuillez sélectionner une option." />
				<Select options={englishLevelOptions} value={englishLevel} onChangeHandler={(e) => setEnglishLevel(e.target.value)} label="Pratique de l'anglais *" error={showErrors && !englishLevel} errorText="Veuillez sélectionner une option." />
			</SelectContainers>
			<SmallTitle>{isFreelance ? "Avez-vous" : "L'interco a-t-il"} une habilitation ?</SmallTitle>
			<YesNoButtons style={{ marginBottom: "50px" }} isChoicePositive={hasEmpowerment} setChoice={setHasEmpowerment} />
			{
				hasEmpowerment ?
					<>
                                                <AutocompleteWithChips
                                                        addData={addEmpowerment}
                                                        deleteData={deleteEmpowerment}
                                                        dataChoice={loadedEmpowerments.map(empowerment => ({ label: empowerment, id: empowerment }))}
                                                        data={empowerments}
                                                        setData={setEmpowerments}
                                                        placeholder="Lesquelles ?"
                                                />
						{showErrors && (hasEmpowerment && !empowerments.length) &&
							<FormHelperText error>
								Veuillez indiquer au moins une habilitation.
							</FormHelperText>
						}
					</>
					: null
			}
			{
				!isEditMode ?
					<RoutingButtons handlePrevious={handlePrevious} handleNext={handleValidationAndNext} />
					: null
			}
		</>
	);
}