import React, { useState } from "react";
import styled from "styled-components";

import Stepper from "../../../shared/components/stepper.component";
import Select from "../../../shared/components/inputs/select-input.component";
import RoutingButtons from "./routing-buttons.component";
import TextInput from "../../../shared/components/inputs/text-input.component";
import Autocomplete from "../../../shared/components/inputs/autocomplete-input.component";
import { isValid } from "../../../shared/utils/input-validation.utils";
import { genderOptions } from "../../../constants/select-options.constants";
import PhoneInput from "../../../shared/components/inputs/phone-input.component";
import AutocompleteComponent from "../../../shared/components/inputs/autocomplete-input.component";
import { jobOptions } from "../../../constants/select-options.constants";

export default ({
	stepperLabels,
	activeStep,
	completedSteps,
	setCompletedSteps,
	userProfile,
	userGender,
	userLastname,
	userFirstname,
	userPhoneNumber,
	userJobTitle,
	isJoiningCompany,
	companiesOptions,
	joinedCompany,
	setJoinedCompany,
	setUserGender,
	setUserLastname,
	setUserFirstname,
	setUserPhoneNumber,
	setUserJobTitle,
	handleNext,
	handlePrevious,
	handleStep
}) => {
	const [showErrors, setShowErrors] = useState(false);
	const [isPhoneValid, setIsPhoneValid] = useState(false);

	const handleValidationAndNext = () => {
		const hasErrors =
			!userGender ||
			(!userFirstname.trim() || !isValid("text", userFirstname)) ||
			(!userLastname.trim() || !isValid("text", userLastname)) ||
			!isPhoneValid ||
			(isJoiningCompany && joinedCompany === null) ||
			(userProfile !== "freelance" && !userJobTitle);
		if (hasErrors) {
			setShowErrors(true);
		} else {
			setCompletedSteps(completedSteps => ({
				...completedSteps,
				[activeStep]: true
			}));
			handleNext();
		}
	};

	return (
		<>
			<Stepper
				labels={stepperLabels}
				activeStep={activeStep}
				handleStep={handleStep}
				completedSteps={completedSteps}
				style={{ marginLeft: '-10%' }}
				type="pink"
			/>
			<UserFormContainer>
				{isJoiningCompany ? (
					<AutocompleteContainer>
						<Autocomplete
							value={joinedCompany}
							options={companiesOptions}
							noOptionsLabel="Pas de société reconnue"
							placeholder="Société"
							onChange={(_, value) => setJoinedCompany(value)}
							error={showErrors && isJoiningCompany && joinedCompany === null}
							errorText="Veuillez sélectionner une société à rejoindre."
							required
						/>
					</AutocompleteContainer>
				) : null}
                                  <Select
                                          options={genderOptions}
                                          label="Genre"
                                          value={userGender}
                                          onChangeHandler={e => {
                                                  setUserGender(e.target.value);
                                          }}
                                          error={showErrors && !userGender}
                                          errorText="Veuillez sélectionner une option."
                                          required
                                  />
				<UserNamesGroup>
					<TextInput
						value={userLastname}
						onChangeHandler={e => setUserLastname(e.target.value)}
						label="Nom"
						error={
							showErrors &&
							(!userLastname.trim() || !isValid("text", userLastname))
						}
						errorText="Le nom est obligatoire et ne peut que contenir que des lettres."
						required
					/>
					<TextInput
						value={userFirstname}
						onChangeHandler={e => setUserFirstname(e.target.value)}
						label="Prénom"
						error={
							showErrors &&
							(!userFirstname.trim() || !isValid("text", userFirstname))
						}
						errorText="Le prénom est obligatoire et ne peut que contenir que des lettres."
						required
					/>
				</UserNamesGroup>
				<PhoneInput
					id="phone-number"
					value={userPhoneNumber}
					onChangeHandler={setUserPhoneNumber}
					validationHandler={setIsPhoneValid}
					placeholder="Numéro de téléphone"
					error={showErrors && !isPhoneValid}
					errorText="Le format du numéro entré n'est pas valide."
					required
				/>
				{userProfile !== "freelance" ? (
                                  <Select
                                          value={userJobTitle}
                                          options={jobOptions}
                                          label="Fontion dans la société"
                                          onChangeHandler={e => setUserJobTitle(e.target.value)}
                                          error={
                                            showErrors && userProfile === "company" && !userJobTitle
                                          }
                                          errorText="Ce champ est obligatoire."
                                          required
                                  />
				) : null}
				<RoutingButtons
					handleNext={handleValidationAndNext}
					handlePrevious={handlePrevious}
				/>
			</UserFormContainer>
		</>
	);
};

const AutocompleteContainer = styled.div`
	width: 100%;
	margin-bottom: 24px;
`;

const UserFormContainer = styled.div`
	margin-top: 40px;

	@media screen and (min-width: 520px) {
		width: 80%;
	}
`;

const UserNamesGroup = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	& > * {
		width: 48%;
		margin-bottom: 24px;
	}
`;
