import { useState } from "react";
import { BigTitle } from "../../../shared/utils/styled-components-library.utils";
import styled from "styled-components";
import { Paragraph, MediumTitle } from "../../../shared/utils/styled-components-library.utils";
import TextInput from "../../../shared/components/inputs/text-input.component";
import Button from "../../../shared/components/buttons/button.component";
import {CircularProgress} from "@material-ui/core";
import { post } from "../../../shared/utils/api.utils";
import { Alert } from "@material-ui/lab";
import { AlertWrapper } from "../../modals/delete-user.modal";

export default function Referral() {
	const [showErrors, setShowErrors] = useState(false);
	const [isSending, setIsSending] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: null, message: '' });

  const sendMail = (e) => {
    e.preventDefault();
    setShowErrors(false);
    setAlert({ show: false, type: null, message: '' });
    setIsSending(true);
    const email = e.target.email.value;
    e.target.reset();
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setShowErrors(true);
      setIsSending(false);
      return;
    }
    post('/misc/referral', { email })
      .then(() => {
        setAlert({ show: true, type: 'success', message: 'Votre mail a bien été envoyé.' });
      })
      .catch(() => {
        setAlert({ show: true, type: 'error', message: 'Une erreur est survenue, veuillez réessayer.' });
      })
      .finally(() => {
        setIsSending(false);
      });
  }


  return (
		<MainWrapper>
			<BigTitle>PARRAINER</BigTitle>
      <BigCard>
        <MediumTitle>L'union fait la force !</MediumTitle>
        <Paragraph>
          Vous êtes satisfait de la plateforme ? Partagez la plateforme à votre réseau !
        </Paragraph>
        <Form onSubmit={sendMail}>
          <TextInput
            name="email"
            label="Adresse mail de votre contact"
            error={showErrors}
            errorText="L'adresse e-mail entrée n'est pas valide."
          />
          { isSending
            ?
              <CircularProgress />
            :
              <Button
                type="submit"
                secondary
                disabled={isSending}
              >
                Envoyer
              </Button>
          }
        </Form>
        { alert.show && <AlertWrapper>
          <Alert severity={alert.type}>{alert.message}</Alert>
        </AlertWrapper>
        }
      </BigCard>
		</MainWrapper>
  );
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
  align-items: center;
`;

const BigCard = styled.div`
  width: 100%;
  box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.3);
	border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px;
`;

const Form = styled.form`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
