import styled from "styled-components";
import { Checkbox, withStyles } from "@material-ui/core";

export const FormContainer = styled.div`
	margin: 40px 0px;
	width: ${props => props.width ? props.width : "50%"};
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	border-radius: 40px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	padding: 3em;
`;

export const CustomTitle = styled.h3`
	font-family: "Prompt Semi Bold";
	font-size: 32px;
	font-weight: normal;
	color: #241f47;
`;

export const SmallTitle = styled.p`
	color: #241f47;
	font-size: 16px;
	font-family: "Prompt";
`;

export const CustomCheckbox = withStyles({
	colorSecondary: {
		color: "#F2B6BC",
		'&$checked': {
			color: "#F2B6BC",
		},
	},
	checked: {} // permet d'override la couleur par défaut
})(Checkbox);

export const SelectContainers = styled.div`
	margin-top: 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: left;

	& > * {
		margin-bottom: 15px;
	}
`;

/* ****************************************************************************************** */
/* Composants spécifiques aux formulaires mais réutiliser dans le modal d'édition d'un profil */

export const MultiSelectWrapper = styled.div`
	margin-bottom: 24px;
`;

export const CustomLabel = styled.label`
	font-family: "Prompt Light";
	font-size: 16px;
	color: #241f47;
`;

export const NamesContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	
	& > * {
		margin: 10px 0px;
	}
`;