import { useState, useEffect, useMemo } from "react";
import { CircularProgress } from "@material-ui/core";
import ModalTemplate from "../../shared/components/modal-template.component";
import { AlertWrapper, CustomTitle } from './delete-user.modal';
import { Alert } from "@material-ui/lab";
import { get } from "../../shared/utils/api.utils";
import ProfileCard from "../components/personal-account/profiles/profile-card.component";
import { ProfilesWrapper } from "../components/personal-account/shared/item-container.component";
import Button from "../../shared/components/buttons/button.component";
import ModalButtonContainer from "../../shared/components/buttons/modal-button-container.component";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Pagination from "../../shared/components/lists/pagination.component";
import SkillCard from "../../shared/components/skill-card.component";

const noMatchMessage = (user, nameSpace) => {
  if (nameSpace !== 'needs')
    return `${user.profile === 'freelance' ? "Vous n'avez" : "Cet interco n'a"} pas de besoin suggéré automatiquement`;
  return "Ce besoin n'a pas d'interco suggéré automatiquement";
}

export default function MatchItemModal(props) {
  const location = useLocation();
  const { item, nameSpace, ...modalProps } = props;
  const history = useHistory();
  const [alert, setAlert] = useState({ show: false, type: null, message: '' });
  const [ matchingSubItems, setMatchingSubItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);

  const subItemNameSpace = nameSpace === 'needs' ? 'profiles' : 'needs';

  /* Pagination */
  const handlePageChange = (_, value) => {
    setPage(value);
    const params = new URLSearchParams(location.search);
    params.set("page", value);
    history.push({
      pathname: location.pathname,
      search: '?' + params.toString()
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialPage = params.get("page");
    if (initialPage) {
      setPage(parseInt(initialPage));
    } else {
      setPage(1);
    }
  }, []);

  const displayedSubItems = matchingSubItems.slice((page - 1) * 16, page * 16);

  const pagesCount = useMemo(() => Math.ceil(matchingSubItems.length / 16), [
    matchingSubItems
  ]);

  const itemsPerPage = useMemo(() => [60], []);
  /* End of pagination */

  useEffect(() => {
    if (!modalProps.showModal) return;
    setAlert({ show: false, type: null, message: null });
    const aggregatedSubitems = [];
    setLoading(true);
    get(`/${nameSpace}/${item.hash}/matching?size=32`).then(async(response) => {
      if (response.matching) {
        try {
          for (const { hash, score } of response.matching) {
            const subItemQuery = await get(`/${subItemNameSpace}/${hash}`);
            aggregatedSubitems.push({ profile: subItemQuery.profile, score: Math.ceil(score)});
          }
          setMatchingSubItems(aggregatedSubitems);
          if (aggregatedSubitems.length === 0)
              setAlert({ show: true, type: "info", message: noMatchMessage(props.user) });
              setLoading(false);
        } catch (error) {
            setAlert({ show: true, type: "error", message: "Une erreur est survenue lors du matching, veuillez réessayer ultérieurement." });
          setLoading(false);
        }
      } else {
        setAlert({ show: true, type: "error", message: "Une erreur est survenue lors du matching, veuillez réessayer ultérieurement." });
        setLoading(false);
      }
    });
  }, [item, modalProps.showModal]);

  return (
    <ModalTemplate
      width="90%"
      height="90%"
      {...modalProps}
      closeModal={() => {
        modalProps.closeModal();
        history.replace({ search: `onglet=${nameSpace==='needs' ? 'besoins' : 'intercos'}` }); 
        setAlert({ show: false, type: null, message: null });
      }}
    >
      <CustomTitle>
        { nameSpace === 'needs' ? 'PROFILS' : 'BESOINS' } SUGGÉRÉS
      </CustomTitle>
      { loading
        ? <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div>
        : 
        <>
          <ProfilesWrapper>
            { displayedSubItems.map((subItem) => (
                <ProfileCard
                  topClickable
                  bottomClickable
                  key={subItem.hash}
                  profile={subItem.profile}
                  nameSpace={subItemNameSpace}
                  sidebar={<Score><span className="grey">Score:</span> {subItem.score}</Score>}
                  isPrivate={false}
                  bottom={<SkillCard profile={subItem.profile} nameSpace={subItemNameSpace} />}
                />
              ))
            }
            </ProfilesWrapper>
            { page && (
                <Pagination
                  handlePageChange={handlePageChange}
                  items={itemsPerPage}
                  page={page}
                  pagesCount={pagesCount}
                  rowPerPage={16}
                />
              )
            }
        </>
      }
      { alert.show && <AlertWrapper>
        <Alert severity={alert.type}>{alert.message}</Alert>
      </AlertWrapper>
      }
      <ModalButtonContainer>
        <Button
          outlineButton
          onClick={() => {
            history.replace({ search: `onglet=${nameSpace==='needs' ? 'besoins' : 'intercos'}` }); 
            modalProps.closeModal();
          }}
        >
          Fermer
        </Button>
      </ModalButtonContainer>
    </ModalTemplate>
  );
}

const Score = styled.div`
	font-family: "Prompt";
	font-size: 13px;
	& .grey {
		color: #918fa3;
	}
`;
