import { get } from "./api.utils";

export const uploadFile = async file => {
	// on récupère d'abord l'URL du bucket S3
	let type = file.type;
	const { url } = await get(`/files/s3Url?type=${type}`);

	// puis on upload le fichier grâce à une requête PUT
	const headersContent = {
		"Content-Type": file.type,
		Accept: "application/json"
	};
	const headers = new Headers(headersContent);
	const response = await fetch(
		new Request(url, {
			method: "PUT",
			body: file,
			headers
		})
	);
	const filename =
		response && response.url
			? response.url
					.split("?")[0]
					.replace(
						"https://1terco-uploads.s3.eu-west-3.amazonaws.com/userUploads/",
						""
					)
			: "";
	return filename;
};
