import React, { useState, useEffect, useContext } from 'react';

import { FormContainer } from "../createinterco/shared/styled-components-library";

import AnonymousForm from "../createinterco/subforms/anonymous-form.component";
import DurationForm from "../createinterco/subforms/duration-form.component";
import MiscellaneousForm from "./subforms/miscellaneous-form.component";

export default function InfoForm(props) {
  const { subStep, setSubStep } = props;
  const [formData, setFormData] = useContext(props.context);

	// 1er sous-formulaire
	const [anonymous, setAnonymous] = useState(false);

	// 2ème sous-formulaire
	const [title, setTitle] = useState("");
	const [fieldIds, setFieldIds] = useState([]);
	const [availability, setAvailability] = useState("");
	const [duration, setDuration] = useState("");
	const [isRenewable, setIsRenewable] = useState(false);

	// 3ème sous-formulaire
	const [workLocations, setWorkLocations] = useState([]);
	const [workFormat, setWorkFormat] = useState("");
	const [experienceYears, setExperienceYears] = useState("");
	const [englishLevel, setEnglishLevel] = useState("");
        const [hasEmpowerment, setHasEmpowerment] = useState(false);
        const [empowerments, setEmpowerments] = useState([]);

	useEffect(() => {
		loadFormData();
	}, []); // eslint-disable-line

	const loadFormData = () => {
		if (formData && formData.infoForm) {
			const data = formData.infoForm;
			setAnonymous(data.anonymous);
			setTitle(data.title);
			setFieldIds(data.fieldIds);
			setAvailability(data.availability);
			setDuration(data.duration);
			setIsRenewable(data.isRenewable);
			setWorkLocations(data.workLocations);
			setWorkFormat(data.workFormat);
			setExperienceYears(data.experienceYears);
			setEnglishLevel(data.englishLevel);
                        setHasEmpowerment(data.hasEmpowerment);
                        setEmpowerments(data.empowerments);
		}
	}

	/**
	 * Fonction permettant de sauvegarder dans le contexte les informations relatives à cette partie du formulaire.
	 */
	const saveFormData = () => {
		setFormData(formData => ({
			...formData,
			infoForm: {
				anonymous,
				title,
				fieldIds,
				availability,
				duration,
				isRenewable,
				workLocations,
				workFormat,
				englishLevel,
				experienceYears,
                                hasEmpowerment,
                                empowerments,
			}
		}));
	}

	const handleNext = () => {
		if (subStep === 2) {
			props.setCompletedSteps(completedSteps => ({ ...completedSteps, [props.activeStep]: true }));
			saveFormData();
			props.handleNext();
		} else {
			setSubStep(formerStep => formerStep + 1);
		}
		window.scrollTo(0, 0);
	}

	const handlePrevious = () => {
	        setSubStep(formerStep => formerStep - 1);
		window.scrollTo(0, 0);
	}

	const renderCurrentSubForm = (activeSubStep) => {
		const commonSubProps = { handleNext, handlePrevious, isFreelance: props.isFreelance, openSignInModal: props.openSignInModal };
		switch (activeSubStep) {
			case 0:
                          return <AnonymousForm
                              {...commonSubProps}
                              anonymous={anonymous}
                              setAnonymous={setAnonymous}
                              paragraph={
                                <span>Si besoin anonyme, les informations de votre société et vos coordonnées ne seront pas visibles</span>
                              }
                            />;
                        case 1:
                          return <DurationForm
                              {...commonSubProps}
                              fields={props.fields}
                              fieldIds={fieldIds}
                              setFieldIds={setFieldIds}
                              jobTitle={title}
                              setJobTitle={setTitle}
                              availability={availability}
                              setAvailability={setAvailability}
                              duration={duration}
                              setDuration={setDuration}
                              isRenewable={isRenewable}
                              setIsRenewable={setIsRenewable}
                              title={'Informations besoin'} 
                              jobTitlePlaceholder="Titre de la mission"
                              timePlaceholder="Dans combien de temps la mission doit démarrer ?"
                              durationPlaceholder="Quelle est la durée de la mission ?"
                            />;
			case 2:
                          return <MiscellaneousForm
                              {...commonSubProps}
                              workLocations={workLocations}
                              setWorkLocations={setWorkLocations}
                              workFormat={workFormat}
                              setWorkFormat={setWorkFormat}
                              experienceYears={experienceYears}
                              setExperienceYears={setExperienceYears}
                              englishLevel={englishLevel}
                              setEnglishLevel={setEnglishLevel}
                              hasEmpowerment={hasEmpowerment}
                              setHasEmpowerment={setHasEmpowerment}
                              empowerments={empowerments}
                              setEmpowerments={setEmpowerments}
                            />;
			default: return null;
		}
	}

	return (
		<FormContainer>
			{renderCurrentSubForm(subStep)}
		</FormContainer >
	);
}