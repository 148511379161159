export const getLogo = (nameSpace, item, userProfile, permissions, big = false) => {
  let logo = "";
  let bigPrefix = big ? "big_" : "";
  if (nameSpace === 'needs') {
    if (item.anonymous) {
      if (permissions?.ownerContact) {
        return item.companyLogo ? `https://1terco-uploads.s3.eu-west-3.amazonaws.com/userUploads/${item.companyLogo}` : "/assets/icons/need_logo_anonymous.png"
      } else
        return "/assets/icons/need_logo_anonymous.png";
    }
    return item.companyLogo ? `https://1terco-uploads.s3.eu-west-3.amazonaws.com/userUploads/${item.companyLogo}` : "/assets/icons/need_logo_undefined.png"
  } else {
    logo = item.anonymous
          ? `/assets/icons/${bigPrefix}avatar_anonymous.png`
          : userProfile === "freelance"
          ? `/assets/icons/${bigPrefix}avatar_freelance.png`
          : `/assets/icons/${bigPrefix}avatar_company.png`
  }
  return logo;
}
