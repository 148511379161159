import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Alert } from "@material-ui/lab";

import { ReactComponent as BinIcon } from "../../../svg-assets/poubelle.svg";
import { BigTitle } from "../../../shared/utils/styled-components-library.utils";
import { ActionButton } from "./shared/styled-components.library";
import { post } from "../../../shared/utils/api.utils";

export default function ProfilePage({ user, setUser, deleteUser }) {
	const toggleUserActivation = (manager) => {
		const newActivity = !manager.accountActivated;
		post("/users/update-user-activation", { userId: manager.userId, newActivity }).then(response => {
			if (response && response.success) {
				setUser(user => ({
					...user,
					users: user.users.map(_manager => _manager.userId === manager.userId ? { ..._manager, accountActivated: !_manager.accountActivated } : _manager)
				}));
			}
		});
	}

	return (
		<MainWrapper>
			<BigTitle>GÉRER LES UTILISATEURS</BigTitle>
			<UsersWrapper>
				{
					user.users && user.users.length > 0 ?
						<>
							{
								user.users.map(manager => (
									<UserCard key={manager.userId}>
										<UserInfoWrapper>
											<div className="name">{manager.firstname} {manager.lastname.toUpperCase()}</div>
											<div className="date">Utilisateur créé le {moment(manager.createdAt).format("DD/MM/YYYY")}</div>
											<div className="stats">{manager.profileCount} intercontrat(s) consulté(s) {manager.profileViewCount} fois par les autres tribus</div>
										</UserInfoWrapper>
										<div>
											<ActionButton onClick={() => toggleUserActivation(manager)}>
												{manager.accountActivated ? "Suspendre" : "Activer"}
											</ActionButton>
										</div>
										<DeleteWrapper onClick={() => deleteUser(manager)}>
											<BinIcon />
										</DeleteWrapper>
									</UserCard>
								))
							}
						</>
						: <Alert severity="warning">Il n'y a pas d'utilisateurs rattachés à votre compte.</Alert>
				}

			</UsersWrapper>
		</MainWrapper>
	);
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const UsersWrapper = styled.div`
	width: 96%;
	margin: 2% auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: white;
	box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
	border-radius: 70px;
	padding: 4em 2em;
	justify-content: center;
`;

const UserCard = styled.div`
	width: 310px;
	/* height: 100%; */
	min-height: 250px;
	margin: 10px 12px;
	background-color: #FFF;
	font-family: "Prompt";
	font-size: 16px;
	border-radius: 15px;
	padding: 20px 15px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	transition: all 300ms ease-out;

	box-shadow: 1px 2px 5px rgba(0,0,0,0.3);

	&:hover {
		transform: translateY(-6px);
		box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
	}
`;

const UserInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	line-height: 17px;

	& .name {
		color: #241f47;
	}
	& .date {
		color: #241f47;
		margin-top: 8px;
	}
	& .stats {
		font-size: 14px;
		color: #7978a8;
		margin-top: 16px;
		text-align: center;
	}
`;

const DeleteWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	cursor: pointer;

	&:hover {
		& svg > g > g {
			fill: #f296ac;
		}
	}
`;