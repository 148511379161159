import { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import ModalTemplate from "../../shared/components/modal-template.component";
import { Alert } from "@material-ui/lab";
import { get } from "../../shared/utils/api.utils";
import Column from './manage-item/column';
import ProfileCard from "../../specific/components/personal-account/profiles/profile-card.component";
import Button from "../../shared/components/buttons/button.component";
import { updateNeed } from "../../shared/utils/needs.utils";  
import { deleteReq } from "../../shared/utils/api.utils";
import ModalButtonContainer from "../../shared/components/buttons/modal-button-container.component";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";

/* This page is used to manage a need or a profile:
 * - if it's a need, it will display the candidates
 * - if it's a profile, it will display the needs to which it is a candidate
 */

const noCandidateMessage = (user, nameSpace) => {
  if (nameSpace !== 'needs')
    return `${user.profile === 'freelance' ? "Vous ne vous êtes" : "Cet interco n'a été"} positionné(e) sur aucun besoin`;
  return "Ce besoin n'a pas encore reçu de candidatures";
}

export default function ManageItemModal(props) {
  // if frozen is true, the buttons are disabled
  const { item, nameSpace, setShouldRefreshUser, ...modalProps } = props;
  const history = useHistory();
  const [ newSubItems, setNewSubItems] = useState([]);
  const [ initialSubItems, setInitialSubItems] = useState([]);
  const [alert, setAlert] = useState({ show: false, type: null, message: '' });
  const [loading, setLoading] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [needWon, setNeedWon] = useState(null);
  const [changeOccured, setChangeOccured] = useState(false);

  const subItemNameSpace = nameSpace === 'needs' ? 'profiles' : 'needs';

  const frozen = nameSpace === 'needs' && item.won;

  useEffect(() => {
    if (!modalProps.showModal) return;
    setAlert({ show: false, type: null, message: null });
    const aggregatedSubitems = [];
    get(`/${nameSpace}/${item.hash}/candidates`).then(async(response) => {
      if (response.candidates) {
        try {
          for (const candidate of response.candidates) {
            const subItemQuery = await get(`/${subItemNameSpace}/${candidate.hash}`);
            aggregatedSubitems.push({
              candidateAt: candidate.createdAt,
              status: candidate.status,
              ...subItemQuery.profile
            });
          }
          setInitialSubItems(aggregatedSubitems);
          setNewSubItems(aggregatedSubitems);
          if (aggregatedSubitems.length === 0)
              setAlert({ show: true, type: "info", message: noCandidateMessage(props.user, nameSpace) });
        } catch (error) {
            setAlert({ show: true, type: "error", message: "Une erreur est survenue lors de la récupération des candidatures, veuillez réessayer ultérieurement." });
        }
      } else {
        setAlert({ show: true, type: "error", message: "Une erreur est survenue lors de la récupération des candidatures, veuillez réessayer ultérieurement." });
      }
    });
  }, [item, modalProps.showModal]);

  const updateStatus = async() => {
    setLoading(true);
    if (nameSpace === 'profiles')
    {
      // delete all needs that are not present anymore
      for (let i = 0; i < initialSubItems.length; i++) {
        if (!newSubItems.find((subItem) => subItem.hash === initialSubItems[i].hash)) {
          await deleteReq('/candidates', { needId: initialSubItems[i].hash, profileHash: item.hash });
        }
      }
    }
    if (nameSpace === 'needs') {
      await updateNeed({ needHash: item.hash, changes: newSubItems });
    }
    setInitialSubItems(newSubItems);
    setLoading(false);
  }

  const bottom = (confirmMessage) => {
    // si un utilisateur a cliqué sur "gagné", on lui demande de confirmer en
    // lui montrant le profil restant
    const columns = ['waiting', 'refused', 'interesting', 'won'];
    return (
    <>
      { 
        <ColumnContainer>
          {
            columns.map((status) => (
              <Column
                nameSpace={ nameSpace === 'needs' ? 'profiles' : 'needs' }
                key={ status }
                subItemStatus={ status }
                subItems={ newSubItems } 
                setSubItems={ setNewSubItems }
                setConfirmMessage={ setConfirmMessage }
                frozen={ frozen }
                setNeedWon={ setNeedWon }
                setChangeOccured={ setChangeOccured }
                shouldGreyOut={ confirmMessage && status !== 'won'}
              />
            ))
          }
        </ColumnContainer>
      }
      { alert.show && <AlertWrapper>
        <Alert severity={alert.type}>{alert.message}</Alert>
      </AlertWrapper>
      }
    </>);
  };

  const returnWonNeedToPreviousStatus = () => {
    setNewSubItems(newSubItems.map((subItem) => {
      if (subItem.status === 'won') {
        return {
          ...subItem,
          status: needWon.previousStatus,
        }
      }
      return subItem;
    }));
    setNeedWon(null);
    setConfirmMessage(false);
  }

  return (
    <ModalTemplate
      width="90%"
      height="90%"
      {...modalProps}
      closeModal={() => {
        // If you are asked to confirm a win and close, the won
        // status is removed
        if (confirmMessage) {
          returnWonNeedToPreviousStatus();
        }
        modalProps.closeModal();
        if (changeOccured === true) {
          setShouldRefreshUser(true);
        }
        history.replace({ search: `onglet=${nameSpace==='needs' ? 'besoins' : 'intercos'}` }); 
        setAlert({ show: false, type: null, message: null });
      }}
    >
        {
          confirmMessage === true
          ? <>
              { bottom(true) }
              <AlertWrapper>
                <Alert severity="warning">Votre besoin va être clôturé. Toutes les candidatures sauf la candidature gagnante vont être refusées. Confirmez-vous ?</Alert>
              </AlertWrapper>
            </>
          :
            <ProfileCard
              isPrivate
              fixed
              borderless
              nameSpace={ nameSpace }
              profile={item}
              summary
              large
              bottom={bottom(false)}
              showStatus={false}
            />
        }
        {
          frozen === true &&
          <AlertWrapper>
            <Alert severity="info">Vous ne pouvez pas modifier les candidatures d'un besoin pourvu.</Alert>
          </AlertWrapper>
        }
        <ModalButtonContainer>
          { loading
            ? <CircularProgress />
            : <>
                <Button
                  outlineButton
                  onClick={() => {
                    // If you are asked to confirm a win and cancel, the won
                    // status is removed
                    if (confirmMessage) {
                      returnWonNeedToPreviousStatus();
                      return;
                    }
                    history.replace({ search: `onglet=${nameSpace==='needs' ? 'besoins' : 'intercos'}` }); 
                    setConfirmMessage(false);
                    if (changeOccured === true) {
                      setShouldRefreshUser(true);
                    }
                    modalProps.closeModal();
                  }}
                >
                  { frozen === true ? 'Fermer' : 'Annuler' }
                </Button>
                { frozen !== true && <Button
                    disabled={changeOccured === false}
                    secondary
                    onClick={() => {
                      updateStatus();
                      setChangeOccured(false);
                      if (confirmMessage) {
                        setConfirmMessage(false);
                        if (changeOccured === true) {
                          setShouldRefreshUser(true);
                        }
                        modalProps.closeModal();
                      }
                    }}
                  >
                    Confirmer
                  </Button>
                }
              </>
          }
        </ModalButtonContainer>
    </ModalTemplate>
  );
}

const ColumnContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
`

const AlertWrapper = styled.div`
	margin-top: 50px;
`;
