import React, { useState, useContext, useEffect } from 'react';
import styled from "styled-components";

import { FormContainer, CustomTitle } from "./shared/styled-components-library";
import RoutingButtons from "./shared/routing-buttons.component";
import TextInput from "../../../shared/components/inputs/text-input.component";

export default function WishesForm(props) {
	const { handleNext, setCompletedSteps, activeStep } = props;
	const [formData, setFormData] = useContext(props.context);
	const [wish, setWish] = useState("");

	useEffect(() => {
		loadFormData();
	}, []); // eslint-disable-line

	const loadFormData = () => {
		if (formData && formData.wishesForm) {
			setWish(formData.wishesForm.wish);
		}
	}

	const saveAndHandleNext = () => {
		setCompletedSteps(completedSteps => ({ ...completedSteps, [activeStep]: true }));
		saveFormData();
		handleNext();
	}

	const saveFormData = () => {
		setFormData(formData => ({
			...formData,
			wishesForm: {
				wish
			}
		}));
	}

	return (
		<WishesFormLayout
			{...props}
			wish={wish}
			setWish={setWish}
			saveAndHandleNext={saveAndHandleNext}
		/>
	);
}

export const WishesFormLayout = (props) => {
	const {
		wish,
		setWish,
		handlePrevious,
		saveAndHandleNext,
		isFreelance,
		isEditMode
	} = props;

	return (
		<FormContainer width={isEditMode ? "90%" : "50%"}>
			<CustomTitle>Quelques mots sur {isFreelance ? "vous" : "l'interco"}</CustomTitle>
			<WishInputContainer>
				<TextInput
					value={wish}
					onChangeHandler={(e) => setWish(e.target.value)}
					multiline
					placeholder={`Dites-nous en plus sur ${isFreelance ? "vous et vos souhaits" : "sa personnalité et ses souhaits"} (optionnel mais recommandé)`}
					rows={10}
					noMargin
				/>
			</WishInputContainer>
			{!isEditMode &&
				<RoutingButtons
					handleNext={saveAndHandleNext}
					handlePrevious={handlePrevious}
					nextStepButtonLabel={wish.trim() ? "Valider" : "Passer cette étape"}
				/>
			}
		</FormContainer>
	);
}

const WishInputContainer = styled.div`
	margin-top: 40px;
	width: 100%;
	background-color: #FFF;
`;