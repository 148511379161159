import React, { useEffect } from 'react';
import styled from "styled-components";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

export default function PhoneInput(props) {
    const inputId = props.id || Math.random().toString(36).substr(2, 10).replace([0-9], '');

    const handleChange = (e) => {
        const input = document.querySelector(`#${inputId}`);
        const ini = window.intlTelInputGlobals.getInstance(input);
        props.onChangeHandler && props.onChangeHandler(ini.getNumber());
        props.validationHandler && props.validationHandler(ini.isValidNumber());
    }

    useEffect(() => {
		const phoneInput = document.querySelector(`#${inputId}`);
		intlTelInput(phoneInput, {
            initialCountry: "fr",
            //separateDialCode: true,
			utilsScript: "/scripts/intlTelInput/utils.min.js"
		});

        phoneInput.addEventListener("countrychange", e => {
            handleChange(e)
        });
	}, []);

	return (
        <InputWrapper>
            <CustomInput
                id={inputId}
                noMargin={props.noMargin}
                placeholder={props.placeholder}
                value={props.value}
                onChange={handleChange}
                disabled={props.disabled}
                required={props.required}
                error={props.error}
            />
            {props.error &&
                <ErrorText>{props.errorText}</ErrorText>
            }
        </InputWrapper>
	);
}

const InputWrapper = styled.div`
	width: 100%;
	margin-bottom: ${props => props.noMargin ? "0px" : "24px"};

    & .iti {
        width: 100%;
    }

    & .iti__selected-flag {
        background-color: none;
        border-top-left-radius: 28px;
        border-bottom-left-radius: 28px;
    }
`;

const CustomInput = styled.input`
    font-family: "Prompt Light";
    padding: 15px 27px;
    border: 1px solid ${props => props.error ? "#f44336" : "#ffc4ce"};
	border-radius: 28px;
    width: 100%;
    color: #42387a;
    font-size: 16px;

    &:hover {
        border: 1px solid #f2a0ad;
    }

    &:focus {
        outline: none;
        border: 2px solid #f2a0ad;
    }
`;

const ErrorText = styled.span`
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.75rem;
    color: #f44336;
    padding-left: 20px;
`;