import Pagination from "../../../../shared/components/lists/pagination.component";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import SearchInput from "./search-input.component";

export default function ItemContainer({ items, renderCard, searchPlaceHolder, rowPerPage, setRowPerPage }) {

        // pagination
        const [page, setPage] = useState(1);
        const [search, setSearch] = useState("");

	const pages = useMemo(() => [4, 8, 16, 32], []);
	const pagesCount = useMemo(() => Math.ceil(items.length / rowPerPage), [items, rowPerPage]);

	useEffect(() => {
		setPage(1);
	}, [search, items, rowPerPage]);
	// recherche parmi le titre et la référence (i.e. le hash) du profil

	const handlePageChange = (_, value) => setPage(value);
	const handleRowPerPageChange = (event) => setRowPerPage(event.target.value);

  let displayedItems;
  if (!search.trim().length) {
    displayedItems= items;
  } else {
    const searchContent = search.toLowerCase();
    displayedItems = items.filter(item =>
      item.lastName?.toLowerCase().startsWith(searchContent)
      || item.firstName?.toLowerCase().startsWith(searchContent)
      || item.title?.toLowerCase().startsWith(searchContent)
      || item.hash.toLowerCase().startsWith(searchContent)
    );
  }
  displayedItems = displayedItems.slice(rowPerPage * (page - 1), rowPerPage * page);



  return (
    <>
      <SearchInput value={search} onChange={e => setSearch(e.target.value)} placeholder={ searchPlaceHolder }/>
      <ProfilesWrapper nbProfiles={displayedItems.length}>
        { displayedItems.map((profile) => renderCard(profile)) }
      </ProfilesWrapper>
			<Pagination
				items={pages}
				page={page}
				label="intercos"
				pagesCount={pagesCount}
				rowPerPage={rowPerPage}
				handlePageChange={handlePageChange}
				handleRowPerPageChange={handleRowPerPageChange}
			/>
    </>
);
}

export const ProfilesWrapper = styled.div`
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
      gap: 20px;
`;

