import React, { useState } from 'react';
import styled from "styled-components";
import { Input, withStyles } from "@material-ui/core";

function AddValueInput({ classes, placeholder, addValueHandler }) {
	const [inputValue, setInputValue] = useState("");

	const onClickHandler = () => {
		addValueHandler(inputValue);
		setInputValue("");
	}

	const pressKeyHandler = (event) => {
		if (event.keyCode === 13) {
			onClickHandler();
		}
	}

	return (
		<InputContainer>
			<Wrapper>
				<Input
					value={inputValue}
					onChange={e => setInputValue(e.target.value)}
					onKeyDown={pressKeyHandler}
					autoComplete="off"
					InputProps={{
						classes: {
							input: classes.resize,
						},
					}}
					name="name"
					placeholder={placeholder}
					className={classes.textField}
				/>
				<AddButton onClick={onClickHandler}>Ajouter</AddButton>
			</Wrapper>
		</InputContainer>
	)
}

const InputContainer = styled.div`
	width: 100%;
	margin: 20px 0px;
`;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;

	&& {
		& .MuiInput-root {
			flex: 1;
		}
	}
`;

const AddButton = styled.button`
	user-select: none;
	font-size: 14px;
	font-family: "Prompt";
	color: white;
	background-color: #241f47;
	border: none;
	border-radius: 100px;
	padding: 5px 10px;
	cursor: pointer;
	transition: all 0.25s ease;
	margin-left: 5px;

	&:hover {
		background-color: #2e2275;
	}

	&:focus {
		outline: none;
	}
`;

const styles = {
	textField: {
		fontSize: 24,
		fontFamily: "Prompt Light"
	},
	resize: {
		fontSize: 30
	},
}

export default withStyles(styles)(AddValueInput);