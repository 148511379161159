import React from 'react';
import styled from "styled-components";

export default function Image(props) {
  const finalWidth = props.size ? props.size : props.width ? props.width : '100px';
  const finalHeight = props.size ? props.size : props.height ? props.height : null;

  return (
    <ImageContainer
      onClick={props.onClick}
      borderRadius={ props.borderRadius }
    >
      {
        props.src
        ?
          <StyledImage
            width={finalWidth}x
            height={finalHeight}
            src={props.src}
            alt={props.alt || ""}
          />
        : <EmptyBloc />
      }
    </ImageContainer>
  );
}

const ImageContainer = styled.div`
  border-radius: ${props => props.borderRadius || "0px"};
`;

const StyledImage = styled.img`
  width: ${props => props.width};
  height: ${props => props.height ?? 'auto'};
  object-fit: contain;
`;

const EmptyBloc = styled.div`
  width: 100%;
  height: auto;
`;