import { useState } from 'react';
import ModalTemplate from '../components/modal-template.component';
import ModalButtonContainer from '../components/buttons/modal-button-container.component';
import { CircularProgress} from '@material-ui/core';
import Button from '../components/buttons/button.component';
import { Alert } from "@material-ui/lab";
import styled from "styled-components";

export default function ConfirmModal(props) {
  const { onCancel, onConfirm, message, ...modalProps } = props;
  const [ alert, setAlert] = useState({ show: false, type: null, message: '' });
  const [loading, setLoading] = useState(false);

  const cancelation = async() => {
    setLoading(true);
    await onCancel();
    setAlert({ show: false, type: null, message: null });
    setLoading(false);
    modalProps.closeModal();
  };

  const confirmation = async() => {
    setLoading(true);
    await onConfirm();
    modalProps.closeModal();
    setAlert({ show: false, type: null, message: null });
    setLoading(false);
  };

  return (
    <ModalTemplate
      width="50%"
      height={ props.height ?? 'auto' }
      {...modalProps}
      closeModal={() => {
        if (loading) return;
        modalProps.closeModal();
      }}
    >
      <MainWrapper>
        <div>{message}</div>
        <ModalButtonContainer marginTop='0px'>
          { alert.show && <AlertWrapper>
              <Alert severity={alert.type}>{alert.message}</Alert>
            </AlertWrapper>
          }
          { loading
            ? <CircularProgress />
            : <>
                <Button
                  outlineButton
                  onClick={cancelation}
                >
                  Annuler
                </Button>
                <Button
                  secondary
                  onClick={confirmation}
                >
                  Confirmer
                </Button>
              </>
          }
        </ModalButtonContainer>
      </MainWrapper>
    </ModalTemplate>
  );
}

const AlertWrapper = styled.div``;

export const MainWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;
