import React from "react";
import styled from "styled-components";
import moment from "moment";

export default function ProfileDescription({ profile }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {profile.experiences?.map((experience) => (
        <Experience key={experience.profileExperienceId}>
          <div className="highlight">{experience.title}</div>
          <div>
            du {moment(experience.startDate).format("DD/MM/YYYY")} au{" "}
            {moment(experience.endDate).format("DD/MM/YYYY")}
          </div>
          {!experience.anonymous ? (
            <div>
              pour <span className="highlight">{experience.clientName}</span>
            </div>
          ) : null}
          {experience.description ? (
            <div className="description">{experience.description}</div>
          ) : null}
        </Experience>
      ))}
      {profile.wishes ? (
        <WishesWrapper>
          <div className="title">
            Quelques mots{" "}
            {profile.user.profile === "freelance" ? "sur moi" : "du manager"}
          </div>
          <pre className="content">{profile.wishes}</pre>
        </WishesWrapper>
      ) : null}
      {profile.missionContext &&
        <WishesWrapper>
          <div className="title">
						Contexte de la mission
          </div>
          <pre className="content">{profile.missionContext}</pre>
        </WishesWrapper>
			}
      {profile.missionDescription &&
        <WishesWrapper>
          <div className="title">
						Description
          </div>
          <pre className="content">{profile.missionDescription}</pre>
        </WishesWrapper>
			}
      {profile.missionExpertise &&
        <WishesWrapper>
          <div className="title">
						Expertise souhaitée
          </div>
          <pre className="content">{profile.missionExpertise}</pre>
        </WishesWrapper>
			}
    </div>
  );
}

const Experience = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid #f194ac;
  font-size: 16px;
  font-family: "Prompt";
  color: #241f4788;

  & .highlight {
    color: #241f47;
  }

  & .description {
    font-size: 15px;
    margin-top: 10px;
    color: #333;
    white-space: pre-line;
  }
`;

const WishesWrapper = styled.div`
  width: auto;
  padding: 16px;
  font-size: 16px;
  font-family: "Prompt";
  color: #fff;
  line-height: 24px;
  background-color: #353f83;
  margin-top: 20px;
  border-radius: 20px;

  & .title {
    margin-bottom: 10px;
  }

  & .content {
    opacity: 0.9;
    font-size: 15px;
    font-family: "Prompt Light";
    white-space: pre-line;
  }
`;
