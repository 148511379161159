import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

import {
  PDFViewer,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  // Font
} from "@react-pdf/renderer";

import { get } from "../../shared/utils/api.utils";
// import Logo from "../../png-assets/logo.png";

// Font.register({ family: 'Roboto', src: "https://1terco.com/assets/fonts/Roboto-Regular.ttf", fontStyle: 'normal', fontWeight: 'normal' });

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
  },

  header: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "150px",
  },

  title: {
    profileTitle: {
      fontSize: "26pt",
      color: "#241f47",
      borderBottom: "2pt solid #241f47",
    },
    profileHash: {
      marginTop: 5,
      fontSize: "15pt",
      color: "#B2B2B2",
    },
  },

  section: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
  },

  main: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
    display: "flex",
    flexDirection: "row",

    wrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",

      title: {
        fontSize: "16pt",
        color: "#333",
        borderBottom: "2pt solid #333",
      },
    },
  },

  sidebar: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    avatar: {
      width: 80,
      height: 80,
    },

    userInfo: {
      fontSize: "13pt",
      color: "#333",
      marginTop: 8,
      marginBottom: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    tjmInfo: {
      width: "75%",
      backgroundColor: "#241f47",
      padding: 10,
      marginTop: 20,
      marginBottom: 20,

      wrapper: {
        marginTop: 5,
        marginBottom: 5,
      },
      label: {
        fontSize: "12pt",
        color: "#FFF",
        opacity: "0.5",
      },
      value: {
        fontSize: "13pt",
        color: "#FFF",
      },
    },
  },

  generalInfo: {
    width: "55%",
    display: "flex",
    flexDirection: "column",
    infoWrapper: {
      marginBottom: 10,
    },
    label: {
      fontSize: "13pt",
      color: "#333",
    },
    value: {
      fontSize: "13pt",
      color: "#B2B2B2",
    },
    additionalInfo: {
      fontSize: "13pt",
      color: "#333",
      fontWeight: "bold",
    },
  },

  skills: {
    wrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 5,
      marginBottom: 5,
    },

    skill: {
      marginRight: 10,
      display: "flex",
      flexDirection: "row",

      label: {
        fontSize: "13pt",
        marginRight: "5pt",
      },
      star: {
        width: "16pt",
      },
    },
  },

  experiences: {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      marginTop: 5,
      marginBottom: 5,
    },

    flexRow: {
      display: "flex",
      flexDirection: "row",
    },

    highlight: {
      fontSize: "13pt",
      color: "#241f47",
    },

    date: {
      fontSize: "13pt",
      color: "#B2B2B2",
    },

    description: {
      marginTop: 5,
      fontSize: "13pt",
      color: "#333",
      borderBottom: "1px solid #B2B2B2",
    },
  },

  wishes: {
    width: "100%",
    backgroundColor: "#241f47",
    padding: 10,
    marginTop: 10,

    label: {
      fontSize: "13pt",
      color: "#FFF",
    },
    value: {
      fontSize: "13pt",
      color: "#FFF",
      opacity: "0.5",
    },
  },
});

export default function ProfileCardPdf() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    get(`/profiles/${params.hash}`).then((response) => {
      if (response && response.success) {
        if (!response.profile.anonymous) {
          // on vérifie que le profil n'est pas anonyme
          setProfile(response.profile);
          setLoading(false);
        }
      }
    });
  }, []); // eslint-disable-line

  return (
    <>
      {!loading && profile ? (
        <PDFWrapper>
          <PDFViewer width="100%" height="100%">
            <Document>
              <Page size="A4" style={styles.page}>
                {/* LOGO */}
                <View style={styles.header}>
                  <Image src="/assets/logo.png" />
                </View>
                {/* TITRE DU PROFIL */}
                <View style={styles.section}>
                  <View style={styles.title.profileTitle}>
                    <Text>{profile.title}</Text>
                  </View>
                  <View style={styles.title.profileHash}>
                    <Text>{profile.hash}</Text>
                  </View>
                </View>
                <View style={styles.main}>
                  {/* SIDEBAR */}
                  <View style={styles.sidebar}>
                    <View style={styles.sidebar.avatar}>
                      <Image
                        src={
                          profile.user.profile === "freelance"
                            ? "/assets/icons/big_avatar_freelance.png"
                            : "/assets/icons/big_avatar_company.png"
                        }
                      />
                    </View>
                    <View style={styles.sidebar.userInfo}>
                      {profile.anonymous ? (
                        <Text style={styles.sidebar.userInfo}>
                          {profile.user.profile === "freelance"
                            ? "Profil"
                            : "Besoin"}{" "}
                          anonymisé
                        </Text>
                      ) : (
                        <>
                          <Text style={styles.sidebar.userInfo}>
                            {profile.user.fullname}
                          </Text>
                          <Text style={styles.sidebar.userInfo}>
                            {profile.user.phone}
                          </Text>
                          <Text style={styles.sidebar.userInfo}>
                            {profile.user.profile === "freelance"
                              ? "Freelance"
                              : `${profile.user.companyName}`}
                          </Text>
                        </>
                      )}
                    </View>
                    <View style={styles.sidebar.tjmInfo}>
                      <View style={styles.sidebar.tjmInfo.wrapper}>
                        <Text style={styles.sidebar.tjmInfo.label}>TJM</Text>
                        <Text style={styles.sidebar.tjmInfo.value}>
                          {profile.priceVisible ? `${profile.price}€` : "N/C"}
                        </Text>
                      </View>
                      <View style={styles.sidebar.tjmInfo.wrapper}>
                        <Text style={styles.sidebar.tjmInfo.label}>
                          DATE DE MÀJ
                        </Text>
                        <Text style={styles.sidebar.tjmInfo.value}>
                          {moment(profile.updatedAt).format("DD.MM.YYYY")}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* INFO DU PROFIL */}
                  <View style={styles.generalInfo}>
                    <View style={styles.generalInfo.infoWrapper}>
                      <Text style={styles.generalInfo.label}>
                        {profile.user.profile === "freelance"
                          ? "Mobilité"
                          : "Localisation"}
                      </Text>
                      <Text style={styles.generalInfo.value}>
                        {profile.workLocations.map((location) => location.name)}
                      </Text>
                    </View>
                    <View style={styles.generalInfo.infoWrapper}>
                      <Text style={styles.generalInfo.label}>
                        Format de travail
                      </Text>
                      <Text style={styles.generalInfo.value}>
                        {profile.type}
                      </Text>
                    </View>
                    <View style={styles.generalInfo.infoWrapper}>
                      <Text style={styles.generalInfo.label}>
                        {profile.user.profile === "freelance"
                          ? "Expérience"
                          : "Expérience minimum"}
                      </Text>
                      <Text style={styles.generalInfo.value}>
                        {profile.experience}
                      </Text>
                    </View>
                    <View style={styles.generalInfo.infoWrapper}>
                      <Text style={styles.generalInfo.label}>
                        {profile.user.profile === "freelance"
                          ? "Date de disponibilité"
                          : "Date de démarrage"}
                      </Text>
                      <Text style={styles.generalInfo.value}>
                        {profile.availability}
                      </Text>
                    </View>
                    <View style={styles.generalInfo.infoWrapper}>
                      <Text style={styles.generalInfo.label}>
                        {profile.user.profile === "freelance"
                          ? "Durée de disponibilité"
                          : "Date du projet"}
                      </Text>
                      <Text style={styles.generalInfo.value}>
                        {profile.duration}
                      </Text>
                    </View>
                    {profile.user.profile === "freelance" && (
                      <View style={styles.generalInfo.infoWrapper}>
                        <Text style={styles.generalInfo.label}>
                          Niveau d'études
                        </Text>
                        <Text style={styles.generalInfo.value}>
                          {profile.studies}
                        </Text>
                      </View>
                    )}
                    <View style={styles.generalInfo.infoWrapper}>
                      <Text style={styles.generalInfo.label}>
                        Secteurs d'activité
                      </Text>
                      <Text style={styles.generalInfo.value}>
                        {profile.fields.map((field) => field.label)}
                      </Text>
                    </View>
                    <View style={styles.generalInfo.infoWrapper}>
                      <Text style={styles.generalInfo.label}>Anglais</Text>
                      <Text style={styles.generalInfo.value}>
                        {profile.englishLevel}
                      </Text>
                    </View>
                    <View style={styles.generalInfo.infoWrapper}>
                      <Text style={styles.generalInfo.label}>
                        {profile.user.profile === "freelance"
                          ? "Ouvert à la pré-embauche"
                          : "Pré-embauche"}
                      </Text>
                      <Text style={styles.generalInfo.value}>
                        {profile.trialPeriod ? "Oui" : "Non"}
                      </Text>
                    </View>
                    {profile.empowerments.length ? (
                      <View style={styles.generalInfo.infoWrapper}>
                        <Text style={styles.generalInfo.label}>
                          Habilitation
                        </Text>
                        <Text style={styles.generalInfo.value}>
                          {profile.empowerments.join(", ")}
                        </Text>
                      </View>
                    ) : null}
                    {profile.recommandationLetter ? (
                      <View style={styles.generalInfo.infoWrapper}>
                        <Text style={styles.generalInfo.additionalInfo}>
                          Lettre(s) de recommandation disponible(s)
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
                <View style={styles.main}>
                  <View style={styles.main.wrapper}>
                    <Text style={styles.main.wrapper.title}>Compétences</Text>
                    <View style={styles.skills.wrapper}>
                      {profile.skills.map((skill) => {
                        const stars = [];
                        for (let i = 0; i < skill.rating; i++) {
                          stars.push("filled");
                        }
                        for (let i = 0; i < 5 - skill.rating; i++) {
                          stars.push("not_filled");
                        }
                        return (
                          <View style={styles.skills.skill}>
                            <Text style={styles.skills.skill.label}>
                              {skill.label}
                            </Text>
                            {stars.map((el) => (
                              <Image
                                style={styles.skills.skill.star}
                                src={`/assets/icons/${
                                  el === "filled" ? "star.png" : "star_2.png"
                                }`}
                              />
                            ))}
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </View>
                {profile.experiences.length ? (
                  <View style={styles.main}>
                    <View style={styles.main.wrapper}>
                      <Text style={styles.main.wrapper.title}>Expériences</Text>
                      {profile.experiences.map((experience) => (
                        <View style={styles.experiences.wrapper}>
                          <Text style={styles.experiences.highlight}>
                            {experience.title}
                          </Text>
                          <Text style={styles.experiences.date}>
                            du{" "}
                            {moment(experience.startDate).format("DD/MM/YYYY")}{" "}
                            au {moment(experience.endDate).format("DD/MM/YYYY")}
                          </Text>
                          {!experience.anonymous ? (
                            <View style={styles.experiences.flexRow}>
                              <Text style={styles.experiences.date}>pour</Text>
                              <Text
                                style={{
                                  ...styles.experiences.highlight,
                                  marginLeft: 5,
                                }}
                              >
                                {experience.clientName}
                              </Text>
                            </View>
                          ) : null}
                          <Text style={styles.experiences.description}>
                            {experience.description}
                          </Text>
                        </View>
                      ))}
                    </View>
                  </View>
                ) : null}
                {profile.wishes ? (
                  <View style={styles.main}>
                    <View style={styles.main.wrapper}>
                      <Text style={styles.main.wrapper.title}>
                        Mot du manager
                      </Text>
                      <View style={styles.wishes}>
                        <Text style={styles.wishes.label}>
                          Quelques mots{" "}
                          {profile.user.profile === "freelance"
                            ? "sur moi"
                            : "du manager"}
                        </Text>
                        <Text style={styles.wishes.value}>
                          {profile.wishes}
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : null}
              </Page>
            </Document>
          </PDFViewer>
        </PDFWrapper>
      ) : (
        <WaitWrapper>
          <CircularProgress size={200} />
        </WaitWrapper>
      )}
    </>
  );
}

const PDFWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

const WaitWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
