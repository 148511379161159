import React from 'react';
import { FormControlLabel, Checkbox, withStyles } from "@material-ui/core";

export default function CheckboxInput({ label, checked, onChangeHandler }) {
	return (
		<FormControlLabel
			control={<ColoredCheckbox checked={checked} onChange={onChangeHandler} style={{ backgroundColor: 'transparent' }} />}
			label={label || ""}
		/>
	);
}

const ColoredCheckbox = withStyles({
	root: {
		color: "#F2CED2",
		'&$checked': {
			color: "#F2CED2",
		},
	},
	checked: {},
})((props) => <Checkbox {...props} />);
