import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { CustomButton, ButtonsContainer } from "../createinterco/shared/yes-no-buttons.component";
import { FormControlLabel } from "@material-ui/core";
import {
	FormContainer,
	SmallTitle,
	CustomCheckbox,
} from "../createinterco/shared/styled-components-library";
import Select from "../../../shared/components/inputs/select-input.component";

import UnderlineInput from "../../../shared/components/inputs/big-underline-text-input.component";
import RoutingButtons from "../createinterco/shared/routing-buttons.component";
import { propositionNumberOptions, frequenceOptions } from "../../../constants/select-options.constants";
import {Alert} from "@material-ui/lab";
import { get } from "../../../shared/utils/api.utils";
import { LightTooltip } from "../../../shared/utils/styled-components-library.utils";

export const priceIsOK = (price) => {
  return !isNaN(price
      .toString()
      .replace(" ", "")
      .replace("€", "")
      .replace(",", ".")
      .trim())
    && !(price === "");
}


export default function PricesForm(props) {
	const { submitAlert, handleSubmit, handlePrevious } = props;
	const [formData, setFormData] = useContext(props.context);
	const [preEmploymentOpen, setPreEmploymentOpen] = useState(false);
	const [price, setPrice] = useState("");
	const [negotiable, setNegotiable] = useState(false);
	const [showErrors, setShowErrors] = useState(false);
	const [companyPanel, setCompanyPanel] = useState(null);
	const [diffusionToAllCompanies, setDiffusionToAllCompanies] = useState(true);
	const [diffusionToAllFreelances, setDiffusionToAllFreelances] = useState(true);
        const [propositionNumber, setPropositionNumber] = useState(null);
        const [notificationFrequence, setNotificationFrequence] = useState('real-time');

  useEffect(() => {
    loadFormData();
  }, []); // eslint-disable-line

        useEffect(
          ()  => {
            if (submitAlert.show && submitAlert.type === 'success') {
              setTimeout(() => {
                window.location.href = "/";
              }, 5000);
            }
          }, [submitAlert]
        );

	const loadFormData = () => {
		if (formData && formData.pricesForm) {
			setPreEmploymentOpen(formData.pricesForm.preEmploymentOpen);
                        setNegotiable(formData.pricesForm.negotiable);
			setPrice(formData.pricesForm.price);
                        setCompanyPanel(formData.pricesForm.companyList);
                        setDiffusionToAllCompanies(formData.pricesForm.companyList === null);
                        setDiffusionToAllFreelances(formData.pricesForm.allFreelances);
                        setPropositionNumber(formData.pricesForm.propositionNumber);
                        setNotificationFrequence(formData.pricesForm.notificationFrequence);
                }

	};

	const saveAndHandleSubmit = () => {
          const newData = saveFormData();
          const hasError =
            !negotiable && !priceIsOK(price) ||
            !diffusionToAllCompanies && companyPanel === null ||
            propositionNumber === null;
          if (hasError) {
                  setShowErrors(true);
          } else {
                  handleSubmit(newData);
          }
	};

        const saveAndHandlePrevious = () => {
          saveFormData();
          handlePrevious();
        }

        const saveFormData = () => {
                const newData = {
			...formData,
			pricesForm: {
				preEmploymentOpen,
                                negotiable,
				price: price
                                    .toString()
                                    .replace(" ", "")
                                    .replace("€", "")
                                    .replace(",", ".")
                                    .trim(),
                                companyList: diffusionToAllCompanies ? null : companyPanel,
                                allFreelancesNotified: diffusionToAllFreelances,
                                propositionNumber: propositionNumber === -1 ? null : propositionNumber,
                                notificationFrequence,
			}
		}
		setFormData(newData);
                return newData
	};

	const stateProps = {
		preEmploymentOpen,
		setPreEmploymentOpen,
		price,
		setPrice,
		negotiable,
		setNegotiable,
    companyPanel,
    setCompanyPanel,
    diffusionToAllCompanies,
    setDiffusionToAllCompanies,
    diffusionToAllFreelances,
    setDiffusionToAllFreelances,
    propositionNumber,
    setPropositionNumber,
    notificationFrequence,
    setNotificationFrequence,
	};

	return (
          <>
		<PricesFormLayout
			{...props}
			{...stateProps}
			showErrors={showErrors}
			saveAndHandleSubmit={saveAndHandleSubmit}
                        saveAndHandlePrevious={saveAndHandlePrevious}
		/>
          </>
	);
}

/* Le layout est utile pour la création d'un profil MAIS AUSSI pour l'édition d'un profil dans l'espace personnel */
export const PricesFormLayout = props => {
	const {
		showErrors,
		submitAlert, // object : { show: boolean, type: string, message: string }
		preEmploymentOpen,
		setPreEmploymentOpen,
		price,
		setPrice,
		negotiable,
		setNegotiable,
    companyPanel,
    setCompanyPanel,
    diffusionToAllCompanies,
    setDiffusionToAllCompanies,
    diffusionToAllFreelances,
    setDiffusionToAllFreelances,
                propositionNumber,
                setPropositionNumber,
                notificationFrequence,
                setNotificationFrequence,
                saveAndHandlePrevious,
                saveAndHandleSubmit,
		isEditMode,
	} = props;
    const [loadedCompanyLists, setLoadedCompanyLists] = useState([]);

  useEffect(() => {
    get("/company-list").then(response => {
      setLoadedCompanyLists(response);
    })
  }, []); // eslint-disable-line

	return (
          <>
		<FormContainer width={isEditMode ? "95%" : "50%"}>
                        <SmallTitle>Est-ce pour de la pré-embauche ?</SmallTitle>
                        <ButtonsContainer style={{ margin: "30px 0px"}}>
                                <CustomButton active={preEmploymentOpen === true} onClick={() => {
                                        setPreEmploymentOpen(true);
                                }}>Oui</CustomButton>
                                <CustomButton active={preEmploymentOpen === false} onClick={() => {
                                        setPreEmploymentOpen(false);
                                }}>Non</CustomButton>
                                <CustomButton active={preEmploymentOpen === null} onClick={() => {
                                        setPreEmploymentOpen(null);
                                }}>Possible</CustomButton>
                        </ButtonsContainer>
			<SmallTitle>Taux Journalier Moyen max</SmallTitle>
			<PriceContainer>
				<UnderlineInput
					deactivate={negotiable}
					placeholder="Préciser le TJM (€)"
					value={price}
          onChangeHandler={e => {
            let newPrice = e.target.value;
            if (priceIsOK(newPrice)) {
              setPrice(Math.max(parseFloat(newPrice), 0));
            }
            else if (newPrice === "") {
              setPrice("")
            }
					}}
					error={showErrors && !negotiable && !priceIsOK(price)}
					errorText="Veuillez indiquer un TJM, dans un format valide."
					type="number"
					step="1"
					min="1"
				/>
				<CustomFormControlLabel
					style={{ marginTop: "30px" }}
					label="Selon profil"
					labelPlacement="end"
					control={
						<CustomCheckbox
							checked={negotiable}
							onChange={e => {
                                                          setPrice("");
                                                          setNegotiable(e.target.checked)
                                                        }}
						/>
					}
				/>
			</PriceContainer>

      <SmallTitle style={{ marginTop: 20 }}>À quelles ESN souhaitez-vous diffuser votre offre ?</SmallTitle>
      <ButtonsContainer style={{ marginBottom: 20}}>
              <CustomButton active={diffusionToAllCompanies === true} onClick={() => {
                      setDiffusionToAllCompanies(true);
              }}>À toutes</CustomButton>
              <LightTooltip
                title={ loadedCompanyLists?.length === 0 ? "Aucun référencement défini dans votre espace personnel" : ""}
              >
              <CustomButton 
                active={diffusionToAllCompanies === false}
                disabled={loadedCompanyLists?.length === 0}
                onClick={() => {
                      setDiffusionToAllCompanies(false);
                }}
              >À un référencement</CustomButton>
              </LightTooltip>
      </ButtonsContainer>
      { loadedCompanyLists !== undefined && diffusionToAllCompanies === false &&
        <Select
          options={loadedCompanyLists && loadedCompanyLists.map(({ companyListId, title }) => ({ label: title, value: companyListId}))}
          value={companyPanel}
          onChangeHandler={(e) => setCompanyPanel(e.target.value)}
          label="Liste de diffusion *"
          error={showErrors && !companyPanel}
          errorText="Veuillez sélectionner une option."
        />
      }
      <SmallTitle style={{ marginBottom: 20 }}>Souhaitez-vous diffuser votre offre à tous les freelances ?</SmallTitle>
      <ButtonsContainer style={{ marginBottom: 30 }}>
              <CustomButton active={diffusionToAllFreelances === true} onClick={() => {
                      setDiffusionToAllFreelances(true);
              }}>Oui</CustomButton>
              <CustomButton active={diffusionToAllFreelances === false} onClick={() => {
                      setDiffusionToAllFreelances(false);
              }}>Non</CustomButton>
      </ButtonsContainer>

                        <div style={{ marginTop: 30 }} />
                          <Select options={propositionNumberOptions} value={propositionNumber} onChangeHandler={(e) => setPropositionNumber(e.target.value)} label="Nombre de propositions possibles par utilisateur *" error={showErrors && !propositionNumber} errorText="Veuillez sélectionner une option." />

                        <SmallTitle style={{ marginTop: 20 }}>Souhaitez-vous être informé des candidatures ?</SmallTitle>
                        <ButtonsContainer>
                                <CustomButton active={notificationFrequence === 'real-time'} onClick={() => {
                                        setNotificationFrequence('real-time');
                                }}>Oui</CustomButton>
                                <CustomButton active={notificationFrequence === 'never'} onClick={() => {
                                        setNotificationFrequence('never');
                                }}>Non</CustomButton>
                        </ButtonsContainer>
			{
                          !isEditMode &&
                            <>
				<RoutingButtons
                                        isLastStep
                                        handleSubmit={saveAndHandleSubmit}
                                        handlePrevious={saveAndHandlePrevious}
				/>
                                {
                                    submitAlert.show &&
                                        <Alert severity={submitAlert.type}>{submitAlert.message}</Alert>
                                }
                            </>
                        }
		</FormContainer>
          </>
	);
};

const PriceContainer = styled.div`
	width: 60%;
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
	& .MuiFormControlLabel-label {
		font-family: "Prompt Light";
	}
`;