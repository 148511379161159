import React from 'react';
import { Rating } from '@material-ui/lab';
import Image from './images/image.component';
import { LightTooltip } from '../utils/styled-components-library.utils';
import { withStyles } from '@material-ui/core';
import Help from './images/help.component';
import styled from 'styled-components';

const skillTooltipContent = (
  <div>
    <div>
      <b>1 étoile</b> : Connaissances uniquement scolaire
    </div>
    <div>
      <b>2 étoiles</b> : Pratiqué 6 mois
    </div>
    <div>
      <b>3 étoiles</b> : Pratiqué entre 1 an et 2 ans
    </div>
    <div>
      <b>4 étoiles</b> : Pratiqué entre 3 et 5 ans
    </div>
    <div>
      <b>5 étoiles</b> : Pratiqué plus de 5 ans
    </div>
  </div>
);

const SkillsList = ({ profile, nameSpace }) => {
  return (
    <SkillsWrapper>
      <LightTooltip placement="right" title={skillTooltipContent}>
        <SkillsHelpWrapper>
          <Help width="15px">
            <h3
              style={{
                margin: '0',
              }}
            >
              Compétences
            </h3>
          </Help>
        </SkillsHelpWrapper>
      </LightTooltip>

      {profile.skills &&
        profile.skills
          .filter((skill, index) => index < 3)
          .sort((a, b) => {
            if (a.rating > b.rating) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((skill) => (
            <RatingWrapper key={skill.label}>
              <span className="label">{skill.label}</span>
              <StyledRating
                value={skill.rating}
                readOnly
                size="large"
                icon={
                  <Image
                    width="30px"
                    height="30px"
                    src="/assets/icons/banane.png"
                  />
                }
                emptyIcon={
                  <Image
                    width="30px"
                    height="30px"
                    src="/assets/icons/banane-empty.png"
                  />
                }
              />
            </RatingWrapper>
          ))}
    </SkillsWrapper>
  );
};

// The following styled-components and withStyles are also required in the file
// as they are used in the SkillsList component.

const SkillsWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  padding: 1em 1.5em;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to right, #f9cdd5 0%, #f29daa 100%);
  border-radius: 20px;
`;

const SkillsHelpWrapper = styled.div`
  margin: 0 auto;

  h3 {
    font-size: 18px;
    font-family: 'Prompt';
    color: white;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;

  @media screen and (max-width: 1550px) {
    flex-direction: column;

    & > .label {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 50%;
    }
  }

  & > .label {
    font-size: 18px;
    font-family: 'Prompt';
    color: #241f47;
    margin-right: 10px;
    overflow: hidden;
    white-space: wrap;
    max-width: 90%;
    overflow-wrap: normal;
  }
`;

const StyledRating = withStyles({
  iconFilled: {
    color: '#ffde8f',
  },
  iconHover: {
    color: 'transparent',
    border: '2px solid #ffde8f',
  },
})(Rating);

export default SkillsList;
