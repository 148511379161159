import React from "react";
import { withStyles, Switch } from "@material-ui/core";

const StyledSwitch = withStyles({
	root: {
		width: 42,
		height: 22,
		padding: 0,
		display: 'flex',
	},
	switchBase: {
		padding: 3,
		color: "#F194AC",
		'&$checked': {
			transform: 'translateX(20px)',
			color: "#FFF",
			'& + $track': {
				opacity: 1,
				backgroundImage: "linear-gradient(to right, #f9cdd5 0%, #f29daa 100%)",
				border: "none",
			},
		},
	},
	thumb: {
		width: 16,
		height: 16,
		boxShadow: 'none',
	},
	track: {
		border: "1px solid #F194AC",
		borderRadius: 22 / 2,
		opacity: 1,
		backgroundColor: "#FFF",
		height: "auto"
	},
	checked: {},
})(Switch);

const SwitchInput = (props) => {
	return (
		<StyledSwitch checked={props.checked} onChange={props.onChangeHandler} />
	);
}


export default SwitchInput;