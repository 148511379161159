import React, { useContext } from "react";
import styled from "styled-components";

import { AuthContext } from "../../../contexts/auth.context";
import Logo from "../logo.component";
import { StyledLink } from "../../utils/styled-components-library.utils";

const responsiveMaxWidth = "840px";
const responsiveMediumWidth = "1275px";

export default function Footer() {
  const [authState] = useContext(AuthContext);
  return (
    <FooterWrapper>
      <FooterElement>
        <FooterTop>
          <TopImage src="/assets/images/footer-top.png" />
        </FooterTop>
        <FooterBottom>
          <FooterBottomContainer>
            <FooterLeft>
              <StyledLink to="/">
                <FooterLogo width="200px" />
              </StyledLink>
              <FooterInfo>
                <div>50 Quai Louis Bleriot</div>
                <div>75016 Paris</div>
                <div>06 59 37 74 43</div>
              </FooterInfo>
            </FooterLeft>
            <FooterRight>
              <FooterLinks>
                <FooterHyperlinkItem to="/a-propos">
                  A propos
                </FooterHyperlinkItem>
                <FooterHyperlinkItem
                  to={
                    authState.logged
                      ? "/espace-personnel?onglet=contact"
                      : "/nous-contacter"
                  }
                >
                  Nous contacter
                </FooterHyperlinkItem>
                <FooterHyperlinkItem to="/mentions-legales">
                  Mentions legales
                </FooterHyperlinkItem>
              </FooterLinks>
            </FooterRight>
          </FooterBottomContainer>
        </FooterBottom>
        <BottomImage src="/assets/images/footer-vegetation.png"></BottomImage>
      </FooterElement>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div`
  margin-top: 10px;
`;

const FooterElement = styled.footer`
  position: relative;
  overflow: hidden;
`;

const FooterTop = styled.div`
  width: 100%;
  overflow: hidden;
`;

const TopImage = styled.img`
  width: 100%;
  min-width: 650px;
`;

const FooterBottom = styled.div`
  width: 100%;
  background-color: #241f47;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1em 8vw 3vw;

  & > * {
    z-index: 1;
  }
`;

const BottomImage = styled.img`
  width: 100%;
  min-width: 650px;
  position: absolute;
  left: 0;
  top: 5.37vw;
  z-index: 0;

  @media screen and (max-width: 750px) {
    top: 36px;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    justify-content: center;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2em 2vw 0;
  width: 100%;
  max-width: 800px;
  margin: auto;

  @media screen and (max-width: ${responsiveMediumWidth}) {
    padding-top: 0;
    flex-direction: column;
  }
`;

const FooterLogo = styled(Logo)`
  filter: grayscale(100%) brightness(100);
`;

const FooterBottomContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    flex-direction: column;
  }
`;

const FooterHyperlinkItem = styled(StyledLink)`
  color: #fff;
  font-size: 24px;
  font-family: "Prompt Semi Bold";
  text-transform: uppercase;

  &:hover {
    color: #f2b6bc;
  }

  @media screen and (max-width: ${responsiveMaxWidth}) {
    margin: 5px 0;
  }
`;

const FooterRight = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 420px);

  @media screen and (max-width: ${responsiveMaxWidth}) {
    margin: 20px 0;
    flex-direction: column;
    text-align: center;
    width: 100%;

    & > * {
      margin: auto;
    }
  }
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-family: "Prompt Light";
  font-size: 16px;
  text-align: left;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    text-align: center;
  }
`;
