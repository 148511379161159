import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

import { AuthContext } from "../../../contexts/auth.context";
import { StyledLink } from "../../utils/styled-components-library.utils";
import Button from "../buttons/button.component";
import Logo from "../logo.component";

import { deleteToken } from "../../utils/auth.utils";

// Le tableau suivant contient la liste des pages où le Header devrait être en sticky
const stickyPages = ["/creer-interco", "/espace-personnel"];

const responsiveMaxWidth = "1000px";

export default function Header({ openSignInModal }) {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [auth] = useContext(AuthContext);

  useEffect(() => {
    if (stickyPages.includes(location.pathname)) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, [location]);

  return (
    <HeaderElement sticky={isSticky}>
      <HeaderContentContainer>
        <StyledLink
          to="/"
          className="logo"
          style={{ flex: 1, marginRight: 10 }}
        >
          <LogoContainer>
            <Logo width="210px" />
          </LogoContainer>
        </StyledLink>
        <NavLinksContainer>
          {!auth.logged ? (
            <Button
              onClick={() => openSignInModal("/bourse-interco")}
              outlineButton={location.pathname !== "/"}
              secondary={location.pathname !== "/"}
              invisible={location.pathname === "/"}
              style={{
                color: "#2e2275",
                margin: "0px 0px",
                padding: location.pathname === "/" ? "8px 0px" : "8px 36px",
              }}
            >
              Bourse d'interco
            </Button>
          ) : auth.profile !== "freelance" ? (
            <StyledNavLink to="/bourse-interco">Bourse d'interco</StyledNavLink>
          ) : null}
          {auth.logged && auth.profile === "company" && (
            <StyledNavLink to="/creer-interco">Créer un interco</StyledNavLink>
          )}

          {!auth.logged ? (
            <Button
              onClick={() => openSignInModal("/liste-besoin")}
              outlineButton={location.pathname !== "/"}
              secondary={location.pathname !== "/"}
              invisible={location.pathname === "/"}
              style={{
                color: "#2e2275",
                margin: "0px 0px",
                padding: location.pathname === "/" ? "8px 0px" : "8px 36px",
              }}
            >
              Liste des besoins
            </Button>
          ) : auth.profile !== "end-client" ? (
            <StyledNavLink to="/liste-besoin">Liste des besoins</StyledNavLink>
          ) : null}

          {auth.logged && auth.profile !== "freelance" && (
            <StyledNavLink to="/creer-besoin">
              <span>Créer un besoin</span>
            </StyledNavLink>
          )}
          {auth.logged && (
            <StyledNavLink to="/espace-personnel">
              Espace personnel
            </StyledNavLink>
          )}
          {auth.logged && (
            <Button
              outlineButton
              secondary
              onClick={() => {
                deleteToken();
                window.location.href = "/";
              }}
            >
              Se déconnecter
            </Button>
          )}
          {!auth.logged && (
            <>
              <Button
                onClick={openSignInModal}
                outlineButton={location.pathname !== "/"}
                secondary={location.pathname !== "/"}
                invisible={location.pathname === "/"}
                style={{
                  color: "#2e2275",
                  margin: "0px 0px",
                  padding: location.pathname === "/" ? "8px 0px" : "8px 36px",
                }}
              >
                Se connecter
              </Button>
              {location.pathname !== "/inscription" &&
              location.pathname !== "/nous-contacter" ? (
                <Link to="/nous-contacter">
                  <Button
                    outlineButton
                    secondary={location.pathname === "/"}
                    style={{ margin: "0px 0px" }}
                  >
                    S'inscrire
                  </Button>
                </Link>
              ) : null}
            </>
          )}
        </NavLinksContainer>
      </HeaderContentContainer>
    </HeaderElement>
  );
}

const HeaderElement = styled.header`
  position: ${(props) => (props.sticky ? "sticky" : "static")};
  top: 0;
  z-index: 5;
  box-shadow: ${(props) =>
    props.sticky ? "0px 3px 5px 0px rgba(0,0,0,0.3)" : "none"};
  background-color: ${(props) => (props.sticky ? "#FFF" : "transparent")};
`;

const HeaderContentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 30px 80px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  & .loginButtons {
    width: 350px;
  }

  @media screen and (max-width: ${responsiveMaxWidth}) {
    justify-content: center;

    & .loginButtons {
      display: none;
    }
  }
`;

const LogoContainer = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    justify-content: center;
    margin-left: 0px;
  }
`;

const NavLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 20px;
  gap: 36px;

  @media screen and (max-width: ${responsiveMaxWidth}) {
    display: none;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 0px 0px;
  font-size: 14px;
  font-family: "Prompt Semi Bold";
  color: #42387a;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    color: #f296ac;
  }

  &.active {
    color: #f296ac;
    border-bottom: 2px solid #f296ac;
  }
`;
