import React from 'react';
import styled from "styled-components";

import Image from "../images/image.component";

export default function Button({ children, src, onClick, outlineButton, disabled, secondary, light, invisible, gradient, uppercase, style }) {
	return (
		<StyledButton
			onClick={onClick}
			outlineButton={outlineButton}
			secondary={secondary}
			uppercase={uppercase}
			light={light}
			invisible={invisible}
			gradient={gradient}
			disabled={disabled}
			style={style}>
			<span>{children}</span>
			<Image src={src} width="24px" />
		</StyledButton>
	);
}

const StyledButton = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	user-select: none;
	color: ${props => (props.outlineButton || props.invisible ? (props.secondary ? '#F2B6BC' : '#241f47') :  (props.secondary ? '#241f47' : '#FFF'))};
	padding: 8px 36px;
	border: ${props => (props.outlineButton && !props.invisible ? "1px solid " + (props.secondary ? "#F2B6BC" : "#241f47") : "none")};
	border-radius: 100px;
	font-size: 14px;
	font-family: ${props => (props.light ? "Prompt Light" : "Prompt Semi Bold")};
	background-color: ${props => {
		if (props.disabled)
			return "#d8d8d8";
		return (!props.invisible ? !props.outlineButton ? (props.secondary ? "#F2B6BC" : "#241f47") : "#FFF" : 'transparent')}};
	transition: all 0.2s ease-in;
	cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
	pointer-events: ${props => (props.disabled ? "none" : "auto")};
	outline: none;
	${props => (props.uppercase ? "text-transform: uppercase;" : "")}

	${props => (props.gradient ?
		props.disabled ? "" : "background-image: linear-gradient(to right, #f9cdd5 0%, #f29daa 100%);"
		:
		""
	)}
	
	&:hover {
		${props => (props.outlineButton || props.invisible ? 'color: ' + (props.secondary ? "#f9d4de" : "#2e2275") : '')};
		${props => (props.outlineButton || props.invisible ? 'border-color: ' + (props.secondary ? "#f9d4de" : "#2e2275") : '')};
		background-color: ${props => (!props.outlineButton && !props.invisible ? (props.secondary ? "#f9d4de" : "#2e2275") : "transparent")};
	}

	&:disabled {
		background-color: ${props => (!props.outlineButton ? "#f9d4de" : "#CCC")};
		color: black;
	}
`;
