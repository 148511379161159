import React from "react";
import styled from "styled-components";
import Stepper from "../../../shared/components/stepper.component";
import DragDrop from "../../../shared/components/drag-drop.component";
import RoutingButtons from "./routing-buttons.component";
import { Alert } from "@material-ui/lab";


export default function LogoForm({ stepperLabels, activeStep, companyLogoFile, setCompanyLogoFile, setCompanyLogoUploadedFilename, handleNext, handlePrevious, handleStep, completedSteps, setCompletedSteps }) {
  const [showErrors, setShowErrors] = React.useState(false);

  const handleValidationAndNext = () => {
    if (!companyLogoFile) {
      setShowErrors(true);
    } else {
      setCompletedSteps((completedSteps) => ({
        ...completedSteps,
        [activeStep]: true,
      }));
      handleNext();
    }
  };


  return (
	<>
		<Stepper labels={stepperLabels} activeStep={activeStep} handleStep={handleStep} completedSteps={completedSteps} type="pink" style={{ marginLeft: '-10%' }} />
		<DragDropContainer>
			<DragDrop
				label="Cliquez ici pour importer votre logo ou faites le glisser dans cette zone"
				acceptedFileExtensions={["image/jpeg", "image/jpg", "image/png"]}
				acceptedFileExtensionsLabels={["JPEG, JPG, PNG"]}
				imagePreview
				file={companyLogoFile}
				setFile={setCompanyLogoFile}
				uploadCallback={(filename) => setCompanyLogoUploadedFilename(filename)}
				removeFile={() => {
					setCompanyLogoFile(null);
					setCompanyLogoUploadedFilename("");
				}}
			/>
                        { showErrors && !companyLogoFile && <Alert severity="error">Veuillez importer votre logo</Alert> }
			<RoutingButtons
				handleNext={handleValidationAndNext}
				handlePrevious={handlePrevious}
				handleSubmit={() => { }}
				style={{ justifyContent: 'center' }}
			/>
		</DragDropContainer>
	</>
);
}

const DragDropContainer = styled.div`
	min-height: 500px;
	
	& > * {
			margin: 30px 0px;
	}

	@media screen and (min-width: 520px) {
		width: 80%;
	}
`;