import CompanyProfiles from "./company-profiles.component";

export default function FreelanceProfile({ user, setUser, updateProfile, deleteProfile, manageItem, matchItem, currentTab, setCurrentTab, setShouldRefreshUser, loadedFields, loadedWorkLocations, auth }) {
  const [displayedProfile] = user.profiles;

  return (
    <CompanyProfiles
      nameSpace='profiles'
      user={user}
      setUser={setUser}
      updateProfile={updateProfile}
      deleteProfile={deleteProfile}
      manageItem={manageItem}
      matchItem={matchItem}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      setShouldRefreshUser={setShouldRefreshUser}
      fixedProfile={displayedProfile ?? undefined}
      loadedFields={loadedFields}
      loadedWorkLocations={loadedWorkLocations}
      auth={auth}
    />
  );
}
