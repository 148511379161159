import Autocomplete from './inputs/add-value-autocomplete-input.component';
import DeleteableChipContainer from "./deleteable-chip-container.component";

export default function AutocompleteWithChips({ addData, deleteData, data, dataChoice, placeholder }) {
  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
              data={dataChoice}
              onClickHandler={(e) => addData(e)}
              placeholder={ placeholder ?? "Veuillez entrer une valeur" }
              style={{ width: '100%' }}
      />
      <DeleteableChipContainer chips={data} deleteChipHandler={deleteData} />
    </div>
);
}
