import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { genderOptions } from "../../../constants/select-options.constants";
import { BigTitle } from "../../../shared/utils/styled-components-library.utils";
import { post, get } from "../../../shared/utils/api.utils";
import { isValid } from "../../../shared/utils/input-validation.utils";
import { uploadFile } from "../../../shared/utils/files.utils";

import TextInput from "../../../shared/components/inputs/text-input.component";
import PasswordInput from "../../../shared/components/inputs/password-input.component";
import Button from "../../../shared/components/buttons/button.component";
import Select from "../../../shared/components/inputs/select-input.component";
import MultiSelect from "../../../shared/components/inputs/multi-select-input.component";
import { Alert } from "@material-ui/lab";
import FileInput from "../../../shared/components/inputs/file-input.component";
import PhoneInput from "../../../shared/components/inputs/phone-input.component";
import { jobOptions } from "../../../constants/select-options.constants";

export default function Settings({ user, deleteUser, loadedFields }) {
  const isFreelance = user && user.profile === "freelance";
  const [showErrors, setShowErrors] = useState(false);
  const [alertTop, setAlertTop] = useState({
    show: false,
    type: null,
    message: null,
  });
  const [alertBottom, setAlertBottom] = useState({
    show: false,
    type: null,
    message: null,
  });

  const [email, setEmail] = useState("");
  const [formerPassword, setFormerPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [uploadedCompanyLogo, setUploadedCompanyLogo] = useState("");
  const [fields, setFields] = useState([]);
  const [workforce, setWorkforce] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [siret, setSiret] = useState("");
  const [ape, setApe] = useState("");
  const [website, setWebsite] = useState("http");
  const [checkingExistence, setCheckingExistence] = useState(false);
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [initialName, setInitialName] = useState("");
  const [initialSiret, setInitialSiret] = useState("");
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [isDuplicateSiret, setIsDuplicateSiret] = useState(false);

  useEffect(() => {
    const success = window.location.search.includes("success=true");
    if (success) {
      setAlertTop({
        show: true,
        type: "success",
        message: "Vos informations ont bien été mises à jour",
      });
    }
  }, []);

  useEffect(() => {
    if (user && !!loadedFields.length) {
      setDefaultValues(user);
    }
  }, [user, loadedFields]); // eslint-disable-line

  const setDefaultValues = (user) => {
    setEmail(user.email);
    setGender(user.gender === "male" ? 1 : 2);
    setLastname(user.lastname);
    setFirstname(user.firstname);
    setPhone(user.phone);
    setJobTitle(user.jobTitle);

    if (user.profile !== "freelance" && user.type === "admin") {
      setCompanyName(user?.company?.name || "");
      setInitialName(user?.company?.name || "");
      setCompanyLogo(user?.company?.logo || "");
      setWorkforce(user?.company?.workforce || "");
      setZipcode(user?.company?.zip || "");
      setSiret(user?.company?.siret || "");
      setInitialSiret(user?.company?.siret || "");
      setApe(user?.company?.ape || "");
      setWebsite(user?.company?.website || "");
      setCompanyId(user?.company?.companyId || "");
      if (loadedFields.length) {
        const selectedFieldIds =
          user?.company?.fields.map((field) => field.fieldId) || [];
        const selectedFields = loadedFields.filter((field) =>
          selectedFieldIds.includes(field.fieldId)
        );
        setFields(selectedFields);
      }
    }
  };

  const canSubmitEdit = () => {
    let hasError = false;

    if (!email.trim() || !isValid("email", email)) hasError = true;
    if (!phone.trim() || !isValid("phone", phone)) hasError = true;
    if (!lastname || !lastname.trim() || !isValid("text", lastname))
      hasError = true;
    if (!firstname || !firstname.trim() || !isValid("text", firstname))
      hasError = true;

    if (user.profile !== "freelance" && user.type === "admin") {
      if (!companyName || !companyName.trim()) hasError = "true";
      if (!workforce || !isValid("number", workforce)) hasError = true;
      if (!zipcode.trim() || !isValid("number", zipcode)) hasError = true;
      if (!isValid("siret", siret)) hasError = true;
      if (!isValid("ape", ape)) hasError = true;
      if (!isValid("website", website)) hasError = true;
    }

    // nouveau mot de passe
    if (!!formerPassword.trim() || !!newPassword.trim()) {
      if (!formerPassword.trim()) hasError = true;
      if (!isValid("password", newPassword)) hasError = true;
      if (newPassword !== confirmPassword) hasError = true;
      if (formerPassword === newPassword) hasError = true;
    }

    if (hasError) {
      setShowErrors(true);
    } else {
      setShowErrors(false);
    }
    return !hasError;
  };

  const submitEdit = () => {
    setAlertTop({ show: false, type: null, message: null });
    setAlertBottom({ show: false, type: null, message: null });
    if (!canSubmitEdit()) return null;
    setShowErrors(false);

    const validPasswords =
      isValid("password", newPassword) && !!formerPassword.trim();
    // const validPasswords = isValid("password", newPassword) && isValid("password", formerPassword);
    const payload = {
      phone,
      lastname,
      firstname,
      gender: gender === 1 ? "male" : "female",
      jobTitle,
      zipcode,
      password: {
        newPassword: validPasswords ? newPassword : null,
        formerPassword: validPasswords ? formerPassword : null,
      },
      company: {
        name: companyName.trim().toUpperCase(),
        siret: siret.trim(),
        ape: ape.trim(),
        workforce,
        zipcode: zipcode.trim(),
        fields,
        logo: uploadedCompanyLogo || companyLogo,
        website: website.trim(),
      },
    };
    if (!isDuplicateName && !isDuplicateSiret) {
      post(`/users/${user.userId}`, payload).then((response) => {
        if (response && response.success) {
          window.location.href =
            "/espace-personnel?onglet=parametres&success=true";
        } else {
          setAlertBottom({
            show: true,
            type: "error",
            message:
              (response.error && response.message) ||
              "Un problème a été rencontré durant la sauvegarde de vos modifications.",
          });
        }
      });
    }
  };

  const onFileChange = (e) => {
    const fileToUpload = e.target.files[0];
    if (fileToUpload) {
      setCompanyLogo(fileToUpload.name);

      uploadFile(fileToUpload).then((uploadedFileName) => {
        if (!!uploadedFileName) {
          setUploadedCompanyLogo(uploadedFileName);
        } else {
          setAlertBottom({
            show: true,
            type: "error",
            message:
              "Un problème est survenu lors du téléchargement de votre nouveau logo, veuillez réessayer.",
          });
        }
      });
    }
  };

  const newPasswordError = !newPassword
    ? "Ce champ est obligatoire si vous souhaitez modifier votre mot de passe."
    : !isValid("password", newPassword)
    ? "Le nouveau mot de passe n'est pas valide."
    : formerPassword === newPassword
    ? "Veuillez indiquer un nouveau mot de passe différent de l'ancien mot de passe."
    : "";

  const checkSiretExistence = () => {
    let newSiret = siret.replace(/ /g, "");
    if (isValid("siret", newSiret) && initialSiret != newSiret) {
      setIsDuplicateSiret(false);
      setCheckingExistence(true);
      get(`/companies/siret/${newSiret}`).then((response) => {
        if (response && response.found) {
          setIsDuplicateSiret(true);
        }
        setCheckingExistence(false);
      });
    }
  };

  const checkNameExistence = () => {
    const name = companyName.trim().toUpperCase();
    if (!!name && name != initialName) {
      setIsDuplicateName(false);
      setCheckingExistence(true);
      get(`/companies/name/${name}`).then((response) => {
        if (response && response.found) {
          setIsDuplicateName(true);
        }
        setCheckingExistence(false);
      });
    }
  };
  return (
    <PageWrapper>
      <BigTitle>INFORMATIONS</BigTitle>
      <ContentWrapper>
        {alertTop.show && (
          <Alert
            style={{ marginBottom: "2em" }}
            severity={alertTop.type}
            onClose={() => {
              setAlertTop({ show: false, type: null, message: null });
            }}
          >
            {alertTop.message}
          </Alert>
        )}
        <FormWrapper>
          {loadedFields.length && (
            <>
              { user && user.profile !== 'freelance' && user.type === 'admin' &&
                <FormColumn>
                  <CustomTitle>Informations{ user.profile === 'freelance' ? '' : ' société'}</CustomTitle>
                    <TextInput
                      value={companyName}
                      onChangeHandler={(e) => setCompanyName(e.target.value)}
                      label="Nom société"
                      required
                      onBlur={checkNameExistence}
                      error={isDuplicateName}
                      errorText={
                        isDuplicateName
                          ? "Le nom de cette société est déjà prise."
                          : "Ce champ est obligatoire."
                      }
                    />
                    <MultiSelect
                      label="Secteurs d'activité *"
                      options={loadedFields}
                      values={fields}
                      handleChange={(e) => setFields(e.target.value)}
                    />
                    <FieldsGroup>
                      <TextInput
                        value={workforce}
                        onChangeHandler={(e) => setWorkforce(e.target.value)}
                        label="Effectif"
                        required
                        error={
                          showErrors &&
                          (!workforce || !isValid("number", workforce))
                        }
                        errorText={
                          workforce
                            ? "Ce champ ne doit comporter que des nombres."
                            : "Ce champ est obligatoire."
                        }
                      />
                      <TextInput
                        value={zipcode}
                        onChangeHandler={(e) => setZipcode(e.target.value)}
                        label="Code postal"
                        required
                        error={
                          showErrors &&
                          (!zipcode.trim() || !isValid("number", zipcode))
                        }
                        errorText={
                          zipcode.trim()
                            ? "Ce champ ne doit comporter que des nombres."
                            : "Ce champ est obligatoire."
                        }
                      />
                      <TextInput
                        value={siret}
                        onChangeHandler={(e) => setSiret(e.target.value)}
                        label="Siret"
                        required
                        error={isDuplicateSiret || !isValid("siret", siret)}
                        errorText={
                          isDuplicateSiret
                            ? "Ce siret existe déjà."
                            : siret.trim()
                            ? "Veuillez saisir un SIRET valide."
                            : "Ce champ est obligatoire."
                        }
                        onBlur={checkSiretExistence}
                      />
                      <TextInput
                        value={ape}
                        onChangeHandler={(e) => setApe(e.target.value)}
                        label="Code APE"
                        required
                        error={showErrors && !isValid("ape", ape)}
                        errorText={
                          ape.trim()
                            ? "Veuillez saisir un code APE valide."
                            : "Ce champ est obligatoire."
                        }
                      />
                    </FieldsGroup>
                    <TextInput
                      value={website}
                      onChangeHandler={(e) => setWebsite(e.target.value)}
                      label="Site internet"
                      placeholder="Exemple : https://www.amazon.com"
                      error={showErrors && !isValid("website", website)}
                      errorText="Le format du site web n'est pas valide."
                    />
                    <FileInput
                      companyLogo={companyLogo}
                      onFileChange={onFileChange}
                    />
                </FormColumn>
              }
              <FormColumn>
                <CustomTitle>Informations utilisateur</CustomTitle>
                <Select
                  options={genderOptions}
                  label="Genre"
                  value={gender}
                  onChangeHandler={(e) => setGender(e.target.value)}
                  required
                />
                <FieldsGroup>
                  <TextInput
                    value={lastname}
                    onChangeHandler={(e) => setLastname(e.target.value)}
                    label="Nom"
                    required
                    error={
                      showErrors &&
                      (!lastname ||
                        !lastname.trim() ||
                        !isValid("text", lastname))
                    }
                    errorText="Ce champ est obligatoire et ne doit pas comporter de chiffres."
                  />
                  <TextInput
                    value={firstname}
                    onChangeHandler={(e) => setFirstname(e.target.value)}
                    label="Prénom"
                    required
                    error={
                      showErrors &&
                      (!firstname ||
                        !firstname.trim() ||
                        !isValid("text", firstname))
                    }
                    errorText="Ce champ est obligatoire et ne doit pas comporter de chiffres."
                  />
                </FieldsGroup>
                <PhoneInput
                  id="phone-number"
                  value={phone}
                  onChangeHandler={setPhone}
                  validationHandler={setIsPhoneValid}
                  label="Numéro de téléphone"
                  required
                  error={showErrors && (!phone.trim() || !isPhoneValid)}
                  errorText={
                    phone.trim()
                      ? "Veuillez saisir un numéro de téléphone valide."
                      : "Ce champ est obligatoire."
                  }
                />
                {!isFreelance && (
                  <Select
                          value={jobTitle}
                          options={jobOptions}
                          label="Fontion dans la société"
                          onChangeHandler={e => setJobTitle(e.target.value)}
                          error={showErrors && (!jobTitle || !jobTitle.trim())}
                          errorText="Ce champ est obligatoire."
                          required
                  />
                )}
                <TextInput
                  value={email}
                  disabled
                  label="Adresse e-mail"
                  required
                  error={
                    showErrors &&
                    (!email.trim() || !isValid("email", email.trim()))
                  }
                  errorText={
                    email.trim()
                      ? "Veuillez saisir une adresse e-mail valide."
                      : "Ce champ est obligatoire."
                  }
                />
                <PasswordInput
                  value={formerPassword}
                  onChangeHandler={(e) => setFormerPassword(e.target.value)}
                  label="Ancien mot de passe"
                  required
                  error={
                    showErrors && !!newPassword.trim() && !formerPassword.trim()
                  }
                  errorText="Veuillez saisir votre ancien mot de passe si vous souhaitez le modifier."
                />
                <PasswordInput
                  value={newPassword}
                  onChangeHandler={(e) => setNewPassword(e.target.value)}
                  showPasswordStrength={!!newPassword}
                  showPasswordRules
                  label="Nouveau mot de passe"
                  required
                  error={
                    showErrors &&
                    !!formerPassword.trim() &&
                    (!isValid("password", newPassword) ||
                      formerPassword === newPassword)
                  }
                  errorText={newPasswordError}
                />
                <PasswordInput
                  value={confirmPassword}
                  onChangeHandler={(e) => setConfirmPassword(e.target.value)}
                  label="Confirmer le nouveau mot de passe"
                  required
                  error={showErrors && confirmPassword !== newPassword}
                  errorText="Les deux mots de passe ne correspondent pas."
                />
              </FormColumn>
            </>
          )}
        </FormWrapper>
        <ActionsWrapper>
          <ButtonsWrapper>
            <Button
              outlineButton
              secondary
              onClick={() => setDefaultValues(user)}
              style={{ color: "#241f47" }}
            >
              Annuler les modifications
            </Button>
            <Button secondary gradient onClick={submitEdit}>
              Enregistrer
            </Button>
          </ButtonsWrapper>
          {alertBottom.show && (
            <Alert severity={alertBottom.type}>{alertBottom.message}</Alert>
          )}
          <DeleteAccount onClick={() => deleteUser(user, true)}>
            Supprimer mon compte
          </DeleteAccount>
        </ActionsWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  background-color: white;
  border-radius: 60px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  padding: 4em;
`;

const CustomTitle = styled.div`
  font-family: "Prompt";
  font-size: 20px;
  color: #241f47;
  margin-bottom: 30px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const FormColumn = styled.div`
  width: 40%;
  max-width: 400px;
  margin-right: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const FieldsGroup = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 48%;
    margin-bottom: 24px;
  }
`;

const ActionsWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 20px 0;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;

  & > * {
    margin: 0 8px;
  }
`;

const DeleteAccount = styled.div`
  font-family: "Prompt Semi Bold";
  color: #241f47;
  font-size: 14px;
  border-bottom: 2px solid #f296ac;
  cursor: pointer;
`;
