import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Alert } from "@material-ui/lab";

import { BigTitle } from "../../../shared/utils/styled-components-library.utils";
import ContactInfo from "../../../shared/components/contact-info.component";
import TextInput from "../../../shared/components/inputs/text-input.component";
import Button from "../../../shared/components/buttons/button.component";
import { post } from "../../../shared/utils/api.utils";

export default function ProfilePage({ user }) {
	const [subject, setSubject] = useState("");
	const [content, setContent] = useState("");
	const [alert, setAlert] = useState({ show: false, type: null, message: null });
	const [showErrors, setShowErrors] = useState(false);

	const sendMail = () => {
		if (alert.type !== "success") {
			if (!!subject.trim() && !!content.trim()) {
				setShowErrors(false);
				post("/misc/user-contact-us", { subject, content }).then(response => {
					if (response && response.success) {
						setAlert({ show: true, type: "success", message: "Votre message a bien été transmis à l'équipe 1terco !" });
					} else {
						setAlert({ show: true, type: "error", message: "Une erreur s'est produite lors de l'envoi du message. Veuillez vous déconnectez puis vous reconnectez afin de pouvoir contacter 1terco." });
					}
				});
			} else {
				setShowErrors(true);
				setAlert({ show: true, type: "error", message: "Le sujet et le contenu du message sont obligatoires, merci de renseigner ces informations pour conctacter 1terco." });
			}
		}
	}

	return (
		<MainWrapper>
			<BigTitle>CONTACTER L'ÉQUIPE D'1TERCO</BigTitle>
			<BodyWrapper>
				<ContactInfo align="center" />
				<InputsWrapper>
					<TextInput
						value={subject}
						onChangeHandler={(e) => setSubject(e.target.value)}
						label="Sujet"
						error={showErrors && !subject.trim()}
						errorText="Ce champ est obligatoire."
						required
					/>
					<TextInput
						value={content}
						onChangeHandler={(e) => setContent(e.target.value)}
						multiline
						rows={6}
						label="Poser une question à l’équipe 1terco"
						error={showErrors && !content.trim()}
						errorText="Ce champ est obligatoire."
						required
					/>
				</InputsWrapper>
				<SendButtonWrapper>
					<Button onClick={sendMail}>Envoyer</Button>
				</SendButtonWrapper>
				{
					alert.show && <AlertWrapper>
						<Alert severity={alert.type}>{alert.message}</Alert>
					</AlertWrapper>
				}
			</BodyWrapper>
		</MainWrapper>
	);
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const BodyWrapper = styled.div`
	margin: auto;
	padding: 3em;
	background-color: white;
	border-radius: 40px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
	max-width: 565px;
`;

const InputsWrapper = styled.div`
	margin: 20px 0;
`;

const SendButtonWrapper = styled.div`
	margin: 10px 0;
	display: flex;
	justify-content: center;
`;

const AlertWrapper = styled.div`
	margin: 10px 0 40px 0;
`;