import React from 'react';
import styled from "styled-components";

const responsiveMaxWidth = "800px";

export default function RoundedButton(props) {
	return (
		<Button onClick={props.onClick} width={props.width} disabled={props.disabled}>
			{props.children}
		</Button>
	);
}

const Button = styled.button`
	box-sizing: border-box;
	height: 88px;
	padding: 22px 0;
	border-radius: 50px;
	font-size: 20px;
	line-height: 24px;
	font-family: "Prompt Semi Bold";
	user-select: none;
	outline: none;
	cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
	transition: all 0.4s ease;
	width: ${props => props.width ? props.width : "auto"};
	vertical-align: center;

	border: 2px solid #241f47;
	background-color: #241f47;
	color: #fff;

	&:hover {
		background-color: #fff;
		color: #241f47;
		box-shadow: 0px 10px 48px 5px rgba(0,0,0,0.25);
		transform: scale(1.05);
	}

	@media screen and (max-width: ${responsiveMaxWidth}) {
		width: 300px;
		height: 70px;
		font-size: 18px;
		padding: 10px 5px;
	}
`;