import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import ProfileInfo from "../../shared/components/profile-info.component";
import ActionSidebar from "../../specific/components/profilecard/action-bar.component";
import { get, post } from "../../shared/utils/api.utils";
import { GoBack, LightTooltip } from "../../shared/utils/styled-components-library.utils";

import ShareProfileModal from "../../specific/modals/share-profile.modal";
import {AuthContext} from "../../contexts/auth.context";

export default function ProfilePage(props) {
	const history = useHistory();
	const [auth] = useContext(AuthContext);
  const [user, setUser] = useState(null);
	const [showShareModal, setShowShareModal] = useState(false);
	const [profile, setProfile] = useState(null);
  const [permissions, setPermissions] = useState(null);
	const nameSpace = props.nameSpace;
  const itemName = nameSpace === "profiles" ? "profil" : "besoin";

  useEffect(
    () => {
      const hash = props.match.params.hash;
      get(`/${nameSpace}/${hash}`).then(response => {
        if (response && response.success) {
          setProfile(response.profile);
          setPermissions(response.permissions);
          
          const payload = {
            profileId: nameSpace === 'profiles' ? response.profile.profileId : undefined,
            needId: nameSpace === 'needs' ? response.profile.needId : undefined,
          };
          post(`/${nameSpace}/register-visit`, payload);
        }
      });
    },
    [props, history]
  );

  useEffect(() => {
    if (auth.logged) {
      get(`/users/${auth.userId}`).then((user) => {
        if (user) {
          setUser(user);
        }
      });
    }
  }, [auth]);

	return (
		<PageContainer>
			<ProfileContent style={{ width: "90%" }}>
				{profile ? (
					<>
						<ProfileHeader>
							<div>
								<GoBack onClick={() => history.goBack()}>
									<span>{"<"}</span>
									<span>Retour</span>
								</GoBack>
							</div>
							<HeaderInfosWrapper>
								<h2>{profile.title.toUpperCase()}</h2>
								<HeaderInfos>
									<span>{profile.hash}</span>
                                                                        <LightTooltip title={profile.isRestricted ? `Impossible de partager ce ${itemName} à diffusion restreinte` : ""} placement="top">
									<Share isRestricted={profile && profile.isRestricted}>
										<span className="text" onClick={() => {
                                                                                  if (nameSpace === 'profiles' || profile?.isRestricted === false)
                                                                                    setShowShareModal(true);
                                                                                }}>Partager ce {itemName}</span>
									</Share>
                                                                        </LightTooltip>
								</HeaderInfos>
							</HeaderInfosWrapper>
						</ProfileHeader>
						<ProfileInfoWrapper>
              { permissions && 
                <>
                  <ActionSidebar
                    nameSpace={nameSpace}
                    user={user}
                    profile={profile}
                    permissions={permissions}
                  /> 
                  <ProfileInfo
                    nameSpace={nameSpace}
                    profile={profile}
                    permissions={permissions}
                  />
                </>
              }
						</ProfileInfoWrapper>
					</>
				) : (
						<ProgressWrapper>
							<CircularProgress style={{ width: "100px", height: "100px" }} />
						</ProgressWrapper>
					)}
			</ProfileContent>
			<ShareProfileModal
				showModal={showShareModal}
				closeModal={() => setShowShareModal(false)}
				profile={profile}
        nameSpace={nameSpace}
			/>
		</PageContainer>
	);
}

const PageContainer = styled.div`
	padding: 40px 5% 0 5%;
	min-height: 60vh;
	width: 100%;
	display:flex;
	justify-content: center;
	background-color: #FFF;
	background-image: url("/assets/images/plantes-roses.svg");
	background-repeat: no-repeat;
	background-position: 105% 100%;
	background-size: calc(15em + 5vw);
`;

const ProgressWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const ProfileContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	& > * {
		margin-bottom: 30px;
	}
`;

const ProfileHeader = styled.div`
	display: flex;
	flex-direction: row;
	line-height: 24px;
	margin-bottom: 2em;
	width: 100%;
	align-self: flex-start;

	& h2 {
		font-size: 46px;
		line-height: 46px;
		font-family: "Prompt Black";
		color: #241f47;
		margin-bottom: 0;
	}

	& > div:first-child {
		width: 250px;
		min-width: 250px;
	}

	& > div:last-child {
		margin: auto;
	}
`;

const HeaderInfosWrapper = styled.div`
	width: 100%;
	min-width: 670px;
	padding: 0 5vw;
`;

const HeaderInfos = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	& > span {
		font-size: 14px;
		font-family: "Prompt";
		color: #7978a8;
		text-decoration: underline;
	}
`;

const ProfileInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
`;

const Share = styled.div`
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	font-size: 14px;
	font-family: "Prompt Light";
        color: ${props => props.isRestricted ? '#7978a8' : '#F194AC'};
	text-decoration: underline;
        text-decoration-color: ${props => props.isRestricted ? '#7978a8' : '#F194AC'};
	cursor: pointer;

	& .text {
		display: block;
	}
`;
