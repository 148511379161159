import React from "react";
import styled from "styled-components";

export default function Button(props) {
	return (
		<StyledButton
			onClick={props.onClick}
			outlineButton={props.outlineButton}
			secondary={props.secondary}
			uppercase={props.uppercase}
			light={props.light}
			invisible={props.invisible}
			gradient={props.gradient}
			disabled={props.disabled}
			style={props.style}
		>
			{props.children}
		</StyledButton>
	);
}


const StyledButton = styled.button`
	-webkit-appearance: none;
	user-select: none;
	color: ${props => (props.outlineButton || props.invisible ? (props.secondary ? '#F2B6BC' : '#241f47') :  (props.secondary ? '#241f47' : '#FFF'))};
	padding: 8px 36px;
	border: ${props => (props.outlineButton && !props.invisible ? "1px solid " + (props.secondary ? "#F2B6BC" : "#241f47") : "none")};
	border-radius: 100px;
	font-size: 14px;
	font-family: ${props => (props.light ? "Prompt Light" : "Prompt Medium")};
	background-color: ${props => (!props.invisible ? !props.outlineButton ? (props.secondary ? "#F2B6BC" : "#241f47") : "#FFF" : 'transparent')};
	transition: all 0.2s ease-in;
	cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
	pointer-events: ${props => (props.disabled ? "none" : "auto")};
	outline: none;
	${props => (props.uppercase ? "text-transform: uppercase;" : "")}

	${props => (props.gradient ?
		"background-image: linear-gradient(to right, #f9cdd5 0%, #f29daa 100%);"
		:
		""
	)}

	&:hover {
		${props => (props.outlineButton || props.invisible ? 'color: ' + (props.secondary ? "#f9d4de" : "#2e2275") : '')};
		${props => (props.outlineButton || props.invisible ? 'border-color: ' + (props.secondary ? "#f9d4de" : "#2e2275") : '')};
		background-color: ${props => (!props.outlineButton && !props.invisible ? (props.secondary ? "#f9d4de" : "#2e2275") : "transparent")};
	}

	&:disabled {
		background-color: ${props => (!props.outlineButton ? "#c3b3c3" : "#CCC")};
		color: black;
	}
	
`;
