import React, { useContext } from "react";
import moment from "moment";
import { AuthContext } from "../../../../contexts/auth.context";
import ItemCardLayout, { KeyValue } from "../../../../shared/components/item-card-layout.component";
import { useHistory } from "react-router-dom";
import ProfileActivity from "../../../../shared/components/profile-activity.component";
import Image from "../../../../shared/components/images/image.component";
import { getLogo } from "../../../../shared/utils/items.utils";
import { infoFactory } from "../../profilecard/profile-general-info.component";
import { LightTooltip } from "../../../../shared/utils/styled-components-library.utils";


export default function ProfileCard({ nameSpace, profile, sidebar, bottom, borderless, fixed, style, topClickable, bottomClickable, isPrivate, greyOut, additionalInformation }) {
  const [auth] = useContext(AuthContext);
  const history = useHistory();

  if (!profile) return `Error, profile is undefined`;
  const url = `/${nameSpace === 'needs' ? 'besoin' : 'profil'}/${profile.hash}`;
  const onClick = () => history.push(url);
  const titleShouldSpanTwoLines = nameSpace === 'profiles' && isPrivate === false;

  const Title = () => {
    if (nameSpace === 'needs' || (nameSpace === 'profiles' && isPrivate === true)) {
      return (
        <>
          { isPrivate === true &&
            <div className="name">{profile.firstName} {profile.lastName?.toUpperCase()}</div>
          }
          <LightTooltip title={profile.title.toUpperCase()}>
            <div className="title">{profile.title}</div>
          </LightTooltip>
          { isPrivate === true  && <ProfileActivity nameSpace={nameSpace} active={profile.isActive} /> }
        </>
      );
    } else {
      return (
        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
          <Image
            src={ getLogo(nameSpace, profile, profile.type, null, true) }
            size="70px"
          />
          <LightTooltip title={profile.title.toUpperCase()}>
            <div className="title">{profile.title}</div>
          </LightTooltip>
        </div>
      )
    }
  }

  const infos = infoFactory(profile, nameSpace, isPrivate, auth, true);

  const Info = () => (
    <>
    {
      infos.map((info, index) => (
        <KeyValue key={index} name={info.label}>{info.value}</KeyValue>
      ))
    }
    {
      additionalInformation && additionalInformation.map((info, index) => (
        <KeyValue key={index} name={info.label}>{info.value}</KeyValue>
      ))
    }
    </>
  );

  return (
    <ItemCardLayout
      item={profile}
      itemTitle={<Title />}
      itemInfo={<Info />}
      itemSidebar={sidebar}
      itemBottom={bottom}
      borderless={borderless}
      fixed={ fixed ?? false }
      style={ style }
      topClickable={ topClickable }
      bottomClickable={ bottomClickable }
      onClick={onClick}
      titleShouldSpanTwoLines={titleShouldSpanTwoLines}
      greyOut={greyOut}
    />
  );
}
