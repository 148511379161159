import React from 'react';
import styled from "styled-components";
import { FormHelperText } from "@material-ui/core";
import 'antd/dist/antd.css';
import { TreeSelect } from 'antd';
const { SHOW_PARENT } = TreeSelect;

export default function TreeSelectComponent({ placeholder, value, onChange, treeData, error, errorText }) {
	const treeProps = {
		treeData,
		value,
		onChange,
		treeCheckable: true,
		showCheckedStrategy: SHOW_PARENT,
		placeholder,
		showSearch: false,
		maxTagCount: 2,
		virtual: false,
		// filterTreeNode: (inputValue, treeNode) => {
		// 	return treeNode.title.includes(inputValue)
		// },
		style: {
			width: '100%',
		}
	};
	return <div>
		<CustomTreeSelect {...treeProps} />
		{
			error && <FormHelperText error>
				{errorText}
			</FormHelperText>
		}
	</div>;
}

const CustomTreeSelect = styled(TreeSelect)`
	& .ant-select-selector {
		border: 1px solid #ffc4ce !important;
		border-radius: 40px !important;
		background-color: transparent !important;
		padding: 13px 27px;
	}

	& .ant-select-selection-placeholder {
		color: #ffc4ce;
		font-family: "Prompt Light Italic";
		margin-left: 5px;
	}

	& .ant-select-selection-item {
		background-color: #ffc4ce;
		color: #241f47;
		font-family: "Prompt Light";
		border-radius: 15px;
		margin-left: 5px;
		margin-right: 5px;
		border: none;
	}
`;