import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import Button from "../../../shared/components/buttons/button.component";

export default ({ loading, isLastStep, disablePrevious, handlePrevious, handleNext, handleSubmit, isSkipButton, style }) => (
	<BackAndNextButtonsContainer style={style}>
		{
			loading ? <CircularProgress /> : null
		}
		<Button onClick={handlePrevious} secondary uppercase disabled={disablePrevious} style={{ padding: '16px 36px' }}>Précédent</Button>
		{
			!isLastStep ? (
				<Button onClick={handleNext} uppercase style={{ padding: '16px 36px' }}>
					{isSkipButton ? "Passer cette étape" : "Suivant"}
				</Button>
			) : (
					<Button onClick={handleSubmit} uppercase style={{ padding: '16px 36px' }}>
						Suivant
					</Button>
				)
		}
	</BackAndNextButtonsContainer>
);

const BackAndNextButtonsContainer = styled.div`
	margin: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;

	& button:nth-child(2) {
		margin-left: 20px;
	}

	& > * {
		margin-top: 10px;
	}
`;
