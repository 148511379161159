import React, { useState } from 'react';
import styled from "styled-components";
import { Rating } from "@material-ui/lab";
import GeneralInfo from "../../specific/components/profilecard/profile-general-info.component";
import ProfileDescription from "../../specific/components/profilecard/profile-description.component";
import { withStyles } from '@material-ui/core';
import SkillCard from './skill-card.component';

export default function ProfileInfo({ nameSpace, profile, forPrivateProfile = false }) {
  const [mode, setMode] = useState(0); // 0 : Compétences && 1 : Description

  const handleTabChange = (newMode) => {
    if (newMode !== mode && canCheckDescription()) {
      setMode(newMode);
    }
  }

  const canCheckDescription = () => {
    if (nameSpace === "profiles")
      return !!profile.experiences.length || !!profile.wishes;
    if (nameSpace === "needs")
      return !!profile.missionContext || !!profile.missionDescription || profile.missionExpertise;
  }

  return (
    <MainContainer forPrivateProfile={forPrivateProfile}>
      <MainWrapper forPrivateProfile={forPrivateProfile}>
        <TabWrapper>
          <Tab active={mode === 0} onClick={() => handleTabChange(0)}>Compétences</Tab>
          <Tab
            active={mode === 1}
            onClick={() => handleTabChange(1)}
            className={canCheckDescription() ? "" : "deactivated"}
            title={canCheckDescription() ? "" : "La description n'a pas été renseignée"}
          >
            Description
          </Tab>
        </TabWrapper>
        <ContentWrapper>
          {mode === 0 ?
            <>
              <GeneralInfo
                nameSpace={nameSpace}
                profile={profile}
                isPrivate={forPrivateProfile}
              />
              <div style={{ width: "49%" }}>
              <SkillCard profile={profile} nameSpace={nameSpace} />
              </div>
            </>
            : <ProfileDescription profile={profile} />
          }
        </ContentWrapper>
      </MainWrapper>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  ${props => !props.forPrivateProfile ? `
    padding: 0 5vw;
  ` : null
  };
  width: 100%;
`;

const MainWrapper = styled.div`
  width: 100%;
  min-width: 670px;
  display: flex;
  flex-direction: column;
  ${props => !props.forPrivateProfile ? `
    border-radius: 40px;
    box-shadow: 3px 0 20px rgba(0, 0, 0, 0.3);
    background-color: white;
    padding: 2em 6em;
  ` : `
    padding: 2em 0 0 4em;
  `
  };
`;

export const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 4px 0px;
  border-radius: 8px;
`;

export const Tab = styled.div`
  width: 49%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-family: "Prompt";
  padding: 12px 0;
  margin: 0px 4px;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;

  ${props => props.active ?
    "background-image: linear-gradient(to right, #f9cdd5 0%, #f29daa 100%);"
    :
    "background-color: white; box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);"
  };
  color: ${props => props.active ? "#FFF" : "#241f47"};

  &:hover {
    background-color: ${props => props.active ? "#F194AC" : "#EEE"};
  }

  &.deactivated {
    cursor: default;
    background-color: #DDD;
    color: #333;
    opacity: 0.5;

    &:hover {
      background-color: #DDD;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
`;