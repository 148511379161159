import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import ModalTemplate from "../../shared/components/modal-template.component";
import Button from "../../shared/components/buttons/button.component";

import AnonymousForm from "../components/createinterco/subforms/anonymous-form.component";
import DurationForm from "../components/createinterco/subforms/duration-form.component";
import LocationForm from "../components/createinterco/subforms/location-form.component";
import StudiesForm from "../components/createinterco/subforms/studies-form.component";
import { SkillsFormLayout } from "../components/createinterco/skills-form.component";
import { ExperiencesFormLayout } from "../components/createinterco/experiences-form.component";
import { WishesFormLayout } from "../components/createinterco/wishes-form.component";
import { PricesFormLayout } from "../components/createinterco/prices-form.component";
import { DocumentsFormLayout } from "../components/createinterco/documents-form.component";

import {
  mapWorkFormatLabelToValue,
  mapStudyLevelLabelToValue,
  mapExperienceYearsLabelToValue,
  mapEnglishLevelLabelToValue,
  mapAvailabilityLabelToValue,
  mapDurationLabelToValue,
} from "../../constants/select-options.constants";

import {
  fetchAllSkills,
  fetchAllEmpowerments,
} from "../../shared/utils/fetch.utils";
import { get, post } from "../../shared/utils/api.utils";
import { isValid } from "../../shared/utils/input-validation.utils";

/**
 * @param {object} props
 * @param {object} props.user L'utilisateur qui est dans son espace personnel.
 * @param {object} props.profile Le profil que l'on souhaite modifier.
 */
export default function UpdateProfileModal(props) {
  const {
    user,
    profile,
    setProfileToUpdate,
    loadedFields,
    loadedWorkLocations,
    refreshUserData,
    ...modalProps
  } = props;
  const isFreelance = user && user.profile === "freelance";

  // lorsqu'on affiche un profil puis qu'on souhaite le modifier, on a toutes les informations pour cela
  // cependant, lorsqu'on a la liste des intercos et qu'on souhaite en modifier un depuis cette interface,
  // alors il nous manque des données
  const isProfileWithInfo = profile && !!profile.skills;

  const commonFormProps = { isFreelance, isEditMode: true };
  const [defaultArrays, setDefaultArrays] = useState({});
  const [editErrors, setEditErrors] = useState([]);
  const [submittingEditedProfile, setSubmittingEditedProfile] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    type: null,
    message: null,
  });

  const [loadedEmpowerments, setLoadedEmpowerments] = useState([]);
  const [loadedSkills, setLoadedSkills] = useState([]);

  const [anonymous, setAnonymous] = useState(false);

  const [title, setTitle] = useState("");
  const [fieldIds, setFieldIds] = useState([]);
  const [availability, setAvailability] = useState("");
  const [duration, setDuration] = useState("");
  const [isRenewable, setIsRenewable] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [workLocationsSelected, setWorkLocationsSelected] = useState([]);
  const [workFormat, setWorkFormat] = useState("");

  const [studyLevel, setStudyLevel] = useState("");
  const [experienceYears, setExperienceYears] = useState("");
  const [englishLevel, setEnglishLevel] = useState("");
  const [hasEmpowerment, setHasEmpowerment] = useState(false);
  const [empowerments, setEmpowerments] = useState([]);

  const [skills, setSkills] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [wish, setWish] = useState("");
  const [preEmploymentOpen, setPreEmploymentOpen] = useState(false);
  const [hasRecommendation, setHasRecommendation] = useState(false);
  const [price, setPrice] = useState("");
  const [priceHidden, setPriceHidden] = useState(false);

  const [documentFilename, setDocumentFilename] = useState("");
  const [previousDocumentFilename, setPreviousDocumentFilename] = useState("");
  const [documentFile, setDocumentFile] = useState(null);
  const [documentStatus, setDocumentStatus] = useState("idle");
  const [isSafari, setIsSafari] = useState(false);
  // on récupère les données nécessaires
  useEffect(() => {
    let saf = navigator.userAgent.toLowerCase();
    if (saf.indexOf("safari") > -1 && saf.indexOf("chrome") === -1) {
      setIsSafari(true);
    }
  }, []);

  useEffect(() => {
    fetchAllSkills((_skills) => setLoadedSkills(_skills));
    fetchAllEmpowerments((_empowerments) =>
      setLoadedEmpowerments(_empowerments)
    );
  }, []);

  useEffect(
    () => {
      if (profile) {
        if (isProfileWithInfo) {
          autocompleteEditFields(profile);
        } else {
          get(`/profiles/${profile.hash}`).then((response) => {
            if (response && response.success) {
              setProfileToUpdate(response.profile);
            }
          });
        }
      }
    },
    [profile] // eslint-disable-line
  );

  useEffect(() => {
    if (!modalProps.showModal) {
      setAlert({ show: false, type: null, message: null });
      setDocumentFile(null);
    }
  }, [modalProps.showModal]);

  /**
   * Fonction permettant de pré-remplir les champs du modal pour modifier le modal.
   * @param {object} profile Profil à utiliser pour pré-remplir les champs, il doit comporter
   * toutes les informations existantes (habilitations, expériences, ...).
   */
  const autocompleteEditFields = (profile) => {
    // on vérifie que le profil n'est pas vide
    if (profile && !!profile.title) {
      const defaultExperiences = profile.experiences.map((_experience) => ({
        ..._experience,
        startDate: moment(_experience.startDate).format("YYYY-MM-DD"),
        endDate: moment(_experience.endDate).format("YYYY-MM-DD"),
        anonymous: !!_experience.anonymous,
        key: _experience.profileExperienceId, // important, sinon on ne peut pas modifier durant la modification du profil
      }));
      const defaultWorkLocations = profile.workLocations.map((workLocation) => {
        return !!workLocation.departmentId
          ? `${workLocation.regionId}.${workLocation.departmentId}`
          : `${workLocation.regionId}`;
      });
      // loadedFields
      const selectedFieldIds = profile.fields.map((field) => field.fieldId);
      const selectedFields = loadedFields.filter((field) =>
        selectedFieldIds.includes(field.fieldId)
      );
      setFieldIds(selectedFields);

      // à voir si on garde ce fonctionnement avec le nom du fichier
      const defaultFile = profile.files?.length
        ? profile.files[0].fileName
        : null;
      setDefaultArrays({
        fields: selectedFields,
        skills: profile.skills,
        experiences: defaultExperiences,
        empowerments: profile.empowerments,
        workLocations: defaultWorkLocations,
        file: defaultFile,
      });
      setAnonymous(!!profile.anonymous);
      setTitle(profile.title);
      setFieldIds(selectedFields);
      setAvailability(mapAvailabilityLabelToValue[profile.availability]);
      setDuration(mapDurationLabelToValue[profile.duration]);
      setIsRenewable(!!profile.renewable);
      setFirstname(profile.firstName);
      setLastname(profile.lastName);
      setWorkLocationsSelected(defaultWorkLocations);
      setWorkFormat(mapWorkFormatLabelToValue[profile.type]);
      setStudyLevel(mapStudyLevelLabelToValue[profile.studies]);
      setExperienceYears(mapExperienceYearsLabelToValue[profile.experience]);
      setEnglishLevel(mapEnglishLevelLabelToValue[profile.englishLevel]);
      setHasEmpowerment(!!profile.empowerments.length);
      setEmpowerments(profile.empowerments);
      setSkills(profile.skills);
      setExperiences(defaultExperiences);
      setWish(profile.wishes);
      setPreEmploymentOpen(!!profile.trialPeriod);
      setHasRecommendation(!!profile.recommandationLetter);
      setPrice(profile.price + "");
      setPriceHidden(!profile.priceVisible);
      setDocumentFilename(defaultFile);
      setPreviousDocumentFilename(defaultFile);
    }
  };

  /**
   * Fonction permettant de vérifier si les modifications de l'utilisateur sont aptes à être
   * enregistrées en BDD.
   */
  const canSubmit = () => {
    const errors = [];
    if (documentStatus === "loading")
      errors.push(
        "Veuillez attendre la fin du téléchargement de votre nouveau fichier de compétences avant de confirmer vos modifications."
      );
    const formattedPrice = price
      .toString()
      .replace(/ /g, "")
      .replace("€", "")
      .replace(",", ".");
    if ((isNaN(formattedPrice) || price === "") && !priceHidden) {
      errors.push("Veuillez indiquer un TJM valable");
    }
    if (!title.trim()) errors.push("Le titre n'est pas indiqué");
    if (!fieldIds.length)
      errors.push("Au moins un secteur d'activité doit être sélectionné");

    if (!isFreelance && !firstname.trim()) {
      errors.push("Le prénom de l'interco n'est pas indiqué");
    } else if (!isFreelance && !isValid("text", firstname)) {
      errors.push("Le prénom de l'interco ne doit pas contenir de chiffres.");
    }
    if (!isFreelance && !lastname.trim()) {
      errors.push("Le nom de l'interco n'est pas indiqué");
    } else if (!isFreelance && !isValid("text", lastname)) {
      errors.push("Le nom de l'interco ne doit pas contenir de chiffres.");
    }

    if (!workLocationsSelected.length)
      errors.push("Au moins un lieu de travail doit être sélectionné");
    if (hasEmpowerment && !empowerments.length) {
      errors.push(
        `Si ${
          isFreelance ? "vous avez" : "l'interco a"
        } des habilitations, veuillez en ajouter au moins une.`
      );
    }
    if (!skills.length) errors.push("Veuillez ajouter au moins une compétence");
    for (const experience of experiences) {
      if (
        !!experience.title.trim() &&
        !experience.anonymous &&
        !experience.clientName.trim()
      ) {
        errors.push(
          "Veuillez indiquer un nom de client pour les expériences non-anonymes."
        );
        break;
      }
      if (
        (!!experience.clientName.trim() || experience.anonymous) &&
        !experience.title.trim()
      ) {
        errors.push("Veuillez indiquer un titre pour les expériences.");
        break;
      }
    }

    setEditErrors(errors);
    return !errors.length;
  };

  const submitEdit = () => {
    if (submittingEditedProfile) return null;
    if (!canSubmit()) return null;
    const payload = {
      profileId: profile.profileId,
      anonymous: anonymous,
      title: title,
      fieldIds: fieldIds,
      availability: availability,
      duration: duration,
      renewable: isRenewable,
      firstname: firstname,
      lastname: lastname,
      workLocations: workLocationsSelected,
      workFormat: workFormat,
      studyLevel: studyLevel,
      experienceYears: experienceYears,
      englishLevel: englishLevel,
      hasEmpowerment: hasEmpowerment,
      empowerments: empowerments,
      skills: skills,
      experiences: experiences,
      wishes: wish,
      trialPeriod: preEmploymentOpen,
      hasRecommendation: hasRecommendation,
      price: price,
      priceHidden: priceHidden,
      fileName: documentFilename,
      userProfile: isFreelance ? "freelance" : "company",
      updatedArrays: {
        fields: defaultArrays.fields !== fieldIds,
        skills: defaultArrays.skills !== skills,
        experiences: defaultArrays.experiences !== experiences,
        empowerments: defaultArrays.empowerments !== empowerments,
        workLocations: defaultArrays.workLocations !== workLocationsSelected,
        file: defaultArrays.file !== documentFilename,
      },
    };
    setSubmittingEditedProfile(true);
    setAlert({ show: false, type: null, message: null });
    post("/profiles/update-profile", payload).then((response) => {
      if (response && response.success) {
        if (payload.updatedArrays.file) {
          post("/profiles/file-parsing", { profileId: profile.profileId }).then(
            (res) => {
              if (res && res.success) {
                refreshUserData();
                props.closeModal();
              } else {
                setAlert({
                  show: true,
                  type: "error",
                  message:
                    res.errorMessage ||
                    "La modification de ce profil n'a pas aboutie correctement.",
                });
              }
              setSubmittingEditedProfile(false);
            }
          );
        } else {
          setSubmittingEditedProfile(false);
          refreshUserData();
          props.closeModal();
        }
      } else {
        setSubmittingEditedProfile(false);
        setAlert({
          show: true,
          type: "error",
          message:
            response.errorMessage ||
            "La modification de votre profil n'a pas aboutie.",
        });
      }
    });
  };

  return (
    <ModalTemplate {...modalProps} width="900px">
      <MainWrapper>
        <CustomTitle>MODIFICATION DU PROFIL</CustomTitle>
        <ReferenceWrapper>{profile && profile.hash}</ReferenceWrapper>
        {isProfileWithInfo ? (
          <>
            <FormsWrapper>
              <FormWrapper>
                <AnonymousForm
                  {...commonFormProps}
                  anonymous={anonymous}
                  setAnonymous={setAnonymous}
                  paragraph={
                    <>
                      <span>Si oui, {props.isFreelance ? "vos informations" : "les informations de votre société"} ne seront pas visibles.</span>
                      <span>Nous vous recommandons de ne pas {props.isFreelance ? "vous" : "la"} rendre anonyme afin de faciliter les échanges.</span>
                    </>
                  }
                />
              </FormWrapper>
              <FormWrapper width="80%">
                <DurationForm
                  {...commonFormProps}
                  fields={loadedFields}
                  fieldIds={fieldIds}
                  setFieldIds={setFieldIds}
                  jobTitle={title}
                  setJobTitle={setTitle}
                  availability={availability}
                  setAvailability={setAvailability}
                  duration={duration}
                  setDuration={setDuration}
                  isRenewable={isRenewable}
                  setIsRenewable={setIsRenewable}
                  title={`Informations${isFreelance ? '' : ' intercontrat'}`} 
                  jobTitlePlaceholder="Titre du profil"
                  timePlaceholder={ `${isFreelance ? "Dans combien de temps êtes-vous disponible ?" : "Dans combien de temps l’intercontrat est disponible ?"}` }
                  durationPlaceholder={ `${isFreelance ? "Pendant combien de temps êtes-vous disponible ?" : "Pendant combien de temps le profil est disponible ?"}` }
                />
              </FormWrapper>
              <FormWrapper width="80%">
                <LocationForm
                  {...commonFormProps}
                  workLocationsSelected={workLocationsSelected}
                  setWorkLocationsSelected={setWorkLocationsSelected}
                  firstname={firstname}
                  setFirstname={setFirstname}
                  lastname={lastname}
                  setLastname={setLastname}
                  workFormat={workFormat}
                  setWorkFormat={setWorkFormat}
                />
              </FormWrapper>
              <FormWrapper width="80%">
                <StudiesForm
                  {...commonFormProps}
                  studyLevel={studyLevel}
                  setStudyLevel={setStudyLevel}
                  experienceYears={experienceYears}
                  setExperienceYears={setExperienceYears}
                  englishLevel={englishLevel}
                  setEnglishLevel={setEnglishLevel}
                  hasEmpowerment={hasEmpowerment}
                  setHasEmpowerment={setHasEmpowerment}
                  loadedEmpowerments={loadedEmpowerments}
                  empowerments={empowerments}
                  setEmpowerments={setEmpowerments}
                />
              </FormWrapper>
              <FormWrapper>
                <SkillsFormLayout
                  {...commonFormProps}
                  loadedSkills={loadedSkills}
                  skills={skills}
                  setSkills={setSkills}
                />
              </FormWrapper>
              <FormWrapper>
                <ExperiencesFormLayout
                  {...commonFormProps}
                  experiences={experiences}
                  setExperiences={setExperiences}
                />
              </FormWrapper>
              <FormWrapper>
                <WishesFormLayout
                  {...commonFormProps}
                  wish={wish}
                  setWish={setWish}
                />
              </FormWrapper>
              <FormWrapper>
                <PricesFormLayout
                  {...commonFormProps}
                  preEmploymentOpen={preEmploymentOpen}
                  setPreEmploymentOpen={setPreEmploymentOpen}
                  hasRecommendation={hasRecommendation}
                  setHasRecommendation={setHasRecommendation}
                  price={price}
                  setPrice={setPrice}
                  priceHidden={priceHidden}
                  setPriceHidden={setPriceHidden}
                />
              </FormWrapper>
              <FormWrapper>
                <DocumentsFormLayout
                  {...commonFormProps}
                  isAnonymous={anonymous}
                  documentFile={documentFile}
                  documentFilename={documentFilename}
                  setDocumentFile={setDocumentFile}
                  updateStatus={setDocumentStatus}
                  previousDocumentFilename={previousDocumentFilename}
                  setDocumentFilename={setDocumentFilename}
                  disableDelete={true}
                />
              </FormWrapper>
            </FormsWrapper>
            {!!editErrors.length && (
              <AlertWrapper>
                <Alert severity="error">
                  <span>
                    La modification ne peut pas être enregistrée, veuillez
                    régler les erreurs suivantes :
                  </span>
                  <ul>
                    {editErrors.map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </Alert>
              </AlertWrapper>
            )}
            {alert.show && (
              <AlertWrapper>
                <Alert severity={alert.type}>{alert.message}</Alert>
              </AlertWrapper>
            )}
            <SubmitWrapper>
              {submittingEditedProfile ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    onClick={submitEdit}
                    secondary
                    gradient
                    style={{
                      paddingTop: isSafari ? "40px" : "16px",
                      paddingBottom: isSafari ? "40px" : "16px",
                    }}
                  >
                    Enregistrer les modifications
                  </Button>
                  <Button
                    outlineButton
                    secondary
                    onClick={() => {
                      modalProps.closeModal();
                      setDocumentFile(null);
                    }}
                    style={{
                      padding: isSafari ? "40px" : "16px",
                      paddingBottom: isSafari ? "40px" : "16px",
                      color: "#241f47",
                    }}
                  >
                    Annuler
                  </Button>
                </>
              )}
            </SubmitWrapper>
          </>
        ) : null}
      </MainWrapper>
    </ModalTemplate>
  );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  overflow-y: auto;
  margin-right: -20px;
  margin-bottom: 40px;
`;

const CustomTitle = styled.h2`
  color: #241f47;
  font-family: "Prompt Semi Bold";
  font-size: 30px;
  margin: 0;
`;

const ReferenceWrapper = styled.div`
  color: #9e9e9e;
  font-family: "Prompt";
  font-size: 16px;
  margin-bottom: 20px;
  text-decoration: underline;
`;

const FormsWrapper = styled.div`
  width: 100%;
  margin: 15px 0 30px 0;
`;

const FormWrapper = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const SubmitWrapper = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;

  & > * {
    margin: 5px 0;
  }
`;

const AlertWrapper = styled.div`
  margin-top: 15px;
`;
