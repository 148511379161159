import React from 'react';
import styled from "styled-components";

/**
 * Composant englobant un autre composant et lui ajoutant le point d'interrogation.
 */
export default function HelpImage({ children, width = "15px" }) {
	return (
		<MainWrapper>
			{children}
			<HelpImageWrapper width={width}>
				<img src="/assets/icons/help.svg" alt="help" width={width} />
			</HelpImageWrapper>
		</MainWrapper>
	);
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: row;
  align-items: center;
`;

const HelpImageWrapper = styled.div`
	width: ${props => props.width + 3};
	height: ${props => props.width};
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	& img {
		margin-left: 3px;
	}
`;