import React from 'react';
import styled from "styled-components";

import { ReactComponent as MagnifyingGlassIcon } from "../../../../svg-assets/loupe.svg";

export default function SearchInput(props) {
	const { width, ...inputProps } = props;
	return (
		<SearchWrapper width={width}>
			<input {...inputProps} />
			<div className="svgContainer">
				<MagnifyingGlassIcon />
			</div>
		</SearchWrapper>
	);
}

const SearchWrapper = styled.div`
	display: flex;
	align-items: center;
	width: ${props => props.width ? props.width : "300px"};
	margin: 24px 0;
	border-radius: 50px;
	border: 1px solid #42387a;
	padding: 8px 10px;
	color: #42387a;

	& > input {
		width: 280px;
		margin-right: 5px;
		background-color: inherit;
		border: none;
		outline: none;
		font-size: 16px;
		font-family: "Prompt";
	}

	& .svgContainer {
		width: 18px;
		height: 18px;

		& svg > g > g {
			fill: #f296ac;
		}
	}

	&::placeholder {
		color: #42387a;
	}
`;