/*
	Fichier utilitaire pour récupérer des valeurs "constantes" qui dépendent de la BDD
	-> exemple : les secteurs d'activité, les compétences déjà existantes, etc.
*/

import { get } from "./api.utils";

/**
 * Fonction permettant de récupérer tous les secteurs d'activités.
 */
export const fetchAllFields = (callback) => {
  get("/fields").then((fields) => {
    if (fields) {
      const formattedFields = fields.map((field) => ({
        ...field,
        value: field.fieldId,
      }));
      callback(formattedFields);
    }
  });
};

/**
 * Fonction permettant de récupérer tous les secteurs d'activités triés par catégories.
 */
export const fetchAllFieldsByCategories = (callback) => {
  get("/fields/categories").then((fields) => {
    if (fields) {
      let formattedFields = [];
      for (const field of fields) {
        if (!formattedFields.includes(field.categoryLabel))
          formattedFields.push(field.categoryLabel);
        formattedFields.push(field);
      }
      callback(formattedFields);
    }
  });
};

/**
 * Fonction permettant de récupérer tous les lieux de travail possibles.
 */
export const fetchAllWorkLocations = (callback) => {
  get("/locations").then((locations) => {
    if (locations) {
      callback(locations);
    }
  });
};

/**
 * Fonction permettant de récupérer toutes les compétences utilisées par des profils.
 */
export const fetchAllSkills = (callback) => {
  get("/skills").then((skills) => {
    if (skills) {
      callback(skills);
    }
  });
};

/**
 * Fonction permettant de récupérer toutes les habilitations utilisées par des profils.
 */
export const fetchAllEmpowerments = (callback) => {
  get("/empowerments").then((empowerments) => {
    if (empowerments) {
      callback(empowerments);
    }
  });
};

export const fetchAllCompanies = (callback) => {
  get("/companies").then((companies) => {
    if (companies) {
      callback(companies);
    }
  });
};
