import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import TextInput from "../../../shared/components/inputs/text-input.component";
import {CustomTitle} from "../createinterco/shared/styled-components-library";
import RoutingButtons from "../createinterco/shared/routing-buttons.component";

export default function DescriptionForm({
  handlePrevious,
  handleNext,
  activeStep,
  setCompletedSteps,
  context,
}){
  const [missionContext, setMissionContext] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [expertise, setExpertise] = React.useState("");
  const [formData, setFormData] = useContext(context);

  const handleValidationAndNext = () => {
      setCompletedSteps(completedSteps => ({ ...completedSteps, [activeStep]: true }));
      saveFormData();
      handleNext();
  };

  const saveFormData = () => {
          setFormData(formData => ({
                  ...formData,
                  descriptionForm: {
                    missionContext,
                    description,
                    expertise,
                  }
		}));
	};

  return (
    <DescriptionFormLayout
      missionContext={missionContext}
      setMissionContext={setMissionContext}
      description={description}
      setDescription={setDescription}
      expertise={expertise}
      setExpertise={setExpertise}
      handleValidationAndNext={handleValidationAndNext}
      handlePrevious={handlePrevious}
    />
  );
}

/* Le layout est utile pour la création d'un profil MAIS AUSSI pour l'édition d'un profil dans l'espace personnel */
export const DescriptionFormLayout = (props) => {
  const {
    missionContext,
    setMissionContext,
    description,
    setDescription,
    expertise,
    setExpertise,
    handleValidationAndNext,
    handlePrevious,
    isEditMode,
  } = props;
  return (
      <>
        <ExperiencesContainer width={ isEditMode ? "100%" : "50%" }>
          <ExperienceCard>
          <CustomTitle>Contexte de la mission</CustomTitle>
            <div style={{ marginTop: "10px" }}>
                <TextInput
                        value={missionContext}
                        onChangeHandler={e => setMissionContext(e.target.value)}
                        placeholder="N'oubliez pas de relire la fiche de poste et d'enlever les informations confidentielles, s'il y en a (adresse mail, localisation, nom du client, etc...)"
                        noMargin
                        multiline
                        rows={10}
                />
            </div>
          </ExperienceCard>
          <ExperienceCard>
          <CustomTitle>Description</CustomTitle>
            <div style={{ marginTop: "10px" }}>
                <TextInput
                        value={description}
                        onChangeHandler={e => setDescription(e.target.value)}
                        placeholder="N'oubliez pas de relire la fiche de poste et d'enlever les informations confidentielles, s'il y en a (adresse mail, localisation, nom du client, etc...)"
                        noMargin
                        multiline
                        rows={10}
                />
            </div>
          </ExperienceCard>
          <ExperienceCard>
          <CustomTitle>Expertise souhaitée</CustomTitle>
            <div style={{ marginTop: "10px" }}>
                <TextInput
                        value={expertise}
                        onChangeHandler={e => setExpertise(e.target.value)}
                        placeholder="N'oubliez pas de relire la fiche de poste et d'enlever les informations confidentielles, s'il y en a (adresse mail, localisation, nom du client, etc...)"
                        noMargin
                        multiline
                        rows={10}
                />
            </div>
          </ExperienceCard>
        </ExperiencesContainer>
        {
          !isEditMode ?
            <RoutingButtons
              handleNext={handleValidationAndNext}
              handlePrevious={handlePrevious}
            />
          : null
        }
      </>
    );
}


const ExperienceCard = styled.div`
	background-color: #FFF;
	border-radius: 40px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	padding: ${props => (props.noTopMargin ? "0 16px 16px 16px" : "16px")};
	display: flex;
	flex-direction: column;
`;

const ExperiencesContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: ${props => props.width ? props.width : "50%"};
	
	& > * {
		width: 100%;
		margin: 24px 0px;
	}
`;
