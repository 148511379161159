import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Stepper from "../../../shared/components/stepper.component";
import MultiSelect from "../../../shared/components/inputs/multi-select-input.component";
import TextInput from "../../../shared/components/inputs/text-input.component";
import RoutingButtons from "./routing-buttons.component";

import { isValid } from "../../../shared/utils/input-validation.utils";
// import { FieldsGroup } from "../../../shared/utils/styled-components-library.utils";
import { get } from "../../../shared/utils/api.utils";

export default ({
  fieldOptions,
  stepperLabels,
  activeStep,
  completedSteps,
  setCompletedSteps,
  companyName,
  companyFields,
  companyWorkforce,
  companyZipcode,
  companySiret,
  companyAPE,
  companyWebsite,
  setCompanyName,
  setCompanyFields,
  setCompanyWorkforce,
  setCompanyZipcode,
  setCompanySiret,
  setCompanyAPE,
  setCompanyWebsite,
  handleNext,
  handlePrevious,
  handleStep,
}) => {
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [isDuplicateSiret, setIsDuplicateSiret] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [checkingExistence, setCheckingExistence] = useState(false);

  const handleValidationAndNext = () => {
    if (checkingExistence) return null; // on ne fait rien si on est en train de vérifier l'existence d'un SIRET ou d'un nom de société
    const hasErrors =
      !companyFields.length ||
      !isValid("number", companyWorkforce) ||
      !companyZipcode.trim() ||
      !isValid("number", companyZipcode.trim()) ||
      isDuplicateSiret ||
      !isValid("siret", companySiret.replace(/ /g, "")) ||
      !isValid("ape", companyAPE) ||
      !isValid("website", companyWebsite) ||
      isDuplicateName ||
      !companyName.trim();
    if (hasErrors) {
      setShowErrors(true);
    } else {
      setCompletedSteps((completedSteps) => ({
        ...completedSteps,
        [activeStep]: true,
      }));
      handleNext();
    }
  };

  const checkSiretExistence = () => {
    const siret = companySiret.replace(/ /g, "");
    if (isValid("siret", siret)) {
      setIsDuplicateSiret(false);
      setCheckingExistence(true);
      get(`/companies/siret/${siret}`).then((response) => {
        if (response && response.found) {
          setIsDuplicateSiret(true);
        }
        setCheckingExistence(false);
      });
    }
  };

  const checkNameExistence = () => {
    const name = companyName.trim().toUpperCase();
    if (!!name) {
      setIsDuplicateName(false);
      setCheckingExistence(true);
      get(`/companies/name/${name}`).then((response) => {
        if (response && response.found) {
          setIsDuplicateName(true);
        }
        setCheckingExistence(false);
      });
    }
  };

  return (
    <Wrapper>
      <Stepper
        labels={stepperLabels}
        activeStep={activeStep}
        style={{ width: "100%", marginLeft: "-10%" }}
        handleStep={handleStep}
        completedSteps={completedSteps}
        type="pink"
      />
      <FormContainer>
        <TextInput
          value={companyName}
          onChangeHandler={(e) => setCompanyName(e.target.value)}
          onBlur={checkNameExistence}
          label="Nom société"
          required
          error={showErrors && (isDuplicateName || !companyName.trim())}
          errorText={
            isDuplicateName
              ? "Le nom de cette société est déjà prise."
              : "Ce champ est obligatoire."
          }
        />
        <MultiSelect
          label="Secteurs d'activité *"
          options={fieldOptions}
          values={companyFields}
          handleChange={(e) => setCompanyFields(e.target.value)}
          error={showErrors && companyFields.length === 0}
          errorText="Veuillez sélectionner au moins un secteur d'activité."
        />
        <FieldsGroup>
          <TextInput
            value={companyWorkforce}
            onChangeHandler={(e) => setCompanyWorkforce(e.target.value)}
            label="Effectif"
            required
            error={showErrors && !isValid("number", companyWorkforce)}
            errorText="Ce champ doit comporter un nombre."
          />
          <TextInput
            value={companyZipcode}
            onChangeHandler={(e) => setCompanyZipcode(e.target.value)}
            label="Code postal"
            required
            error={
              showErrors &&
              (!companyZipcode.trim() ||
                !isValid("number", companyZipcode.trim()))
            }
            errorText={
              companyZipcode.trim()
                ? "Ce champ ne doit comporter que des nombres."
                : "Ce champ est obligatoire."
            }
          />
          <TextInput
            value={companySiret}
            onChangeHandler={(e) => setCompanySiret(e.target.value)}
            onBlur={checkSiretExistence}
            label="Siret"
            required
            error={
              showErrors &&
              (!isValid("siret", companySiret.replace(/ /g, "")) ||
                isDuplicateSiret)
            }
            errorText={
              isDuplicateSiret
                ? "Ce siret est déjà utilisé par une autre société sur la plateforme 1terco."
                : "Le format du SIRET n'est pas valide."
            }
          />
          <TextInput
            value={companyAPE}
            onChangeHandler={(e) => setCompanyAPE(e.target.value)}
            label="Code APE"
            placeholder="Exemple : 7112B"
            required
            error={showErrors && !isValid("ape", companyAPE)}
            errorText="Le format du code APE n'est pas valide."
          />
        </FieldsGroup>
        <TextInput
          value={companyWebsite}
          onChangeHandler={(e) => setCompanyWebsite(e.target.value)}
          label="Site internet *"
          placeholder="Exemple : https://www.amazon.com"
          error={showErrors && !isValid("website", companyWebsite)}
          errorText="Le format du site web n'est pas valide."
        />
        <RoutingButtons
          handleNext={() => {
            handleValidationAndNext();
          }}
          handlePrevious={handlePrevious}
        />
      </FormContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  @media screen and (min-width: 520px) {
    width: 80%;
  }
`;

const FieldsGroup = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 48%;
    margin-bottom: 24px;
  }
`;
