import React from 'react';
import styled from "styled-components";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, withStyles } from "@material-ui/core";

export default function SelectInput(props) {
	return (
		<SelectContainer marginTop={props.marginTop} marginBottom={props.marginBottom}>
			<CustomFormControl variant="outlined" error={props.error}>
				<CustomInputLabel id={`input-${props.label}`}>
					{props.label}
					{props.required && <span className="MuiInputLabel-asterisk"> *</span>}
				</CustomInputLabel>
				<CustomSelect
					onChange={props.onChangeHandler}
					labelId={`input-${props.label}`}
					id={`select-${props.label}`}
					value={props.value}
					label={props.label}
					required={props.required}
				>
					{
						props.options.map(option => (
							<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
						))
					}
				</CustomSelect>
				{
					props.error && <FormHelperText error>
						{props.errorText}
					</FormHelperText>
				}
			</CustomFormControl>
		</SelectContainer>
	);
}

const SelectContainer = styled.div`
	width: 100%;
	margin-bottom: ${props => props.marginBottom ? props.marginBottom : "24px"};
	margin-top: ${props => props.marginTop ? props.marginTop : "0px"};
`;

const CustomFormControl = withStyles({
	root: {
		width : '100%',

		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	}
})(FormControl);

const CustomInputLabel = withStyles({
	root: {
		backgroundColor: 'white',
		padding: '0 7px',
		fontFamily: 'Prompt Light',
		color: '#42387a',
		left: '13px',

		'& .MuiInputLabel-asterisk': {
			color: '#f2a0ad',
		}
	}
})(InputLabel);

const CustomSelect = withStyles({
	root: {
		backgroundColor: 'white',
		padding: "18.5px 27px",
		border: '1px solid #ffc4ce',
		borderRadius: "28px",
		fontFamily: 'Prompt Light',
		color: '#42387a',

		'&:focus': {
			borderRadius: '28px',
			backgroundColor: 'white'
		}
	},

	icon: {
		color: '#42387a'
	}
})(Select);