import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { CreateNeedContext } from "../../contexts/create-need.context";
import { AuthContext } from "../../contexts/auth.context";
import { BigTitle } from "../../shared/utils/styled-components-library.utils";
import Stepper from "../../shared/components/stepper.component";

import InfoForm from "../../specific/components/createneed/info-form.component";
import DescriptionForm from "../../specific/components/createneed/description-form.component";
import SkillsForm from "../../specific/components/createinterco/skills-form.component";
import PricesForm from "../../specific/components/createneed/prices-form.component";
import * as api from "../../shared/utils/api.utils";
import { fetchAllFieldsByCategories } from '../../shared/utils/fetch.utils'; 

export default function CreateNeedPage({
  isFreelance = false,
  openSignInModal,
}) {
  const history = useHistory();
  const [auth] = useContext(AuthContext);
  const [formData, ] = useContext(CreateNeedContext);
  const [activeStep, setActiveStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitAlert, setSubmitAlert] = useState({
    show: false,
    type: null,
    message: null,
  });
  const [fields, setFields] = useState([]);

  const stepperLabels = [
    "Informations besoin",
    "Descriptif",
    "Compétences",
    "Prix",
  ];

  useEffect(() => {
    if (!auth.logged) {
      history.push("/");
    }
    if (auth.logged && auth.profile === "freelance") {
      history.push("/");
    }
  }, [auth, history]);
  
  useEffect(() => {
    fetchAllFieldsByCategories((_fields) => setFields(_fields));
  }, []);

  const handleNext = () => {
    setActiveStep((formerStep) => formerStep + 1);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    setActiveStep((formerStep) => formerStep - 1);
    window.scrollTo(0, 0);
  };

  const handleStep = (step) => {
    setActiveStep(step);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (newData) => {
    if (submitAlert.type === "success" || submitting) {
      return null;
    }
    setSubmitting(true);
    const payload = {
      anonymous: newData.infoForm.anonymous,
      title: newData.infoForm.title.trim(),
      fieldIds: newData.infoForm.fieldIds,
      availability: newData.infoForm.availability,
      duration: newData.infoForm.duration,
      renewable: newData.infoForm.isRenewable,
      workLocations: newData.infoForm.workLocations,
      type: newData.infoForm.workFormat,
      experience: newData.infoForm.experienceYears,
      englishLevel: newData.infoForm.englishLevel,
      hasEmpowerment: newData.infoForm.hasEmpowerment,
      empowerments: newData.infoForm.empowerments,
      missionContext: newData.descriptionForm.missionContext.trim(),
      missionDescription: newData.descriptionForm.description.trim(),
      missionExpertise: newData.descriptionForm.expertise.trim(),
      skills: newData.skillsForm.skills,
      trialPeriod: newData.pricesForm.preEmploymentOpen,
      price: newData.pricesForm.price,
      negotiable: newData.pricesForm.negotiable,
      companyPanel: newData.pricesForm.companyPanel,
      allFreelancesNotified: newData.pricesForm.allFreelancesNotified,
      propositionNumber: newData.pricesForm.propositionNumber,
      notificationFrequence: newData.pricesForm.notificationFrequence,
      companyList: newData.pricesForm.companyList,
    }

    const response = await api.post("/needs", payload);
    if (!response?.success) {
      setSubmitAlert({
        show: true,
        type: "error",
        message:
          response.errorMessage ||
          `La création de votre besoin n'a pas abouti.`,
      });
      setSubmitting(false);
      setTimeout(() => window.location.href = "/espace-personnel", 5000);
    }
		setSubmitting(false);
    window.location.href = `/espace-personnel?onglet=besoins&matching=${response.needId}`;
  };

  const renderCurrentForm = (currentStep) => {
    const commonProps = {
      handlePrevious,
      handleNext,
      activeStep,
      setCompletedSteps,
      isFreelance,
      context: CreateNeedContext,
    };
    switch (currentStep) {
      case 0:
        return (
          <InfoForm
            {...commonProps}
            subStep={subStep}
            setSubStep={setSubStep}
            openSignInModal={openSignInModal}
            fields={fields}
          />
        );
      case 1:
        return (
          <DescriptionForm
            {...commonProps}
          />
        );
      case 2:
        return (
          <SkillsForm {...commonProps} />
        );
      case 3:
        return <PricesForm
          {...commonProps}
          handleSubmit={handleSubmit}
          submitting={submitting}
          submitAlert={submitAlert}
        />;
      default:
        return null;
    }
  };

  return (
    <PageContainer>
      <BigTitle>
        PROPOSEZ VOTRE BESOIN AUX AUTRES TRIBUS
      </BigTitle>
      <StepperContainer>
        <Stepper
          style={{ backgroundColor: "transparent" }}
          labels={stepperLabels}
          activeStep={activeStep}
          handleStep={handleStep}
          completedSteps={completedSteps}
        />
      </StepperContainer>
      {renderCurrentForm(activeStep)}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  padding: 40px 5% 0 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepperContainer = styled.div`
  width: 100%;
`;
