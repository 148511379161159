import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { minDate } from "../../../shared/utils/profiles.utils";
import moment from "moment";
import { AuthContext } from "../../../contexts/auth.context";

export const infoFactory = (profile, nameSpace, isPrivate, auth, summary = false) => {
  const infos = [];
  if (!isPrivate && summary)
    infos.push({
      label: "Société",
      value: profile.user?.companyName ?? "cachée"
    });
  if (isPrivate) {
    infos.push({
      label: "Manager",
      value: auth.userId !== profile.managerUserId ? profile.managerName : "Moi"
    });
    if (summary === true) {
      infos.push({
        label: "Référence",
        value: profile.hash
      });
    }
  }
  infos.push({
    label: nameSpace === 'needs' ? "TJM maximum" : "TJM",
    value: nameSpace === 'needs'
      ? (profile.negotiable ?'Selon profil' : `${profile.price} €`)
      : (profile.price !==0 ?  `${profile.price} €` : 'Non défini')
  });
  if (profile.workLocations?.length > 0)
  {
    infos.push({
      label: nameSpace === "needs" ? "Localisation" : "Mobilité",
      value: profile.workLocations.map((location) => location.name).join(", ")
    });
  }
  if (!summary)
    infos.push({
      label: "Format de travail",
      value: profile.type
    });
  infos.push({
    label: nameSpace === "needs" ? "Expérience minimum" : "Expérience",
    value: profile.experience
  });
  if (!summary) {
    infos.push({
      label: nameSpace === "needs" ? "Date de démarrage" : "Date de disponibilité",
      value: nameSpace === "needs" ? minDate(profile) : profile.availability
    });
    infos.push({
      label: nameSpace === "needs" ? "Durée du projet" : "Durée de disponibilité",
      value: profile.duration
    });
    if (nameSpace !== "needs") infos.push({
      label: "Niveau d'étude",
      value: profile.studies
    }); 
    if (profile.fields?.length > 0)
      infos.push({
        label: "Secteurs d'activité",
        value: profile.fields.map((field) => field.label).join("; ")
      });
    infos.push({
      label: "Anglais",
      value: profile.englishLevel
    });
    if (profile.empowerments?.length > 0) infos["Habilitation"] = profile.empowerments.join(", ");
    infos.push({
      label: nameSpace === "needs" ? "Pré-embauche" : "Ouvert à la pré-embauche",
      value: profile.trialPeriod === 1 ? "Oui" : (profile.trialPeriod === null ? "Possible" : "Non")
    });
    if (profile.recommandationLetter) infos.push({
      label: "Lettre(s) de recommandation disponible(s)",
      value: "" 
    });
    if (profile.renewable)
      infos.push({
        label: "La période est renouvable",
        value: ""
      });
  }
  infos.push({
    label: "Date de création",
    value: moment(profile.createdAt).format("DD/MM/YYYY")
  });
  infos.push({
    label: "Date de mise à jour",
    value: moment(profile.updatedAt).format("DD/MM/YYYY")
  });
  return infos;
}


export default function GeneralInfo({ nameSpace, profile, isPrivate, summary }) {
  const auth = useContext(AuthContext);
  const [infos, setInfos] = useState([]);

  useEffect(() => {
    setInfos(infoFactory(profile, nameSpace, isPrivate, auth, summary));
  }, [profile, nameSpace, isPrivate, auth, summary]);


  return (
    <InfoWrapper>
    {
      infos.map(({ label, value }, key) => {
        return (
        <InfoCell key={ key }>
          <div className="label">{label}</div>
          <div>{value}</div>
        </InfoCell>
        );
      })
    }
    </InfoWrapper>
  );
}

const InfoWrapper = styled.div`
  padding: 0px 5px 0 5px;
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const InfoCell = styled.div`
  font-size: 16px;
  font-family: "Prompt";
  color: #241f4788;
  margin-bottom: 10px;

  & .label {
    color: #241f47;
  }
`;
